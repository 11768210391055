/* eslint-disable default-param-last */
/*
 *
 * CampaignsPage reducer
 *
 */
import produce from 'immer';

import { Campaign } from 'types/Campaign';
import { FEParsedError } from 'types/Error';

import {
  LOAD_ACTIVE_CAMPAIGNS,
  LOAD_ACTIVE_CAMPAIGNS_SUCCESS,
  LOAD_ACTIVE_CAMPAIGNS_ERROR,
  LOAD_CLOSED_CAMPAIGNS,
  LOAD_CLOSED_CAMPAIGNS_SUCCESS,
  LOAD_CLOSED_CAMPAIGNS_ERROR,
  FILTER_ACTIVE_CAMPAIGNS,
  FILTER_CLOSED_CAMPAIGNS,
  CLEAR_CAMPAIGNS,
  CLEAR_ACTIVE_CAMPAIGNS,
  CLEAR_CLOSED_CAMPAIGNS,
} from './constants';

export type CampaignsPageState = {
  activeLoading: boolean;
  activeError: boolean | FEParsedError;
  campaignsPrivate: Campaign[];
  campaignsRestricted: Campaign[];
  campaignsActive: Campaign[];
  campaignsComingSoon: Campaign[];
  campaignsFeatured: Campaign[];
  campaignsClosing: Campaign[];

  closedLoading: boolean;
  closedError: boolean | FEParsedError;
  campaignsClosed?: {
    results: Campaign[];
    total: number;
  };

  page: number;
};

export const initialState = {
  activeLoading: true,
  activeError: false,
  campaignsPrivate: [],
  campaignsRestricted: [],
  campaignsActive: [],
  campaignsComingSoon: [],
  campaignsFeatured: [],
  campaignsClosing: [],

  closedLoading: false,
  closedError: false,
  campaignsClosed: undefined,

  page: 0,
};

/* eslint-disable default-case, no-param-reassign */
const campaignsPageReducer = (
  state: CampaignsPageState = initialState,
  action,
) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_ACTIVE_CAMPAIGNS:
        draft.activeLoading = true;
        draft.activeError = false;
        break;
      case LOAD_ACTIVE_CAMPAIGNS_SUCCESS:
        draft.campaignsPrivate = action.campaignsPrivate;
        draft.campaignsRestricted = action.campaignsRestricted;
        draft.campaignsActive = action.campaignsActive;
        draft.campaignsComingSoon = action.campaignsComingSoon;
        draft.campaignsFeatured = action.campaignsFeatured;
        draft.campaignsClosing = action.campaignsClosing;
        draft.activeLoading = false;
        draft.activeError = false;
        break;
      case LOAD_ACTIVE_CAMPAIGNS_ERROR:
        draft.activeLoading = false;
        draft.activeError = action.error || true;
        draft.campaignsPrivate = [];
        draft.campaignsRestricted = [];
        draft.campaignsActive = [];
        draft.campaignsComingSoon = [];
        draft.campaignsFeatured = [];
        draft.campaignsClosing = [];
        break;
      case LOAD_CLOSED_CAMPAIGNS:
        draft.closedLoading = true;
        draft.closedError = false;
        break;
      case LOAD_CLOSED_CAMPAIGNS_SUCCESS:
        draft.closedLoading = false;
        draft.closedError = false;

        if (!draft.campaignsClosed) {
          draft.campaignsClosed = {
            results: [],
            total: 0,
          };
        }
        draft.campaignsClosed.results = draft.campaignsClosed.results.concat(
          action.campaignsClosed.results,
        );
        draft.campaignsClosed.total = action.campaignsClosed.total;
        draft.page += 1;
        break;
      case LOAD_CLOSED_CAMPAIGNS_ERROR:
        draft.closedLoading = false;
        draft.closedError = action.error;
        if (!draft.campaignsClosed) {
          draft.campaignsClosed = {
            results: [],
            total: 0,
          };
        }
        draft.campaignsClosed.results = [];
        draft.campaignsClosed.total = 0;
        break;
      case FILTER_ACTIVE_CAMPAIGNS:
        draft.activeLoading = true;
        draft.activeError = false;
        draft.campaignsPrivate = [];
        draft.campaignsRestricted = [];
        draft.campaignsActive = [];
        draft.campaignsComingSoon = [];
        draft.campaignsClosing = [];
        break;
      case FILTER_CLOSED_CAMPAIGNS:
        draft.closedLoading = true;
        draft.closedError = false;
        if (!draft.campaignsClosed) {
          draft.campaignsClosed = {
            results: [],
            total: 0,
          };
        }
        draft.campaignsClosed.results = [];
        draft.campaignsClosed.total = 0;
        draft.page = 0;
        break;
      case CLEAR_ACTIVE_CAMPAIGNS:
        draft.activeLoading = false;
        draft.activeError = false;
        draft.campaignsPrivate = [];
        draft.campaignsRestricted = [];
        draft.campaignsActive = [];
        draft.campaignsComingSoon = [];
        draft.campaignsFeatured = [];
        draft.campaignsClosing = [];
        break;
      case CLEAR_CLOSED_CAMPAIGNS:
        draft.closedLoading = false;
        draft.closedError = false;
        if (!draft.campaignsClosed) {
          draft.campaignsClosed = {
            results: [],
            total: 0,
          };
        }
        draft.campaignsClosed.results = [];
        draft.campaignsClosed.total = 0;
        draft.page = 0;
        break;
      case CLEAR_CAMPAIGNS:
        draft.activeLoading = false;
        draft.activeError = false;
        draft.campaignsPrivate = [];
        draft.campaignsRestricted = [];
        draft.campaignsActive = [];
        draft.campaignsComingSoon = [];
        draft.campaignsFeatured = [];
        draft.campaignsClosing = [];
        draft.closedLoading = false;
        draft.closedError = false;
        draft.campaignsClosed = undefined;
        draft.page = 0;
        break;
    }
  });

export default campaignsPageReducer;
