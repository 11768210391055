import makeStyles from '@mui/styles/makeStyles';
import { CONSOLE_SIDEBAR_WIDTH } from 'utils/constants';

export const useStyle = makeStyles({
  sideBarMenuContainer: {
    width: CONSOLE_SIDEBAR_WIDTH,
    backgroundColor: '#F2F2F2',
    display: 'flex',
    flexDirection: 'column',
    '@media print': {
      display: 'none',
    },
  },
  list: {
    width: '100%',
    flexGrow: 1,
    '@media print': {
      display: 'none',
    },
  },
  primaryButton: {
    marginTop: 20,
  },
  versionNumber: {
    padding: '10px 16px',
  },
  drawerHeader: {
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  separator: {
    width: '100%',
    height: 30,
  },
});
