import User from 'utils/User';
import ENUMRequestType from 'utils/Enums/RequestType';
import { trackException } from 'utils/trackers';
import LocalStorage from 'utils/localStorage';
import refreshToken from 'utils/refreshToken';
import { DEFAULT_LOCALE } from 'i18n';

function getStatusTextFromErrorStatusCode(statusCode) {
  switch (statusCode) {
    case 400:
      return 'Bad Request';
    case 401:
      return 'Unauthorized';
    case 403:
      return 'Forbidden';
    case 404:
      return 'Not Found';
    case 408:
      return 'Request Timeout';
    case 409:
      return 'Conflict';
    case 410:
      return 'Gone';
    case 422:
      return 'Unprocessable Entity';
    case 423:
      return 'Locked';
    case 429:
      return 'Too Many Request';
    case 500:
      return 'Internal Server Error';
    case 501:
      return 'Not Implemented';
    case 502:
      return 'Bad Gateway';
    case 503:
      return 'Service Unavailable';
    case 504:
      return 'Gateway Timeout';

    default:
      return `Failed to fetch (${
        statusCode
          ? `${statusCode} Status Code not mapped`
          : 'No status code present in the response'
      })`;
  }
}

export function makeAuthorizatedOptions(options = {}, requestType) {
  const headers = new Headers(options.headers ? options.headers : {});
  let contentLanguage = DEFAULT_LOCALE;

  switch (requestType) {
    case ENUMRequestType.api:
      headers.set('Content-Type', 'application/json');
      break;
    case ENUMRequestType.formData:
      break;
    default:
      headers.set('Content-Type', 'application/json');
      break;
  }

  if (User.getUserToken()) {
    headers.set('Authorization', `Bearer ${User.getUserToken()}`);
  }

  if (
    process.env.ENABLE_MULTILANGUAGE === 'true' &&
    LocalStorage.getItem('user-language')
  ) {
    contentLanguage = LocalStorage.getItem('user-language');
  }

  headers.set('Content-Language', contentLanguage);
  headers.set('Accept-Language', contentLanguage);
  headers.set('mmc-sign', 'mmc-aq123');

  return { ...options, ...{ headers } };
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if ([204, 205].includes(response.status)) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (
    response.status === 503 &&
    response.headers.get('Content-Type') === 'text/css'
  ) {
    // backoffice is in maintenance
    // FOR LOG READING: Backoffice is in maintenance;
    window.location.reload();
    throw new Error('Backoffice is in maintenance');
  }

  const error = new Error(getStatusTextFromErrorStatusCode(response?.status));
  error.response = response;
  throw error;
}

const handleError = (url, error, method, params) => {
  const { message } = error;
  const e = new Error(message);

  if (
    error &&
    error.message === 'Failed to fetch' &&
    typeof error.response === 'undefined'
  ) {
    if (navigator?.onLine) {
      e.isCORS = true;
    } else {
      e.offline = true;
    }
  }

  if (
    error &&
    error.response &&
    (error.response.status >= 500 || // server error
      error.response.status === 400 || // bad request
      error.response.status === 403 || // forbidden
      error.response.status === 429) // too many requests
  ) {
    trackException(
      `Fetch [${error.response.status}] ${method} ${url} - ${error.toString()}`,
      false,
    );
  }

  e.stack = error.stack;
  e.response = error.response;
  e.url = url;
  e.method = method;
  e.params = params;

  throw e;
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export const request = function request(url, options, requestType = 'api') {
  refreshToken();

  let repeatedSearchParams = new URLSearchParams();

  if (!!options?.repeatedQSParams?.length) {
    options.repeatedQSParams.forEach(item => {
      repeatedSearchParams.append(item.key, item.value);
    });
  }

  /* eslint-disable */
  const params =
    options && (options.qs || options.repeatedQSParams)
      ? `?${new URLSearchParams(
        options.qs,
        ).toString()}${repeatedSearchParams.toString() ? `&${repeatedSearchParams.toString()}` : ''}`
      : '';
  /* eslint-enable */

  const optionsWithHeaders = makeAuthorizatedOptions(options, requestType);

  return fetch(`${url}${params}`, optionsWithHeaders)
    .then(checkStatus)
    .then(parseJSON)
    .catch(error => {
      handleError(url, error, options?.method || 'GET', params);
    });
};
