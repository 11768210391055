import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the stepDonate state domain
 */

const selectStepDonateDomain = state => state.stepDonate || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by StepDonate
 */

const makeSelectStepDonate = () =>
  createSelector(selectStepDonateDomain, substate => substate);
const makeSelectStepDonateDonation = () =>
  createSelector(selectStepDonateDomain, substate => substate.donation);
const makeSelectStepDonateLoading = () =>
  createSelector(selectStepDonateDomain, substate => substate.loading);
const makeSelectStepDonateError = () =>
  createSelector(selectStepDonateDomain, substate => substate.error);

export default makeSelectStepDonate;
export {
  selectStepDonateDomain,
  makeSelectStepDonateDonation,
  makeSelectStepDonateLoading,
  makeSelectStepDonateError,
};
