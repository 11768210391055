import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles({
  linkContainer: {
    padding: 20,
    borderBottom: '1px solid #eaeaea',
    textAlign: 'right',
  },
  powerUserChip: {
    height: 'auto',
    position: 'absolute',
    top: '50%',
    right: 90,
    transform: 'translateY(-50%)',
    '& .MuiChip-label': {
      padding: '1px 4px',
      fontSize: 9,
      fontWeight: 600,
    },
  },
});
