import { takeLatest, call, put } from 'redux-saga/effects';
// import Logger from 'loglevel';
import { request } from 'utils/request';
import makeApiUrl from 'utils/makeApiUrl';
import { ME_COOKIE_SETTINGS_URL } from 'utils/constants';
import { manageSagaError } from 'utils/errorManager';

import { setCookieSettingsError, setCookieSettingsSuccess } from './actions';
import { SET_COOKIES_SETTINGS } from './constants';

export function* setCookies({ cookieSettings }) {
  try {
    const requestUrl = makeApiUrl(ME_COOKIE_SETTINGS_URL);
    const options = {
      method: 'PATCH',
      body: JSON.stringify(cookieSettings),
    };
    yield call(request, requestUrl, options);
    yield put(setCookieSettingsSuccess(cookieSettings));
  } catch (err) {
    const error = yield manageSagaError('CookiesBanner', err);
    yield put(setCookieSettingsError(error));
  }
}

export default function* cookiesBannerSaga() {
  yield takeLatest(SET_COOKIES_SETTINGS, setCookies);
}
