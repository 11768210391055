/*
 * App Messages
 *
 * This contains all the text for the HomePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.App';

export default defineMessages({
  SEO_DESCRIPTION: {
    id: `${scope}.seo_description.message`,
    defaultMessage:
      'Invest in the best start-ups, SMEs and real estate projects thanks to Mamacrowd equity crowdfunding. Discover the campaigns and take advantage of tax breaks.',
  },
});
