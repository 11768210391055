/*
 *
 * StepBasicProfile actions
 *
 */

import {
  GET_BASIC_PROFILE,
  GET_BASIC_PROFILE_SUCCESS,
  GET_BASIC_PROFILE_ERROR,
  SET_BASIC_PROFILE,
  SET_BASIC_PROFILE_SUCCESS,
  SET_BASIC_PROFILE_ERROR,
  CLEAN_BASIC_PROFILE,
} from './constants';

export function getBasicProfile() {
  return {
    type: GET_BASIC_PROFILE,
  };
}

export function getBasicProfileSuccess(basicProfile) {
  return {
    type: GET_BASIC_PROFILE_SUCCESS,
    basicProfile,
  };
}

export function getBasicProfileError(error) {
  return {
    type: GET_BASIC_PROFILE_ERROR,
    error,
  };
}

export function setBasicProfile(basicProfile) {
  return {
    type: SET_BASIC_PROFILE,
    basicProfile,
  };
}

export function setBasicProfileSuccess(basicProfile) {
  return {
    type: SET_BASIC_PROFILE_SUCCESS,
    basicProfile,
  };
}

export function setBasicProfileError(error) {
  return {
    type: SET_BASIC_PROFILE_ERROR,
    error,
  };
}

export function cleanBasicProfile() {
  return {
    type: CLEAN_BASIC_PROFILE,
  };
}
