import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles({
  menuItem: {
    fontWeight: 'bold',
    width: '100%',
  },
  subMenuItem: props => ({
    paddingLeft: 10,
    margin: 0,
    width: '100%',
    opacity: props.disabled ? 0.5 : 1,
  }),
  subMenuItemPadded: props => ({
    width: '100%',
    padding: 0,
    paddingLeft: 25,
    opacity: props.disabled ? 0.5 : 1,
  }),
  listItem: {
    padding: ' 4px 10px 4px 15px',
  },
});
