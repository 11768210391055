/*
 *
 * DonatePage constants
 *
 */

export const LOAD_CAMPAIGN_INFO = 'app/DonatePage/LOAD_CAMPAIGN_INFO';
export const LOAD_CAMPAIGN_INFO_SUCCESS =
  'app/DonatePage/LOAD_CAMPAIGN_INFO_SUCCESS';
export const LOAD_CAMPAIGN_INFO_ERROR =
  'app/DonatePage/LOAD_CAMPAIGN_INFO_ERROR';

export const GO_TO_NEXT_STEP = 'app/DonatePage/GO_TO_NEXT_STEP';
export const GO_TO_PREV_STEP = 'app/DonatePage/GO_TO_PREV_STEP';
export const SET_ACTIVE_STEP = 'app/DonatePage/SET_ACTIVE_STEP';

export const RESET_STEPS_STATE = 'app/DonatePage/RESET_STEPS_STATE';
