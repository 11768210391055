import { checkVAT, countries } from 'jsvat';
import isLengthBetween from 'utils/Validations/isLengthBetween';

export default (vatNumber, country) => {
  if (checkVAT && countries) {
    const nation = countries.filter(c => c.codes.includes(country));
    const checkedVAT = checkVAT(vatNumber, country ? nation : countries);
    if (!checkedVAT.isValid) {
      return false;
    }
    return true;
  }
  return isLengthBetween(vatNumber, 10, 20);
};

export const isEuropeanCountry = value => {
  if (!value) return false;
  return countries.some(country => country.codes.includes(value));
};
