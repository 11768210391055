/**
 *
 * LocalizedSwitch
 *
 */

import React from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectLocale } from 'containers/LanguageProvider/selectors';

export function LocalizedSwitch({ locale, children }) {
  function localizeRoutePath(path) {
    switch (typeof path) {
      case 'undefined':
        return undefined;
      case 'object':
        // path can be a String[]
        if (Array.isArray(path)) {
          return path.map(key => `/${locale}${key}`);
        }
        return path;
      // return { ...path, pathname: `/${locale}${path.pathname}` };
      default:
        // path can be a String
        return path === '*' ? path : `/${locale}${path}`;
    }
  }

  function localizeRouteTo(to) {
    switch (typeof to) {
      case 'undefined':
        return undefined;
      case 'object':
        // if (Array.isArray(to)) {
        //   return to.map(key => `/${locale}${key}`);
        // }
        // to can be an Object
        return { ...to, pathname: `/${locale}${to.pathname}` };
      default:
        // to can be a string;
        return to === '*' ? to : `/${locale}${to}`;
    }
  }

  function localizeRouteFrom(from) {
    switch (typeof from) {
      case 'undefined':
        return undefined;
      case 'object':
        // FROM SHOULD NEVER BE AN ARRAY !
        return from.map(key => `/${locale}${key}`);
      default:
        // From can be a string;
        return from === '*' ? from : `/${locale}${from}`;
    }
  }
  return (
    <Switch>
      {React.Children.map(children, child =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
            ...child.props, // eslint-disable-line
            path: localizeRoutePath(child.props.path), // eslint-disable-line
            to: localizeRouteTo(child.props.to), // eslint-disable-line
            from: localizeRouteFrom(child.props.from), // eslint-disable-line
          }) // eslint-disable-line
          : child,
      )}
    </Switch>
  );
}

LocalizedSwitch.propTypes = {
  locale: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LocalizedSwitch);
