/*
 *
 * HeaderMessage reducer
 *
 */
import produce from 'immer';
import {
  LOAD_MESSAGE,
  LOAD_MESSAGE_SUCCESS,
  LOAD_MESSAGE_ERROR,
} from './constants';

export const initialState = {
  messageArray: [],
  loading: false,
  error: false,
};

/* eslint-disable default-case, no-param-reassign */
const headerMessageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_MESSAGE:
        draft.messageArray = [];
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_MESSAGE_SUCCESS:
        draft.messageArray = action.messageArray;
        draft.loading = false;
        draft.error = false;
        break;
      case LOAD_MESSAGE_ERROR:
        draft.messageArray = [];
        draft.loading = false;
        draft.error = action.error || true;
        break;
    }
  });

export default headerMessageReducer;
