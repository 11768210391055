/**
 *
 * HeaderMessage
 *
 */

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { isPast } from 'date-fns';

import MessageBanner from 'components/MessageBanner';

import User from 'utils/User';
import LocalStorage from 'utils/localStorage';

import {
  makeSelectHeaderMessageArray,
  makeSelectHeaderMessageLoading,
  makeSelectHeaderMessageError,
} from './selectors';
import { loadMessage } from './actions';
import reducer from './reducer';
import saga from './saga';

export function HeaderMessage({
  loadMessageData,
  messageArray,
  loading,
  error,
}) {
  useInjectReducer({ key: 'headerMessage', reducer });
  useInjectSaga({ key: 'headerMessage', saga });

  const userLogged = User.isLogged() && !User.hasTokenExpired();

  useEffect(() => {
    if (userLogged) {
      const messagesRead = LocalStorage.getItem('messagesRead') || [];
      if (messagesRead.length) {
        const messagesReadFiltered = messagesRead.filter(
          message => !isPast(new Date(message.expireAt)),
        );
        LocalStorage.setItem('messagesRead', messagesReadFiltered);
      }
      if (typeof loadMessageData === 'function') {
        loadMessageData();
      }
    }
  }, [loadMessageData, userLogged]);

  const closeMessageBanner = message => {
    const messagesRead = LocalStorage.getItem('messagesRead') || [];
    messagesRead.push({ id: message.id, expireAt: message.expireAt });
    LocalStorage.setItem('messagesRead', messagesRead);
  };

  const messageJSX =
    messageArray &&
    !!messageArray.length &&
    messageArray.map(message => {
      const messagesRead = LocalStorage.getItem('messagesRead') || [];
      if (messagesRead.some(messageHidden => messageHidden.id === message.id)) {
        return null;
      }
      return (
        <MessageBanner
          key={message.id}
          message={message}
          closeMessageBanner={closeMessageBanner}
        />
      );
    });

  return userLogged && !loading && !error && messageArray ? messageJSX : null;
}

HeaderMessage.propTypes = {
  loadMessageData: PropTypes.func.isRequired,
  messageArray: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

const mapStateToProps = createStructuredSelector({
  messageArray: makeSelectHeaderMessageArray(),
  loading: makeSelectHeaderMessageLoading(),
  error: makeSelectHeaderMessageError(),
});

function mapDispatchToProps(dispatch) {
  return {
    loadMessageData: () => {
      dispatch(loadMessage());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(HeaderMessage);
