/*
 * CookiesBanner Messages
 *
 * This contains all the text for the CookiesBanner container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CookiesBanner';

export default defineMessages({
  title: {
    id: `${scope}.title.message`,
    defaultMessage: 'Permettici di conoscerti meglio',
  },
  cookieSettingsTitle: {
    id: `${scope}.cookie_settings_title.message`,
    defaultMessage: 'Imposta le tue preferenze',
  },
  localStorageUnsupportedTitle: {
    id: `${scope}.local_storage_unsupported_title.message`,
    defaultMessage: 'Ops qualcosa è andato storto',
  },
  description: {
    id: `${scope}.description.message`,
    defaultMessage:
      'Mamacrowd e partner operano globalmente e possono, previa acquisizione del tuo consenso attraverso i comandi "Accetta tutto", “Accetta solo i necessari" o “Imposta preferenze", utilizzare cookie per fini statistici, pubblicitari e anche di profilazione, propri o di terzi, per modulare la fornitura del servizio in modo personalizzato e in linea con le tue preferenze.{linebreak}In caso di rifiuto utilizzeremo solo i cookie necessari. Per maggiori informazioni, leggi la nostra ',
  },
  cookieSettingsDescription: {
    id: `${scope}.cookie_settings_description.message`,
    defaultMessage:
      'Questo pannello ti permette di esprimere le tue preferenze relative al trattamento delle informazioni personali.{linebreak}I "Cookie funzionali e di navigazione" non possono essere deselezionati essendo necessari per la corretta fruizione della piattaforma.',
  },
  localStorageUnsupportedDescription: {
    id: `${scope}.local_storage_unsupported_description.message`,
    defaultMessage:
      'Per il corretto funzionamento della piattaforma Mamacrowd è necessario abilitare il salvataggio di cookie del tuo browser. Per un corretto utilizzo, ti invitiamo quindi a <b>modificare le tue impostazioni</b>.',
  },
  cookiesPolicy: {
    id: `${scope}.cookies_policy.message`,
    defaultMessage: 'Cookies Policy',
  },
  cookieFunctional: {
    id: `${scope}.functional.message`,
    defaultMessage: 'Functional and Navigation',
  },
  cookieSupport: {
    id: `${scope}.support.message`,
    defaultMessage: 'Support',
  },
  cookieAnalytics: {
    id: `${scope}.analytics.message`,
    defaultMessage: 'Analytics',
  },
  cookieThirdParty: {
    id: `${scope}.third_party.message`,
    defaultMessage: 'Third Party',
  },
  back: {
    id: `${scope}.back.message`,
    defaultMessage: 'Back',
  },
  save: {
    id: `${scope}.save.message`,
    defaultMessage: 'Save',
  },
  accept: {
    id: `${scope}.accept.message`,
    defaultMessage: 'Accept all',
  },
  showCookies: {
    id: `${scope}.show_cookies.message`,
    defaultMessage: 'Set Preferences',
  },
  acceptFunctional: {
    id: `${scope}.accept_functional.message`,
    defaultMessage: 'Accept only necessary',
  },
});
