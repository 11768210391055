import { lazy, Suspense } from 'react';
import LocalStorage from 'utils/localStorage';

// SOLUTION 2
// force reload of window instead of reloading the component
const lazyWithRetry = componentImport =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      LocalStorage.getItem('page-has-been-force-refreshed') || 'false',
    );

    try {
      const component = await componentImport();

      LocalStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        LocalStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

const loadable = (importFunc, options) => {
  const { fallback } = options || { fallback: null };
  const LazyComponent = lazyWithRetry(importFunc);

  return function (props) {
    return (
      <Suspense fallback={fallback}>
        <LazyComponent {...props} />
      </Suspense>
    );
  };
};

export default loadable;
