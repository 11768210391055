/**
 *
 * LoadableEmptyPlaceholder
 *
 */

export interface LoadableEmptyPlaceholderProps {}

function LoadableEmptyPlaceholder({}: LoadableEmptyPlaceholderProps) {
  return <div style={{ minHeight: '100vh' }} />;
}

export default LoadableEmptyPlaceholder;
