import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the header state domain
 */

const selectHeaderDomain = state => state.header || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Header
 */

const makeSelectHeader = () =>
  createSelector(selectHeaderDomain, substate => substate);

const makeSelectLoading = () =>
  createSelector(selectHeaderDomain, substate => substate.loading);

const makeSelectError = () =>
  createSelector(selectHeaderDomain, substate => substate.error);

const makeSelectCalled = () =>
  createSelector(selectHeaderDomain, substate => substate.called);

const makeSelectHeaderAdditionalLink = () =>
  createSelector(selectHeaderDomain, substate => substate.additionalLink);

export default makeSelectHeader;
export {
  selectHeaderDomain,
  makeSelectLoading,
  makeSelectError,
  makeSelectHeaderAdditionalLink,
  makeSelectCalled,
};
