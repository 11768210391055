export enum UserPermissions {
  All = '*',
  AnchorInvestor = 'anchorInvestor',
  ApiDocs = 'apiDocs', // apiDocs
  BasicProfile = 'basicProfile',
  Billing = 'campaignBilling', // campaigns Menu
  BusinessProfile = 'businessProfile',
  Campaign = 'campaign', // campaigns Menu
  Competitors = 'competitorCampaign', // campaigns Menu
  Company = 'company',
  Comment = 'comment',
  DescriptionSection = 'descriptionSection',
  Document = 'document',
  DataBoard = 'dataBoard',
  EarlyBird = 'earlyBird',
  ErrorLogger = 'errorLogger',
  Follow = 'follow',
  Faq = 'faq',
  HpBanner = 'hpBanner',
  InvestmentOrder = 'investmentOrder', // orders menu, campaign/orders, camp/inv_list, camp/Bank
  Industry = 'industry',
  Media = 'media',
  Mifid = 'mifid',
  MaterializedView = 'materializedView',
  News = 'blogArticle', // news menu CRUD
  Notification = 'notification', // Closing Proc/menu
  Payment = 'payment',
  Partner = 'partner',
  Page = 'page',
  Press = 'press',
  QuotaDef = 'quotaDef',
  Queues = 'queues',
  Refund = 'refund',
  Report = 'investmentsReport',
  Rewards = 'rewards',
  RichData = 'richdata',
  SEO = 'SEO', // SSR + Sitemap + Robots
  Sim = 'sim',
  Setting = 'setting',
  Shareholder = 'shareholder',
  TeamMember = 'teamMember',
  Testimonial = 'testimonial',
  Translator = 'translator',
  User = 'user', // user menu
  UserRole = 'userRole',
  UserProfile = 'userProfile',
  Update = 'update',
  Upload = 'upload',
  UnidentifiedPayment = 'unidentifiedPayment',
  UserNotification = 'userNotification',
  UserCluster = 'userCluster',
  WebMessage = 'webMessage',
}

export default UserPermissions;
