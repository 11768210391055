import User from 'utils/User';
import Logger from 'loglevel';
import SessionStorage, { LAST_TOKEN_REFRESH_DATE } from 'utils/sessionStorage';
import LocalStorage from 'utils/localStorage';
import { addMinutes, isAfter } from 'date-fns';
import {
  LAST_TOKEN_REFRESH_MINUTES,
  AUTH_REFRESH_TOKEN_URL,
} from './constants';
import makeApiUrl from './makeApiUrl';

export default function refreshToken(callback, force = false) {
  const sessionLastTokenRefreshDate = SessionStorage.getItem(
    LAST_TOKEN_REFRESH_DATE,
  );

  // IF TOKEN NOT EXPIRED && SESSION DATE
  if (
    force ||
    (!User.hasTokenExpired() &&
      (!sessionLastTokenRefreshDate ||
        isAfter(
          new Date(),
          addMinutes(
            new Date(sessionLastTokenRefreshDate),
            LAST_TOKEN_REFRESH_MINUTES,
          ),
        )))
  ) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    if (User.getUserToken()) {
      headers.append('Authorization', `Bearer ${User.getUserToken()}`);
    }

    const sessionId = LocalStorage.getItem('session-id');
    if (sessionId) {
      headers.append('x-mama-session', sessionId);
    }

    headers.append('mmc-sign', 'mmc-aq123');

    fetch(makeApiUrl(AUTH_REFRESH_TOKEN_URL), {
      // TO PUT IN WEBPACK
      method: 'POST',
      headers,
    })
      .then(resp => {
        if (resp.status !== 200) {
          User.logout();

          const error = new Error(resp.statusText);
          error.response = resp;

          throw error;
        }
        LocalStorage.removeItem('session-id');
        return resp.json();
      })
      .then(refreshResponse => {
        const { user } = refreshResponse;
        if (user) {
          SessionStorage.setItem(
            LAST_TOKEN_REFRESH_DATE,
            new Date().toISOString(),
          );

          LocalStorage.setItem('user', refreshResponse);

          if (typeof callback === 'function') {
            callback(refreshResponse);
          }
        }
      })
      .catch(e => {
        Logger.error('refreshToken:', e);
      });
  }
}
