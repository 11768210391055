import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  loaded: boolean;
  transparent: boolean;
};

export const useStyle = makeStyles<Theme, Props>(theme => ({
  image: props => {
    const imageStyle = {
      opacity: props.loaded ? 1 : 0.2,
      transition: 'opacity ease-in 100ms',
      '-o-transition': 'opacity ease-in 100ms',
      '-ms-transition': 'opacity ease-in 100ms',
      '-moz-transition': 'opacity ease-in 100ms',
      '-webkit-transition': 'opacity ease-in 100ms',
    };
    if (!props.transparent) {
      return {
        ...imageStyle,
        backgroundColor: theme.palette.secondary.main,
      };
    }
    return imageStyle;
  },
}));
