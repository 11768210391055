/*
 * Dialog Messages
 *
 * This contains all the text for the Dialog component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Dialog';

export default defineMessages({
  yes: {
    id: `${scope}.yes.message`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no.message`,
    defaultMessage: 'No',
  },
});
