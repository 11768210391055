/**
 *
 * DonateSuccessPage
 *
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Typography } from '@mamacrowd/ui-kit';
import { addMinutes, isBefore } from 'date-fns';

import Section from 'components/Section';
import Link from 'basic/Link';
import FormattedMessage from 'components/FormattedMessage';

import { renderEuroFloat } from 'utils/renderEuroValue';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { trackDonationComplete } from 'utils/trackers';
import {
  ERROR_ROUTE,
  CAMPAIGN_ROUTE,
  MAIL_TO_SUPPORT,
  HOME_PAGE_ROUTE,
} from 'utils/constants';

import {
  makeSelectDonateSuccessPageDonation,
  makeSelectDonateSuccessPageError,
  makeSelectDonateSuccessPageLoading,
} from './selectors';
import { loadDonationOrder, cleanDonation } from './actions';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';

import { useStyle } from './DonateSuccessPage.style';

export function DonateSuccessPage({
  loadDonationOrderData,
  cleanDonationData,
  match,
  donation,
  error,
  loading,
  history,
}) {
  useInjectReducer({ key: 'donateSuccessPage', reducer });
  useInjectSaga({ key: 'donateSuccessPage', saga });

  const { investmentOrderId } = match.params;

  useEffect(() => {
    if (error && !loading && !donation) {
      history.push(ERROR_ROUTE);
    }
  }, [error]);

  useEffect(() => {
    if (loadDonationOrderData) {
      loadDonationOrderData(investmentOrderId);
    }
  }, [loadDonationOrderData]);

  useEffect(() => {
    if (
      donation &&
      donation.id &&
      donation.status === 'waiting_for_payment' && // #MMC2: add enum
      isBefore(new Date(), addMinutes(new Date(donation.createdAt), 10)) // here we try to avoid duplicates for page reloads
    ) {
      trackDonationComplete(
        donation.campaign,
        donation.amount,
        donation.id,
        donation.userType,
        {},
      );
    }
  }, [donation]);

  useEffect(
    () => () => {
      cleanDonationData();
    },
    [],
  );

  const classes = useStyle();

  return (
    <Section>
      {!error && !loading && donation && (
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="h1" color="primary">
              <FormattedMessage
                messages={messages}
                messageId="congratulations"
              />
            </Typography>
            <Typography variant="h1" color="textPrimary">
              <FormattedMessage
                messages={messages}
                messageId="investmentSuccess"
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary">
              <FormattedMessage messages={messages} messageId="lastStep" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              className={classes.becameMemberText}
            >
              <FormattedMessage messages={messages} messageId="becameMember" />
              <Link
                to={CAMPAIGN_ROUTE.replace(
                  ':systemTitle',
                  donation.campaign.systemTitle,
                )}
                fontSize={28}
              >
                {donation.campaign.title}
                <img
                  src={donation.campaign.company.logoImgUrl}
                  alt={donation.campaign.title}
                  className={classes.companyLogo}
                />
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textPrimary" className={classes.companyValues}>
              <FormattedMessage
                messages={messages}
                messageId="transferTo"
                isHtml
                values={{
                  companyName: donation.campaign.bank.holderName,
                }}
              />
            </Typography>
            <Typography color="textPrimary" className={classes.companyValues}>
              <FormattedMessage
                messages={messages}
                messageId="iban"
                isHtml
                values={{ companyBank: donation.campaign.bank.IBAN }}
              />
            </Typography>
            <Typography color="textPrimary" className={classes.companyValues}>
              <FormattedMessage
                messages={messages}
                messageId="swift"
                isHtml
                values={{ companySwift: donation.campaign.bank.SWIFT }}
              />
            </Typography>
            <Typography color="textPrimary" className={classes.companyValues}>
              <FormattedMessage
                messages={messages}
                messageId="reason"
                isHtml
                values={{
                  investmentNumber: donation.refOldId,
                  companyName: donation.campaign.title,
                }}
              />
            </Typography>
            <Typography color="textPrimary" className={classes.companyValues}>
              <FormattedMessage
                messages={messages}
                messageId="total"
                isHtml
                values={{ amount: renderEuroFloat(donation.amount) }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              className={classes.becameMemberText}
            >
              <FormattedMessage
                messages={messages}
                messageId="confirmRecepit"
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              className={classes.remindMessageText}
            >
              <FormattedMessage
                messages={messages}
                messageId="supportMessage"
              />
              <a
                href={`mailto:${MAIL_TO_SUPPORT}`}
                target="_blank"
                className={classes.goToLinkText}
              >
                <Typography color="primary" component="span">
                  {MAIL_TO_SUPPORT}
                </Typography>
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link to={HOME_PAGE_ROUTE}>
              <b>
                <FormattedMessage messages={messages} messageId="goToLink" />
              </b>
            </Link>
          </Grid>
        </Grid>
      )}
    </Section>
  );
}

DonateSuccessPage.propTypes = {
  loadDonationOrderData: PropTypes.func.isRequired,
  cleanDonationData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  donation: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  donation: makeSelectDonateSuccessPageDonation(),
  error: makeSelectDonateSuccessPageError(),
  loading: makeSelectDonateSuccessPageLoading(),
});

function mapDispatchToProps(dispatch) {
  return {
    loadDonationOrderData: donationId => {
      dispatch(loadDonationOrder(donationId));
    },
    cleanDonationData: () => {
      dispatch(cleanDonation());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(DonateSuccessPage);
