import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  color?: string;
};

export const useStyle = makeStyles<Theme, Props>(theme => ({
  appLink: {
    textDecoration: 'none',
    '-webkit-tap-highlight-color': 'transparent',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',

    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
    },
  },
  linkText: props => ({
    color: props.color || theme.palette.primary.main,
    cursor: 'pointer',
  }),
  linkArrow: props => ({
    color: props.color || theme.palette.primary.main,
    display: 'inline-block',
    marginLeft: 3,
  }),
}));
