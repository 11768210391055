import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the cookiesBanner state domain
 */

const selectCookiesBannerDomain = state => state.cookiesBanner || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by CookiesBanner
 */

const makeSelectCookiesBanner = () =>
  createSelector(selectCookiesBannerDomain, substate => substate);

const makeSelectCookiesBannerLoading = () =>
  createSelector(selectCookiesBannerDomain, substate => substate.loading);

const makeSelectCookiesBannerError = () =>
  createSelector(selectCookiesBannerDomain, substate => substate.error);

const makeSelectCookiesBannerShowCookiesBanner = () =>
  createSelector(
    selectCookiesBannerDomain,
    substate => substate.showCookiesBanner,
  );

export default makeSelectCookiesBanner;
export {
  selectCookiesBannerDomain,
  makeSelectCookiesBannerLoading,
  makeSelectCookiesBannerError,
  makeSelectCookiesBannerShowCookiesBanner,
};
