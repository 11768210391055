import makeStyles from '@mui/styles/makeStyles';
import {
  MAX_WIDTH_PAGE,
  PAGE_CONTAINER_PADDING,
  PAGE_CONTAINER_MOBILE_PADDING,
} from 'utils/constants';

export const useStyle = makeStyles(theme => ({
  footer: {
    width: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  footerSectionsContainer: {
    margin: '0 auto',
    maxWidth: MAX_WIDTH_PAGE,
    padding: PAGE_CONTAINER_PADDING,
    paddingBottom: 0,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: PAGE_CONTAINER_MOBILE_PADDING,
    },
  },
  footerTitle: {
    textTransform: 'capitalize',
    fontSize: 19,
    lineHeight: '20px',
    letterSpacing: '0.05px',
    fontWeight: 700,
    fontFamily: 'Montserrat',
    marginBottom: 10,
  },
}));
