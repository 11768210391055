/**
 *
 * Asynchronously loads the component for Card
 *
 */
import loadable from 'utils/loadable';
import CardAnimatedLoader from 'components/CardAnimatedLoader';

export default loadable(() => import('./index'), {
  fallback: CardAnimatedLoader,
});
