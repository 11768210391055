import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
type CampaignsPageStyleProps = {
  showExclusiveAccessSection: boolean;
};
export const useStyle = makeStyles<Theme, CampaignsPageStyleProps>(theme => ({
  pageLayoutHeader: () => ({
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(3),
    },
  }),
  coverImage: {
    height: '50vh',
    maxHeight: 'none',
    position: 'relative',
    width: '100%',
    objectFit: 'cover',
  },
  title: {
    lineHeight: 1.2,
    fontSize: 32,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
  },
  subTitle: {
    lineHeight: 1.3,
    fontSize: 18,
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      paddingTop: theme.spacing(1),
    },
  },
  sectionTitle: {
    paddingBottom: theme.spacing(1),
    fontSize: 26,
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
      paddingBottom: 0,
    },
  },
  sectionText: {
    fontSize: 16,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  imageContainer: {
    width: '100%',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      maxWidth: 150,
      display: 'block',
    },
  },
  image: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxHeight: 200,
    },
  },
  titleContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
      width: 'calc(100% - 150px)',
    },
  },
  contentPaddedTop: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
  contentPaddedTopClosed: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
    },
  },
  contentPaddedTopFilters: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
  contentPaddedTopProject: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
  contentPaddedTopProjectExclusive: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
    },
  },
  contentPaddedTopProjectLive: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
    },
  },
  closedProjectContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
  },
  news: {
    color: theme.palette.error.main,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  hintTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
  },
  newsBadge: {
    width: '100%',
    marginTop: 10,
    '& .MuiBadge-badge': {
      marginLeft: 50,
      backgroundColor: '#fff',
      border: '1px solid red',
      color: 'red',
    },
  },
  incomeChip: {
    borderRadius: 5,
    backgroundColor: theme.palette.realEstate.main,
    color: theme.palette.realEstate.contrastText,
    height: 22.28,
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 600,
    padding: '1px 8px 0px',
    textTransform: 'uppercase',
    lineHeight: 1.5,
  },
  guideButton: {
    width: '100%',
  },
  newsHint: props => ({
    marginTop: theme.spacing(2),
    paddingTop: props.showExclusiveAccessSection
      ? theme.spacing(3)
      : theme.spacing(0),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingTop: props.showExclusiveAccessSection
        ? theme.spacing(3)
        : theme.spacing(3),
    },
  }),
  hintNewsTitle: {
    fontSize: 26,
    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
  },
  newsText: {
    lineHeight: '24px',
    fontSize: 16,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
}));
