/*
 * StepDonate Messages
 *
 * This contains all the text for the StepDonate container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.StepDonate';

export default defineMessages({
  import: {
    id: `${scope}.import.message`,
    defaultMessage: 'Import (€)',
  },
  importErrorHelper: {
    id: `${scope}.import_error_helper.message`,
    defaultMessage: 'Import is not valid',
  },
  anonymousInvestor: {
    id: `${scope}.anonymous_investor.message`,
    defaultMessage: 'Non mostrare il mio nome tra la lista donatori',
  },
  acceptDonation: {
    id: `${scope}.accept_donation.message`,
    defaultMessage:
      'I declare that I have read and I accept the general conditions of donation {donationLink}',
  },
  acceptDonationTextLink: {
    id: `${scope}.accept_donation_text_link.message`,
    defaultMessage: 'particular condition',
  },
  donate: {
    id: `${scope}.donate.message`,
    defaultMessage: 'Donate',
  },
  goBack: {
    id: `${scope}.go_back.message`,
    defaultMessage: 'Back',
  },
  validationError: {
    id: `${scope}.validation_error.message`,
    defaultMessage: 'Fields not valid, please check your input and retry',
  },
  donateError: {
    id: `${scope}.donate_error.message`,
    defaultMessage:
      'An error has occurred processing your donation. Please reload the page and try again.',
  },
});
