/*
 * InternetExplorerDialog Messages
 *
 * This contains all the text for the InternetExplorerDialog component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.InternetExplorerDialog';

export default defineMessages({
  warning: {
    id: `${scope}.warning.message`,
    defaultMessage: 'Warning',
  },
  infoAboutIE: {
    id: `${scope}.infoAboutIE.message`,
    defaultMessage:
      "We're sorry, but Mamacrowd's web application is not optimized for old browsers. We therefore recommend that you update your browser with a newer version. You can still browse the site, even if some pages may not be perfectly legible.",
  },
  close: {
    id: `${scope}.close.message`,
    defaultMessage: 'close',
  },
});
