import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the stepBasicProfile state domain
 */

const selectStepBasicProfileDomain = state =>
  state.stepBasicProfile || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by StepBasicProfile
 */

const makeSelectStepBasicProfile = () =>
  createSelector(selectStepBasicProfileDomain, substate => substate);

const makeSelectStepBasicProfileBasicProfile = () =>
  createSelector(
    selectStepBasicProfileDomain,
    substate => substate.basicProfile,
  );
const makeSelectStepBasicProfileGetLoading = () =>
  createSelector(selectStepBasicProfileDomain, substate => substate.getLoading);
const makeSelectStepBasicProfileGetError = () =>
  createSelector(selectStepBasicProfileDomain, substate => substate.getError);
const makeSelectStepBasicProfileSetLoading = () =>
  createSelector(selectStepBasicProfileDomain, substate => substate.setLoading);
const makeSelectStepBasicProfileSetError = () =>
  createSelector(selectStepBasicProfileDomain, substate => substate.setError);

export default makeSelectStepBasicProfile;
export {
  selectStepBasicProfileDomain,
  makeSelectStepBasicProfileBasicProfile,
  makeSelectStepBasicProfileGetLoading,
  makeSelectStepBasicProfileGetError,
  makeSelectStepBasicProfileSetLoading,
  makeSelectStepBasicProfileSetError,
};
