/*
 *
 * UtmProvider reducer
 *
 */
import produce from 'immer';
import {
  SET_USER_UTM,
  SET_USER_UTM_ERROR,
  SET_USER_UTM_SUCCESS,
} from './constants';

export const initialState = {
  loading: false,
  error: false,
  utmSaved: false,
};

/* eslint-disable default-case, no-param-reassign */
const utmProviderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_USER_UTM:
        draft.loading = true;
        draft.error = false;
        draft.utmSaved = false;
        break;
      case SET_USER_UTM_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.utmSaved = true;
        break;
      case SET_USER_UTM_ERROR:
        draft.loading = false;
        draft.error = action.error || true;
        draft.utmSaved = false;
        break;
    }
  });

export default utmProviderReducer;
