/*
 * StepBasicProfile Messages
 *
 * This contains all the text for the StepBasicProfile container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.StepBasicProfile';

export default defineMessages({
  firstName: {
    id: `${scope}.first_name.message`,
    defaultMessage: 'Firstname',
  },
  lastName: {
    id: `${scope}.last_name.message`,
    defaultMessage: 'Lastname',
  },
  email: {
    id: `${scope}.email.message`,
    defaultMessage: 'Email',
  },
  businessName: {
    id: `${scope}.business_name.message`,
    defaultMessage: 'Business Name',
  },
  businessNameHelperText: {
    id: `${scope}.business_name_helper_text.message`,
    defaultMessage: 'Required only if legal entity',
  },
  vatNumber: {
    id: `${scope}.vat_number.message`,
    defaultMessage: 'Vat Number',
  },
  vatNumberHelperText: {
    id: `${scope}.vat_number_helper_text.message`,
    defaultMessage: 'Required only if legal entity',
  },
  firstNameErrorHelper: {
    id: `${scope}.first_name_error_helper.message`,
    defaultMessage: 'Must be at least 2 characters',
  },
  lastNameErrorHelper: {
    id: `${scope}.last_name_error_helper.message`,
    defaultMessage: 'Must be at least 2 characters',
  },
  emailErrorHelper: {
    id: `${scope}.email_error_helper.message`,
    defaultMessage: 'Email not valid',
  },
  businessNameErrorHelper: {
    id: `${scope}.business_name_error_helper.message`,
    defaultMessage: 'Must be at least 3 characters',
  },
  vatNumberErrorHelper: {
    id: `${scope}.vat_number_error_helper.message`,
    defaultMessage: 'VAT Number not valid',
  },
  nextStep: {
    id: `${scope}.next_step.message`,
    defaultMessage: 'Next',
  },
  validationError: {
    id: `${scope}.validation_error.message`,
    defaultMessage: 'Fields not valid, please check your input and retry',
  },
  errorGetBasicProfile: {
    id: `${scope}.error_get_basic_profile.message`,
    defaultMessage: 'An error has occured getting your basic profile',
  },
  errorSetBasicProfile: {
    id: `${scope}.error_set_basic_profile.message`,
    defaultMessage:
      'An error has occured setting your new basic profile, check your fields then retry.',
  },
  vatNumberErrorHelperWithChar: {
    id: `${scope}.vat_number_error_helper_with_char.message`,
    defaultMessage:
      'VAT Number must contain country prefix (i.e: ITxxxxxxxxxxx)',
  },
});
