export default {
  sas: 'sas',
  snc: 'snc',
  srl: 'srl',
  srls: 'srls',
  spa: 'spa',
  scpa: 'scpa',
  individuale: 'individuale',
  other: 'other',
};
