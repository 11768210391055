import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles({
  linkContainer: {
    padding: 20,
    borderBottom: '1px solid #eaeaea',
    textAlign: 'right',
  },
  dropdownItem: {
    padding: '6px 16px',
    minHeight: 'auto',
  },
});
