import makeStyles from '@mui/styles/makeStyles';
import { MAX_WIDTH_PAGE } from 'utils/constants';

export const useStyle = makeStyles(theme => ({
  cookiesBannerContainer: {
    maxWidth: MAX_WIDTH_PAGE,
    width: '100%',
    margin: '0 auto',
    padding: '15px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 20px 30px',
    },
  },
  cookiesBannerTitle: {
    fontSize: 21,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  cookiesBannerText: {
    lineHeight: '22px',
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  cookiesBannerLink: {
    lineHeight: '22px',
    fontWeight: 'bold',
    fontSize: 14,
  },
  revertOnDesk: {
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse',
    },
  },
}));
