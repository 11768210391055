import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  liteContainer: {
    minHeight: '100vh',
    backgroundColor: '#eaeaea',
    [theme.breakpoints.down('md')]: {
      backgroundColor: '#ffffff',
    },
  },
}));
