/*
 *
 * CookiesBanner actions
 *
 */

import {
  CLOSE_COOKIES_SETTINGS,
  OPEN_COOKIES_SETTINGS,
  SET_COOKIES_SETTINGS,
  SET_COOKIES_SETTINGS_ERROR,
  SET_COOKIES_SETTINGS_SUCCESS,
} from './constants';

export function openCookieSettings() {
  return {
    type: OPEN_COOKIES_SETTINGS,
  };
}

export function closeCookieSettings() {
  return {
    type: CLOSE_COOKIES_SETTINGS,
  };
}

export function setCookieSettings(cookieSettings) {
  return {
    type: SET_COOKIES_SETTINGS,
    cookieSettings,
  };
}

export function setCookieSettingsSuccess() {
  return {
    type: SET_COOKIES_SETTINGS_SUCCESS,
  };
}

export function setCookieSettingsError() {
  return {
    type: SET_COOKIES_SETTINGS_ERROR,
  };
}
