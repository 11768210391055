/*
 * DonatePage Messages
 *
 * This contains all the text for the DonatePage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.DonatePage';

export default defineMessages({
  donationSubTitle: {
    id: `${scope}.donation_sub_title.message`,
    defaultMessage: 'Donate',
  },
  basicProfileStep: {
    id: `${scope}.basic_profile_step.message`,
    defaultMessage: 'Insert data',
  },
  donateStep: {
    id: `${scope}.donate_step.message`,
    defaultMessage: 'Donation',
  },
});
