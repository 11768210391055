/**
 *
 * LandingLayout
 *
 */

import PropTypes from 'prop-types';
import Footer from 'components/Footer/FooterLastSection';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

// import ScrollToTop from 'components/ScrollToTop';
// import CheckValidToken from 'components/CheckValidToken';
import Box from 'components/Box';
import CookiesBanner from 'containers/CookiesBanner';
// import Intercom from 'components/Intercom';

// import { makeSelectUserLogged } from 'containers/App/selectors';

import { useStyle } from './LandingLayout.style';

export function LandingLayout({ showFooter = false, children }) {
  const classes = useStyle();

  return (
    <>
      <Box className={classes.pageContainer}>{children}</Box>
      {showFooter && <Footer />}
      <CookiesBanner />
    </>
  );
}

LandingLayout.propTypes = {
  children: PropTypes.any.isRequired,
  showFooter: PropTypes.bool,
  // userIsLogged: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  // userIsLogged: makeSelectUserLogged(),
});

export function mapDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LandingLayout);
