/**
 *
 * Header
 *
 */

import PropTypes from 'prop-types';

import {
  Menu as MUIMenu,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import Menu from '@mui/icons-material/Menu';

import Box from 'components/Box';
import Logo from 'components/Logo';

import { useStyle } from './Header.style';
import { useState } from 'react';
import UserButton from 'components/UserButton';
import UserMenu from 'components/UserMenu';
import User from 'utils/User';

function Header({ handleDrawerToggle }) {
  const isOverSM = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const isOverMD = useMediaQuery(theme => theme.breakpoints.up('md'));
  const classes = useStyle();
  const isUserLogged = User.isLogged();
  const { isPowerUser, firstName } = User.getUser() || {};

  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleUserMenu = event => {
    setMenuAnchor(menuAnchor ? null : event.currentTarget);
  };

  return (
    <AppBar position="relative">
      <Toolbar className={classes.headerContainer}>
        {!isOverSM && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <Menu />
          </IconButton>
        )}
        <Box className={classes.logoContainer}>
          <Logo maxWidth={isOverMD ? 180 : 150} />
        </Box>
        <UserButton
          firstName={firstName}
          isLogged={isUserLogged}
          isPowerUser={isPowerUser}
          onClick={handleUserMenu}
        />
      </Toolbar>
      <MUIMenu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleUserMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
      >
        <UserMenu isPowerUser={isPowerUser} isLogged={isUserLogged} isInSec />
      </MUIMenu>
    </AppBar>
  );
}

Header.propTypes = {
  children: PropTypes.any,
};

export default Header;
