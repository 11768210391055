import User from 'utils/User';
import composeRedirectUrl from 'utils/composeRedirectUrl';

import {
  CAMPAIGNS_ROUTE,
  APPLY_PAGE_ROUTE,
  INDEX_KNOWLEDGE_INVESTOR_ROUTE,
  LOGIN_ROUTE,
  NEWS_PAGE_ROUTE,
  // HOME_PAGE_ROUTE,
  REGISTER_ROUTE,
  CONSOLE_PREFIX,
  CONSOLE_HOME_PAGE_ROUTE,
  SIM_PAGE_ROUTE,
  MONITORING_PAGE_ROUTE,
  ME_PORTFOLIO_ROUTE,
  ME_WATCHLIST_ROUTE,
  ME_CAMPAIGNS_ROUTE,
  ME_COMPANY_MONITORING_ROUTE,
  ME_PROFILE_ROUTE,
} from 'utils/constants';

export const getMenuLinks = () => [
  {
    type: 'link',
    variant: 'default',
    translationId: 'invest',
    link: CAMPAIGNS_ROUTE,
  },
  {
    type: 'link',
    variant: 'default',
    translationId: 'raise',
    link: APPLY_PAGE_ROUTE,
  },
  {
    type: 'link',
    variant: 'default',
    translationId: 'guide',
    link: INDEX_KNOWLEDGE_INVESTOR_ROUTE,
  },
  {
    type: 'link',
    variant: 'default',
    translationId: 'blog',
    link: NEWS_PAGE_ROUTE,
  },
];

export const getAccountLinks = () => ({
  unlogged: [
    {
      type: 'link',
      variant: 'primary',
      translationId: 'login',
      link: composeRedirectUrl(LOGIN_ROUTE),
    },
    {
      type: 'button',
      variant: 'secondary',
      translationId: 'register',
      link: composeRedirectUrl(REGISTER_ROUTE),
    },
  ],
  logged: [
    {
      type: 'link',
      variant: 'default',
      icon: 'Dashboard',
      translationId: 'consoleLink',
      link: CONSOLE_PREFIX + CONSOLE_HOME_PAGE_ROUTE,
      hidden:
        !User.getUserPermissions() ||
        (User.getUserPermissions() &&
          Object.keys(User.getUserPermissions()).length === 1 &&
          Object.keys(User.getUserPermissions()).includes('sim')),
    },
    {
      type: 'link',
      variant: 'default',
      icon: 'Sim',
      translationId: 'simLink',
      link: SIM_PAGE_ROUTE,
      hidden:
        !User.getUserPermissions() ||
        (User.getUserPermissions() &&
          !!Object.keys(User.getUserPermissions()).length &&
          !Object.keys(User.getUserPermissions()).includes('sim') &&
          !Object.keys(User.getUserPermissions()).includes('*')),
    },
    {
      type: 'link',
      variant: 'default',
      icon: 'Monitoring',
      translationId: 'monitoringLink',
      link: MONITORING_PAGE_ROUTE,
      hidden: !User.getUserProp('companyMonitoringProfileOwner'),
    },
    {
      type: 'link',
      variant: 'default',
      icon: 'Portfolio',
      translationId: 'portfolioLink',
      link: ME_PORTFOLIO_ROUTE,
    },
    {
      type: 'link',
      variant: 'default',
      icon: 'Watchlist',
      translationId: 'watchlistLink',
      link: ME_WATCHLIST_ROUTE,
    },
    {
      type: 'link',
      variant: 'default',
      icon: 'MyCampaign',
      translationId: 'myCampaignLink',
      link: ME_CAMPAIGNS_ROUTE,
      hidden: !User.getUserProp('campaignOwner'),
    },
    {
      type: 'link',
      variant: 'default',
      icon: 'MyCompanyReports',
      translationId: 'myCompanyReportsLink',
      link: ME_COMPANY_MONITORING_ROUTE,
      hidden: !User.getUserProp('companyMonitoringOwner'),
    },
    {
      type: 'link',
      variant: 'default',
      icon: 'Profile',
      translationId: 'profileLink',
      link: ME_PROFILE_ROUTE,
    },
  ],
});
