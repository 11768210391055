import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import SessionStorage from 'utils/sessionStorage';

const SCROLL_RESTORATION_KEY = 'restorationHook';

export const useRestorationHook = (
  options: { timeToWait?: number; active } = { timeToWait: 100, active: false },
) => {
  const { timeToWait, active } = options;
  const location = useLocation();

  const restorationHook: {
    [key: string]: { pathname: string; scrollY: number };
  } = SessionStorage.getItem(SCROLL_RESTORATION_KEY) || {};

  useLayoutEffect(() => {
    if (active) {
      if (location && restorationHook && restorationHook[location.key]) {
        setTimeout(() => {
          window.scrollTo(0, restorationHook[location.key].scrollY);
        }, timeToWait);
      }
    }

    return () => {
      if (active) {
        const clearedRestorationHookKeys = Object.keys(restorationHook).filter(
          k =>
            (k && k === location.key) ||
            (k &&
              k !== location.key &&
              restorationHook[k].pathname &&
              restorationHook[k].pathname !== location.pathname),
        );
        const clearedRestorationHook: {
          [key: string]: { pathname: string; scrollY: number };
        } = {};

        clearedRestorationHookKeys.forEach(k => {
          clearedRestorationHook[k] = restorationHook[k];
        });

        SessionStorage.setItem(SCROLL_RESTORATION_KEY, {
          ...clearedRestorationHook,
          [location.key]: {
            pathname: location.pathname,
            scrollY: window.scrollY,
          },
        });
      }
    };
  }, [location, active]);
};

export default useRestorationHook;
