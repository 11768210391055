/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_LOGIN = 'app/App/LOAD_LOGIN';
export const LOAD_LOGIN_SUCCESS = 'app/App/LOAD_LOGIN_SUCCESS';
export const LOAD_LOGIN_ERROR = 'app/App/LOAD_LOGIN_ERROR';
export const CLEAN_LOGIN_ERROR = 'app/App/CLEAN_LOGIN_ERROR';

export const LOAD_LOGOUT = 'app/App/LOAD_LOGOUT';
export const LOAD_LOGOUT_SUCCESS = 'app/App/LOAD_LOGOUT_SUCCESS';
export const LOAD_LOGOUT_ERROR = 'app/App/LOAD_LOGOUT_ERROR';

export const LOAD_USER = 'app/App/LOAD_USER';
export const LOAD_USER_SUCCESS = 'app/App/LOAD_USER_SUCCESS';
export const LOAD_USER_ERROR = 'app/App/LOAD_USER_ERROR';

export const LOAD_CONSOLE_ACTIVE_CAMPAIGN =
  'app/App/LOAD_CONSOLE_ACTIVE_CAMPAIGN';
export const LOAD_CONSOLE_ACTIVE_CAMPAIGN_ERROR =
  'app/App/LOAD_CONSOLE_ACTIVE_CAMPAIGN_ERROR';
export const SET_CONSOLE_ACTIVE_CAMPAIGN =
  'app/App/SET_CONSOLE_ACTIVE_CAMPAIGN';
export const SET_CONSOLE_ACTIVE_COMPANY = 'app/App/SET_CONSOLE_ACTIVE_COMPANY';
export const SET_CONSOLE_ACTIVE_ORDER = 'app/App/SET_CONSOLE_ACTIVE_ORDER';
export const SET_CONSOLE_ACTIVE_USER = 'app/App/SET_CONSOLE_ACTIVE_USER';
export const SET_CONSOLE_ACTIVE_PARTNER = 'app/App/SET_CONSOLE_ACTIVE_PARTNER';
export const SET_CONSOLE_ACTIVE_TESTIMONIAL =
  'app/App/SET_CONSOLE_ACTIVE_TESTIMONIAL';
export const SET_CONSOLE_ACTIVE_ARTICLE = 'app/App/SET_CONSOLE_ACTIVE_ARTICLE';
export const SET_CONSOLE_ACTIVE_MESSAGE = 'app/App/SET_CONSOLE_ACTIVE_MESSAGE';
export const SET_CONSOLE_ACTIVE_BANNER = 'app/App/SET_CONSOLE_ACTIVE_BANNER';
export const SET_CONSOLE_ACTIVE_PAGE = 'app/App/SET_CONSOLE_ACTIVE_PAGE';
export const SET_CONSOLE_ACTIVE_PRESS = 'app/App/SET_CONSOLE_ACTIVE_PRESS';
export const SET_CONSOLE_ACTIVE_FAQ = 'app/App/SET_CONSOLE_ACTIVE_FAQ';
export const SET_CONSOLE_ACTIVE_SETTING = 'app/App/SET_CONSOLE_ACTIVE_SETTING';
export const SET_CONSOLE_ACTIVE_INDUSTRY =
  'app/App/SET_CONSOLE_ACTIVE_INDUSTRY';
