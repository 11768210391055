/* eslint-disable @typescript-eslint/dot-notation */
/**
 *
 * Intecom
 *
 */

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { CircularProgress } from '@mui/material';

import IconButton from 'basic/IconButton';
import { makeSelectUserLogged } from 'containers/App/selectors';
import { makeSelectCookiesBannerShowCookiesBanner } from 'containers/CookiesBanner/selectors';

import IntercomIcon from 'images/intercom-icon.svg';

import User from 'utils/User';
import { setIntercomUserData, startIntercom } from 'utils/intercom';
import { INTERCOM_APP_ID } from 'utils/constants';

import { useStyle } from './Intercom.style';

export interface IntercomProps {
  userLogged: boolean;
  isCookiesBannerActived: boolean;
}

function Intercom({
  userLogged,
  isCookiesBannerActived = false,
}: IntercomProps) {
  const [hideFacade, setHideFacade] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const classes = useStyle();
  const appId = INTERCOM_APP_ID;

  const startIntercomScript = (isClicked = false) => {
    startIntercom(
      'show',
      appId,
      () => setHideFacade(true),
      User,
      isCookiesBannerActived,
      userLogged,
      isClicked,
    );
  };

  useEffect(() => {
    if (window?.Intercom?.booted) {
      setHideFacade(true);
      startIntercomScript(false);
    }
  }, []);

  useEffect(() => {
    setIntercomUserData(User, isCookiesBannerActived, userLogged);
  }, [userLogged, isCookiesBannerActived]);

  return hideFacade || window?.Intercom?.booted ? null : (
    <div className={classes.intercomFacade}>
      <IconButton
        onClick={() => {
          setDisabledButton(true);
          startIntercomScript(true);
        }}
        disabled={disabledButton}
        icon={
          disabledButton ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            <img src={IntercomIcon} width={24} height={24} />
          )
        }
        text={null}
      />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  userLogged: makeSelectUserLogged(),
  isCookiesBannerActived: makeSelectCookiesBannerShowCookiesBanner(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Intercom);
