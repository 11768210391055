import { takeLatest, call, put } from 'redux-saga/effects';
import Logger from 'loglevel';
import makeApiUrl from 'utils/makeApiUrl';
import { ME_INVESTMENT_ORDER_URL } from 'utils/constants';
import { request } from 'utils/request';
import { manageSagaError } from 'utils/errorManager';

import { loadDonationOrderSuccess, loadDonationOrderError } from './actions';
import { LOAD_DONATION_ORDER } from './constants';

export function* getDonationOrderData(payload) {
  const requestDonationOrderUrl = makeApiUrl(
    ME_INVESTMENT_ORDER_URL.replace(':investmentOrderId', payload.donationId),
  );
  try {
    const { investmentOrder } = yield call(request, requestDonationOrderUrl);
    Logger.log(['SAGAS', 'DONATION ORDER', 'SUCCESS', investmentOrder]);
    yield put(loadDonationOrderSuccess(investmentOrder));
  } catch (err) {
    const error = yield manageSagaError('DonateSuccessPage', err);
    yield put(loadDonationOrderError(error));
  }
}

export default function* donateSuccessPageSaga() {
  yield takeLatest(LOAD_DONATION_ORDER, getDonationOrderData);
}
