/*
 * CampaignsPageFilters Messages
 *
 * This contains all the text for the CampaignsPageFilters container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CampaignsPageFilters';

export default defineMessages({
  investmentFilter: {
    id: `${scope}.investment_filter.message`,
    defaultMessage: 'Latest Investment',
  },
  newestFilter: {
    id: `${scope}.newest_filter.message`,
    defaultMessage: 'Newest',
  },
  amountRaised: {
    id: `${scope}.value_raised_filter.message`,
    defaultMessage: 'Raised',
  },
  dropdownTitle: {
    id: `${scope}.dropdown_title.message`,
    defaultMessage: 'Filter',
  },
  equityLabel: {
    id: `${scope}.equity_label.message`,
    defaultMessage: 'Equity',
  },
  minibondLabel: {
    id: `${scope}.minibond_label.message`,
    defaultMessage: 'Minibond',
  },
  chipEquity: {
    id: `${scope}.chip_equity.message`,
    defaultMessage: 'Equity Company',
  },
  chipRealEstate: {
    id: `${scope}.chip_real_estate.message`,
    defaultMessage: 'Equity Real Estate',
  },
  news: {
    id: `${scope}.news.message`,
    defaultMessage: 'News',
  },
});
