/*
 *
 * DonateSuccessPage reducer
 *
 */
import produce from 'immer';
import {
  LOAD_DONATION_ORDER,
  LOAD_DONATION_ORDER_ERROR,
  LOAD_DONATION_ORDER_SUCCESS,
  CLEAN_DONATION,
} from './constants';

export const initialState = {
  loading: false,
  donation: null,
  error: false,
};

/* eslint-disable default-case, no-param-reassign */
const donateSuccessPageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_DONATION_ORDER:
        draft.loading = true;
        draft.error = false;
        draft.donation = null;
        break;
      case LOAD_DONATION_ORDER_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.donation = action.donation;
        break;
      case LOAD_DONATION_ORDER_ERROR:
        draft.loading = false;
        draft.error = action.error || true;
        draft.donation = null;
        break;
      case CLEAN_DONATION:
        draft.loading = false;
        draft.error = false;
        draft.donation = null;
        break;
    }
  });

export default donateSuccessPageReducer;
