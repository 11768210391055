/* eslint-disable react/jsx-no-useless-fragment */
/**
 *
 * CampaignsPage
 *
 */

import { ReactNode, useEffect, useState } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';

import { useMediaQuery, Grid, Theme } from '@mui/material';
import { Typography, Button, Loader } from '@mamacrowd/ui-kit';

import Card from 'components/Card/Loadable';
import ErrorForm from 'components/ErrorForm';
import PageLayout from 'components/PageLayout';
import FormattedMessage from 'components/FormattedMessage';
import CampaignsPageFilters from 'containers/CampaignsPageFilters';
import HintWithNewsBadge from 'components/HintWithNewsBadge';
import LazyLoadImage from 'components/LazyLoadImage';
import {
  makeChangeFilter,
  makeSelectQuery,
  makeSelectTypes,
  makeSelectFiltersChanged,
} from 'containers/CampaignsPageFilters/selectors';
import {
  cleanSearchFilters,
  clearFiltersChanged,
} from 'containers/CampaignsPageFilters/actions';

import SelectInvestor from 'images/PageIcons/invest.svg';

import User from 'utils/User';
import ENUMPageLayout from 'utils/Enums/PageLayout';
import { trackViewItemList, trackSelectItem } from 'utils/trackers';
import { ProjectList } from 'utils/schemaOrg';
import {
  DOMAIN_URL,
  CAMPAIGNS_ROUTE,
  INDEX_KNOWLEDGE_INVESTOR_ROUTE,
  USER_PRIVATE_CAMPAIGN_PROPERTY,
  USER_RESTRICTED_CAMPAIGN_PROPERTY,
} from 'utils/constants';

import { FEParsedError } from 'types/Error';
import { Campaign, CampaignType, isTypeREIncome } from 'types/Campaign';

import {
  makeSelectCampaignsPageActiveLoading,
  makeSelectCampaignsPageClosedLoading,
  makeSelectCampaignsPageActiveError,
  makeSelectCampaignsPageClosedError,
  makeSelectCampaignsPageActive,
  makeSelectCampaignsPageRestricted,
  makeSelectCampaignsPagePrivate,
  makeSelectCampaignsPageClosing,
  makeSelectCampaignsPageClosed,
  makeSelectCampaignsPagePage,
  makeSelectCampaignsPageFeatured,
  makeSelectCampaignsPageComingSoon,
} from './selectors';
import {
  loadActiveCampaigns,
  loadClosedCampaigns,
  filterActiveCampaigns,
  filterClosedCampaigns,
  clearClosedCampaigns,
  clearCampaigns,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import EmptyState from './EmptyState';
import { useStyle } from './CampaignsPage.style';
import useRestorationHook from 'hooks/useScrollRestoration';
import useCleanDataOnHistoryPush from 'hooks/useCleanDataOnHistoryPush';

export interface CampaignsPageProps {
  activeLoading: boolean;
  closedLoading: boolean;
  activeError: boolean | FEParsedError;
  closedError: boolean | FEParsedError;
  campaignsPrivate: Campaign[];
  campaignsRestricted: Campaign[];
  campaignsActive: Campaign[];
  campaignsComingSoon: Campaign[];
  campaignsFeatured: Campaign[];
  campaignsClosing: Campaign[];
  campaignsClosed?: { total: number; results: Campaign[] };
  page: number;
  filter: string;
  query: string;
  campaignTypes: CampaignType[];
  filtersChanged: boolean;
  loadActiveCampaignsData: (
    filter: string,
    query: string,
    types: CampaignType[],
  ) => void;
  loadClosedCampaignsData: (
    page: number,
    filter: string,
    query: string,
    types: CampaignType[],
    limit?: number,
  ) => void;
  filterActiveCampaignsData: (
    filter: string,
    query: string,
    types: CampaignType[],
  ) => void;
  filterClosedCampaignsData: (
    page: number,
    filter: string,
    query: string,
    types: CampaignType[],
  ) => void;
  clearFiltersChangedData: () => void;
  clearClosedCampaignsData: () => void;
  clearCampaignsData: () => void;
  clearSearchFiltersData: () => void;
}

export function CampaignsPage({
  activeLoading,
  closedLoading,
  activeError,
  closedError,
  campaignsPrivate,
  campaignsRestricted,
  campaignsActive,
  campaignsComingSoon,
  campaignsFeatured,
  campaignsClosing,
  campaignsClosed,
  loadActiveCampaignsData,
  loadClosedCampaignsData,
  filterActiveCampaignsData,
  filterClosedCampaignsData,
  clearFiltersChangedData,
  clearClosedCampaignsData,
  clearSearchFiltersData,
  clearCampaignsData,
  page,
  filter,
  query,
  campaignTypes,
  filtersChanged,
}: CampaignsPageProps) {
  useInjectReducer({ key: 'campaignsPage', reducer });
  useInjectSaga({ key: 'campaignsPage', saga });
  const [showNewsBox, setShowNewsBox] = useState(false);

  const intl = useIntl();
  const [snackbarError, setSnackbarError] = useState<{
    message: ReactNode;
    details: string;
  } | null>(null);

  useCleanDataOnHistoryPush(
    () => {
      clearSearchFiltersData();
      clearCampaignsData();
    },
    { active: true },
  );

  const { total, results } = campaignsClosed || { total: 0, results: [] };
  const overXS = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
  const showLoadMoreButton =
    !campaignsClosed ||
    (!results.length && !total && !query) ||
    (results.length > 0 && total > 0 && results.length < total);
  const hasNoLiveProjectsWithoutQuery =
    !campaignsPrivate.length &&
    !campaignsRestricted.length &&
    !campaignsActive.length &&
    !campaignsComingSoon.length &&
    !query;

  useEffect(() => {
    if (
      !activeLoading &&
      (campaignsPrivate?.length ||
        campaignsRestricted?.length ||
        campaignsActive?.length ||
        campaignsComingSoon?.length)
    ) {
      trackViewItemList([
        ...campaignsPrivate,
        ...campaignsRestricted,
        ...campaignsActive,
        ...campaignsComingSoon,
      ]);
    }
  }, [
    campaignsPrivate,
    campaignsRestricted,
    campaignsActive,
    campaignsComingSoon,
  ]);

  useEffect(() => {
    if (
      loadActiveCampaignsData &&
      !(
        campaignsPrivate?.length &&
        campaignsRestricted?.length &&
        campaignsActive?.length &&
        campaignsComingSoon?.length
      )
    ) {
      loadActiveCampaignsData(filter, query, campaignTypes);
    }
  }, [loadActiveCampaignsData]);

  useEffect(() => {
    if (filtersChanged) {
      if (query) {
        filterClosedCampaignsData(0, filter, query, campaignTypes);
      } else {
        filterActiveCampaignsData(filter, query, campaignTypes);
        clearClosedCampaignsData();
      }
      clearFiltersChangedData();
    } else {
      loadActiveCampaignsData(filter, query, campaignTypes);
    }
  }, [filter, query, campaignTypes, filtersChanged]);

  useEffect(() => {
    if (
      !activeLoading &&
      hasNoLiveProjectsWithoutQuery &&
      !campaignsClosed?.results?.length
    ) {
      loadClosedCampaignsData(0, filter, '', campaignTypes, 3);
    }
  }, [
    campaignsPrivate,
    campaignsRestricted,
    campaignsActive,
    campaignsComingSoon,
    activeLoading,
  ]);

  useEffect(() => {
    if (activeError || closedError) {
      const details =
        (typeof activeError !== 'boolean' && activeError?.details) ||
        (typeof closedError !== 'boolean' && closedError?.details);

      setSnackbarError({
        message: <FormattedMessage messages={messages} messageId="getError" />,
        details: details || '',
      });
    }
  }, [activeError, closedError]);

  const loadMore = () => {
    if (showLoadMoreButton) {
      loadClosedCampaignsData(
        page,
        filter,
        query,
        campaignTypes,
        hasNoLiveProjectsWithoutQuery ? 3 : undefined,
      );
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarError(null);
  };

  useEffect(() => {
    if (!showNewsBox) {
      if (
        (campaignTypes.includes(CampaignType.realEstate) ||
          !campaignTypes.length) &&
        (campaignsPrivate.length ||
          campaignsActive.length ||
          campaignsRestricted.length ||
          campaignsComingSoon.length)
      ) {
        const foundPrivateREIncome = !!campaignsPrivate.find(item =>
          isTypeREIncome(item.type),
        );
        const foundRestrictedREIncome = !!campaignsRestricted.find(item =>
          isTypeREIncome(item.type),
        );
        const foundActiveREIncome = !!campaignsActive.find(item =>
          isTypeREIncome(item.type),
        );
        const foundComingSoonREIncome = !!campaignsComingSoon.find(item =>
          isTypeREIncome(item.type),
        );

        if (
          foundPrivateREIncome ||
          foundRestrictedREIncome ||
          foundActiveREIncome ||
          foundComingSoonREIncome
        ) {
          setShowNewsBox(true);
        }
      }
    }
  }, [
    campaignsPrivate,
    campaignsRestricted,
    campaignsActive,
    campaignsComingSoon,
  ]);

  const showExclusiveAccessSection =
    (User.getUserProp(USER_PRIVATE_CAMPAIGN_PROPERTY) ||
      User.getUserProp(USER_RESTRICTED_CAMPAIGN_PROPERTY)) &&
    (!!campaignsPrivate.length || !!campaignsRestricted.length);

  const classes = useStyle({ showExclusiveAccessSection });

  useRestorationHook({
    active:
      !!campaignsPrivate?.length ||
      !!campaignsRestricted?.length ||
      !!campaignsActive?.length ||
      !!campaignsComingSoon?.length ||
      !!campaignsClosed?.results?.length,
  });

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.SEO_TITLE)}</title>
        <link rel="canonical" href={`${DOMAIN_URL}${CAMPAIGNS_ROUTE}`} />
        <meta
          name="description"
          content={intl.formatMessage(messages.SEO_DESCRIPTION)}
        />
        <meta
          name="keywords"
          content={intl.formatMessage(messages.SEO_KEYWORDS)}
        />
        <meta
          property="og:title"
          content={`${intl.formatMessage(messages.SEO_TITLE)}`}
        />
        <meta
          property="og:description"
          content={intl.formatMessage(messages.SEO_DESCRIPTION)}
        />
        <script type="application/ld+json">
          {ProjectList(campaignsActive)}
        </script>
      </Helmet>
      <PageLayout type={ENUMPageLayout.noTitle}>
        <Grid container>
          <Grid item xs={12} className={classes.pageLayoutHeader}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={3}
                className={classes.imageContainer}
                component="div"
              >
                <LazyLoadImage
                  src={SelectInvestor}
                  alt={'select-investor'}
                  className={classes.image}
                  transparentBgWhenLoaded
                />
              </Grid>
              <Grid item className={classes.titleContainer}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h1"
                      color="textPrimary"
                      className={classes.title}
                    >
                      <FormattedMessage
                        messages={messages}
                        messageId="campaignsPageTitle"
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="textPrimary"
                      className={classes.subTitle}
                    >
                      <FormattedMessage
                        messages={messages}
                        messageId="campaignsDecription"
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {activeLoading ? (
              <Loader isLoading mode="remaining-view" />
            ) : (
              <>
                <Grid container>
                  {showExclusiveAccessSection && (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                            className={classes.sectionTitle}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="exclusiveCampaignsTitle"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            className={classes.sectionText}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="exclusiveCampaignsDescription"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={3}
                            direction={overXS ? 'row' : 'column'}
                            alignItems="stretch"
                            className={classes.contentPaddedTopProjectExclusive}
                          >
                            {/* CAMPAIGNS PRIVATE */}
                            {campaignsPrivate.map(item => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={item.title}
                                onClick={() => trackSelectItem(item)}
                              >
                                <Card campaign={item} />
                              </Grid>
                            ))}
                            {/* CAMPAIGNS RESTICTED */}
                            {campaignsRestricted.map(item => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={item.title}
                                onClick={() => trackSelectItem(item)}
                              >
                                <Card campaign={item} isUserInList />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {showNewsBox && (
                    <Grid item xs={12} className={classes.newsHint}>
                      <HintWithNewsBadge
                        chipContent={
                          <FormattedMessage
                            messages={messages}
                            messageId="news"
                          />
                        }
                        hintTitle={
                          <Typography variant="h3" marginBottom={2}>
                            <FormattedMessage
                              messages={messages}
                              messageId="realEstateIncomeTitle"
                            />
                          </Typography>
                        }
                      >
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          className={classes.newsText}
                        >
                          <FormattedMessage
                            messages={messages}
                            messageId="realEstateIncomeDescription"
                            values={{
                              incomeChip: (
                                <span className={classes.incomeChip}>
                                  <FormattedMessage
                                    messages={messages}
                                    messageId="income"
                                  />
                                </span>
                              ),
                              guideLink: (
                                <a
                                  href={`${INDEX_KNOWLEDGE_INVESTOR_ROUTE}?tab=immobiliare-reddito`}
                                  target="_blank"
                                >
                                  <FormattedMessage
                                    messages={messages}
                                    messageId="readGuide"
                                  />
                                </a>
                              ),
                            }}
                          />
                        </Typography>
                      </HintWithNewsBadge>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    className={classes.contentPaddedTopFilters}
                  >
                    <CampaignsPageFilters showNewsLabel={showNewsBox} />
                  </Grid>
                  {!query && (
                    <Grid item xs={12}>
                      {!!campaignsActive.length || !!campaignsComingSoon.length ? ( // eslint-disable-line
                        <Grid
                          container
                          spacing={3}
                          direction={overXS ? 'row' : 'column'}
                          alignItems="stretch"
                          className={classes.contentPaddedTopProjectLive}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="h3"
                              color="textPrimary"
                              className={classes.sectionTitle}
                            >
                              <FormattedMessage
                                messages={messages}
                                messageId="liveCampaigns"
                              />
                            </Typography>
                          </Grid>
                          {/* CAMPAIGNS ACTIVE */}
                          {campaignsActive.map(item => (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              key={item.title}
                              onClick={() => trackSelectItem(item)}
                            >
                              <Card campaign={item} />
                            </Grid>
                          ))}
                          {/* CAMPAIGNS COMING SOON */}
                          {campaignsComingSoon.map(item => (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              key={item.title}
                              onClick={() => trackSelectItem(item)}
                            >
                              <Card campaign={item} />
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Grid item xs={12} className={classes.contentPaddedTop}>
                          <EmptyState query={query} types={campaignTypes} />
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {!!campaignsFeatured.length && !query && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={3}
                        direction={overXS ? 'row' : 'column'}
                        alignItems="stretch"
                        className={classes.contentPaddedTopProjectLive}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="h2"
                            color="textPrimary"
                            className={classes.sectionTitle}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="featuredCampaigns"
                            />
                          </Typography>
                        </Grid>
                        {campaignsFeatured.map(item => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={item.title}
                            onClick={() => trackSelectItem(item)}
                          >
                            <Card campaign={item} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  {(!!campaignsClosing.length || !!results?.length) && (
                    <>
                      {!query && (
                        <Grid
                          item
                          xs={12}
                          className={classes.contentPaddedTopClosed}
                        >
                          <Typography
                            variant="h2"
                            color="textPrimary"
                            className={classes.sectionTitle}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="campaignsClosed"
                            />
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Grid
                          container
                          spacing={3}
                          direction={overXS ? 'row' : 'column'}
                          alignItems="stretch"
                          className={classes.closedProjectContainer}
                        >
                          {/* CAMPAIGNS CLOSED */}
                          {results?.map(item => (
                            <Grid item xs={12} sm={6} md={4} key={item.title}>
                              <Card
                                campaign={item}
                                isUserInList={!item.isRestricted}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {query && !results?.length && (
                    <>
                      {activeLoading ? (
                        <Grid item xs={12} className={classes.contentPaddedTop}>
                          <Loader isLoading mode="remaining-view" />
                        </Grid>
                      ) : (
                        <Grid item xs={12} className={classes.contentPaddedTop}>
                          <EmptyState query={query} types={campaignTypes} />
                        </Grid>
                      )}
                    </>
                  )}
                  {showLoadMoreButton && (
                    <Grid
                      item
                      xs={12}
                      className={classes.contentPaddedTopProject}
                      style={{ textAlign: 'center' }}
                    >
                      <Button
                        onClick={loadMore}
                        disabled={closedLoading}
                        loading={closedLoading}
                      >
                        <FormattedMessage
                          messages={messages}
                          messageId="showMore"
                        />
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <ErrorForm
                  toast
                  errorMessage={snackbarError ? snackbarError.message : null}
                  details={snackbarError ? snackbarError.details : null}
                  handleSnackbarClose={handleSnackbarClose}
                />
              </>
            )}
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  activeLoading: makeSelectCampaignsPageActiveLoading(),
  closedLoading: makeSelectCampaignsPageClosedLoading(),
  activeError: makeSelectCampaignsPageActiveError(),
  closedError: makeSelectCampaignsPageClosedError(),
  campaignsPrivate: makeSelectCampaignsPagePrivate(),
  campaignsRestricted: makeSelectCampaignsPageRestricted(),
  campaignsActive: makeSelectCampaignsPageActive(),
  campaignsComingSoon: makeSelectCampaignsPageComingSoon(),
  campaignsClosing: makeSelectCampaignsPageClosing(),
  campaignsClosed: makeSelectCampaignsPageClosed(),
  campaignsFeatured: makeSelectCampaignsPageFeatured(),
  page: makeSelectCampaignsPagePage(),
  filter: makeChangeFilter(),
  query: makeSelectQuery(),
  campaignTypes: makeSelectTypes(),
  filtersChanged: makeSelectFiltersChanged(),
});

export function mapDispatchToProps(dispatch) {
  return {
    loadActiveCampaignsData: (
      filter: string,
      query: string,
      types: CampaignType[],
    ) => {
      dispatch(loadActiveCampaigns(filter, query, types));
    },
    loadClosedCampaignsData: (
      page: number,
      filter: string,
      query: string,
      types: CampaignType[],
      limit?: number,
    ) => {
      dispatch(loadClosedCampaigns(page, filter, query, types, limit));
    },
    filterActiveCampaignsData: (
      filter: string,
      query: string,
      types: CampaignType[],
    ) => {
      dispatch(filterActiveCampaigns(filter, query, types));
    },
    filterClosedCampaignsData: (
      page: number,
      filter: string,
      query: string,
      types: CampaignType[],
    ) => {
      dispatch(filterClosedCampaigns(page, filter, query, types));
    },
    clearFiltersChangedData: () => {
      dispatch(clearFiltersChanged());
    },
    clearSearchFiltersData: () => {
      dispatch(cleanSearchFilters());
    },
    clearClosedCampaignsData: () => {
      dispatch(clearClosedCampaigns());
    },
    clearCampaignsData: () => {
      dispatch(clearCampaigns());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CampaignsPage);
