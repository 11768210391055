/*
 * Chip Messages
 *
 * This contains all the text for the Chip component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Chip';

export default defineMessages({
  new: {
    id: `${scope}.new.message`,
    defaultMessage: 'New',
  },
});
