import Logger from 'loglevel';
export const LAST_TOKEN_REFRESH_DATE = 'lastTokenRefreshDate';

export const sessionStorageSupported = (() => {
  try {
    sessionStorage.setItem('test', true);
    sessionStorage.removeItem('test');
    return true;
  } catch (e) {
    Logger.error('Session storage not supported!', e);
    return false;
  }
})();

const SessionStorage = {
  setItem: (key, item) => {
    if (sessionStorageSupported) {
      if (typeof item === 'string') {
        sessionStorage.setItem(key, item);
      } else {
        sessionStorage.setItem(key, JSON.stringify(item));
      }
    } else {
      Logger.error('Session storage is not supported');
    }
  },
  getItem: key => {
    let sessionStorageItem = null;

    if (sessionStorageSupported) {
      sessionStorageItem = sessionStorage.getItem(key);
      // TODO: Make this string or object (also in localstorage)
      if (sessionStorageItem) {
        try {
          return JSON.parse(sessionStorageItem);
        } catch (e) {
          return sessionStorageItem;
        }
      }
    }

    return null;
  },
  remove: key => {
    if (sessionStorageSupported) {
      sessionStorage.removeItem(key);
    }
  },
};

export default SessionStorage;
