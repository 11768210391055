const formatterEuro = () =>
  new Intl.NumberFormat('it', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const formatterMlnEuro = () =>
  new Intl.NumberFormat('it', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

const formatterFloat = (decimal = 2) =>
  new Intl.NumberFormat('it', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });

const formatterDecimal = (decimal = 2) =>
  new Intl.NumberFormat('it', {
    style: 'decimal',
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });

export function renderEuroValue(euroValue) {
  return formatterEuro().format(euroValue);
}

export function renderMlnEuro(euroValue) {
  return `${formatterMlnEuro().format(euroValue / 1000000)} Mln€`;
}

export function renderEuroFloat(euroValue, decimal = 2) {
  return `${formatterFloat(decimal).format(euroValue)}`;
}

export function renderEuroIntFloat(euroValue, decimal = 2) {
  // remove ",00" from the end of the string
  if ((parseFloat(euroValue) | 0) < parseFloat(euroValue)) {
    return renderEuroFloat(euroValue, decimal);
  }
  return renderEuroValue(euroValue);
}

export function renderNumberFloat(value, decimal = 2) {
  return `${formatterDecimal(decimal).format(value)}`;
}

export function renderNumberFloatOneDigit(value) {
  return `${formatterDecimal(1).format(value)}`;
}

export function renderNumberEuroFormatted(value) {
  return `${formatterDecimal(1).format(value)}`;
}

// https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
export function numberFormatter(num = 0, decimal = 2) {
  const numberMap = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  // const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = numberMap.length - 1; i > 0; i -= 1) {
    if (num >= numberMap[i].value) {
      break;
    }
  }
  return `${(num / numberMap[i].value).toFixed(decimal)}${numberMap[i].symbol}`;
}

export function numberFormatterCard(num = 0) {
  const numberMap = [
    { value: 1, symbol: '', precision: 2 },
    { value: 1e3, symbol: 'k', precision: 0 },
    { value: 1e6, symbol: 'M', precision: 1 },
  ];
  // const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = numberMap.length - 1; i > 0; i -= 1) {
    if (num >= numberMap[i].value) {
      break;
    }
  }
  return `${(num / numberMap[i].value).toFixed(numberMap[i].precision)}${
    numberMap[i].symbol
  }`;
}

export function numberEuroFormatter(num, decimal = 2) {
  let value = num;
  if (typeof num === 'string') {
    value = parseFloat(num);
  }
  const sign = Math.sign(value);
  const numberMap = [
    { value: 1, symbol: ' €' },
    { value: 1e3, symbol: 'k €' },
    { value: 1e6, symbol: 'Mln €' },
  ];
  // const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = numberMap.length - 1; i > 0; i -= 1) {
    if (Math.abs(value) >= numberMap[i].value) {
      break;
    }
  }
  const result = (Math.abs(value) / numberMap[i].value).toFixed(decimal);
  if (Number.isInteger(parseFloat(result))) {
    return `${renderNumberFloat(sign * parseFloat(result), 0)}${
      numberMap[i].symbol
    }`;
  }
  return `${renderNumberFloat(sign * parseFloat(result), decimal)}${
    numberMap[i].symbol
  }`;
}

export function numberEuroFormatterOnlyMln(num) {
  let value = num;
  if (typeof num === 'string') {
    value = parseFloat(num);
  }
  const formattedNumber =
    `${value}`.split('.')[0].length > 6
      ? renderMlnEuro(value)
      : renderEuroValue(value);

  return formattedNumber;
}
