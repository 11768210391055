/*
 *
 * HeaderMessage actions
 *
 */

import {
  LOAD_MESSAGE,
  LOAD_MESSAGE_SUCCESS,
  LOAD_MESSAGE_ERROR,
} from './constants';

export function loadMessage() {
  return {
    type: LOAD_MESSAGE,
  };
}

export function loadMessageSuccess(messageArray) {
  return {
    type: LOAD_MESSAGE_SUCCESS,
    messageArray,
  };
}

export function loadMessageError(error) {
  return {
    type: LOAD_MESSAGE_ERROR,
    error,
  };
}
