import { createSelector } from 'reselect';
import { CampaignsPageState, initialState } from './reducer';

/**
 * Direct selector to the CampaignsPage state domain
 */

const selectCampaignsPageDomain = (state: {
  campaignsPage: CampaignsPageState;
}) => state.campaignsPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by CampaignsPage
 */

const makeSelectCampaignsPage = () =>
  createSelector(selectCampaignsPageDomain, substate => substate);

const makeSelectCampaignsPageActiveLoading = () =>
  createSelector(selectCampaignsPageDomain, substate => substate.activeLoading);

const makeSelectCampaignsPageClosedLoading = () =>
  createSelector(selectCampaignsPageDomain, substate => substate.closedLoading);

const makeSelectCampaignsPageActiveError = () =>
  createSelector(selectCampaignsPageDomain, substate => substate.activeError);

const makeSelectCampaignsPageClosedError = () =>
  createSelector(selectCampaignsPageDomain, substate => substate.closedError);

const makeSelectCampaignsPageActive = () =>
  createSelector(
    selectCampaignsPageDomain,
    substate => substate.campaignsActive,
  );

const makeSelectCampaignsPageRestricted = () =>
  createSelector(
    selectCampaignsPageDomain,
    substate => substate.campaignsRestricted,
  );

const makeSelectCampaignsPagePrivate = () =>
  createSelector(
    selectCampaignsPageDomain,
    substate => substate.campaignsPrivate,
  );

const makeSelectCampaignsPageFeatured = () =>
  createSelector(
    selectCampaignsPageDomain,
    substate => substate.campaignsFeatured,
  );

const makeSelectCampaignsPageComingSoon = () =>
  createSelector(
    selectCampaignsPageDomain,
    substate => substate.campaignsComingSoon,
  );

const makeSelectCampaignsPageClosing = () =>
  createSelector(
    selectCampaignsPageDomain,
    substate => substate.campaignsClosing,
  );

const makeSelectCampaignsPageClosed = () =>
  createSelector(
    selectCampaignsPageDomain,
    substate => substate.campaignsClosed,
  );

const makeSelectCampaignsPagePage = () =>
  createSelector(selectCampaignsPageDomain, substate => substate.page);

export default makeSelectCampaignsPage;
export {
  selectCampaignsPageDomain,
  makeSelectCampaignsPageActiveLoading,
  makeSelectCampaignsPageClosedLoading,
  makeSelectCampaignsPageActiveError,
  makeSelectCampaignsPageClosedError,
  makeSelectCampaignsPageActive,
  makeSelectCampaignsPageRestricted,
  makeSelectCampaignsPagePrivate,
  makeSelectCampaignsPageComingSoon,
  makeSelectCampaignsPageClosing,
  makeSelectCampaignsPageClosed,
  makeSelectCampaignsPagePage,
  makeSelectCampaignsPageFeatured,
};
