/**
 *
 * SocialMedia
 *
 */

import { IconButton, Link } from '@mamacrowd/ui-kit';

import Box from 'components/Box';

import FacebookIconStd from 'images/social-facebook-icon.svg';
import LinkedinIconStd from 'images/social-linkedin-icon.svg';
import InstagramIconStd from 'images/social-instagram-icon.svg';
import YoutubeIconStd from 'images/social-youtube-icon.svg';
import FacebookIconBlack from 'images/Social/facebook-icon-black.svg';
import LinkedinIconBlack from 'images/Social/linkedin-icon-black.svg';
import InstagramIconBlack from 'images/Social/instagram-icon-black.svg';
import YoutubeIconBlack from 'images/Social/youtube-icon-black.svg';

import {
  FACEBOOK_SOCIAL_URL,
  INSTAGRAM_SOCIAL_URL,
  LINKEDIN_SOCIAL_URL,
  YOUTUBE_SOCIAL_URL,
} from 'utils/constants';

import { useStyle } from './SocialMedia.style';

interface SocialMediaProps {
  variant?: 'standard' | 'black';
}

function SocialMedia({ variant = 'standard' }: SocialMediaProps) {
  const classes = useStyle();

  let facebookIcon: string;
  let instagramIcon: string;
  let linkedinIcon: string;
  let youtubeIcon: string;

  switch (variant) {
    case 'standard':
      facebookIcon = FacebookIconStd;
      instagramIcon = InstagramIconStd;
      linkedinIcon = LinkedinIconStd;
      youtubeIcon = YoutubeIconStd;
      break;
    case 'black':
      facebookIcon = FacebookIconBlack;
      instagramIcon = InstagramIconBlack;
      linkedinIcon = LinkedinIconBlack;
      youtubeIcon = YoutubeIconBlack;
      break;
  }

  return (
    <Box className={classes.socialMediaContainer}>
      <Link to={LINKEDIN_SOCIAL_URL} target="_blank" rel="noreferrer">
        <IconButton
          className={classes.socialIconContainer}
          size="large"
          icon={
            <img
              src={linkedinIcon}
              className={classes.socialIcon}
              alt="linkedin"
            />
          }
        />
      </Link>
      <Link to={INSTAGRAM_SOCIAL_URL} target="_blank" rel="noreferrer">
        <IconButton
          className={classes.socialIconContainer}
          size="large"
          icon={
            <img
              src={instagramIcon}
              className={classes.socialIcon}
              alt="instagram"
            />
          }
        />
      </Link>
      <Link to={YOUTUBE_SOCIAL_URL} target="_blank" rel="noreferrer">
        <IconButton
          className={classes.socialIconContainer}
          size="large"
          icon={
            <img
              src={youtubeIcon}
              className={classes.socialIcon}
              alt="youtube"
            />
          }
        />
      </Link>
      <Link to={FACEBOOK_SOCIAL_URL} target="_blank" rel="noreferrer">
        <IconButton
          className={classes.socialIconContainer}
          size="large"
          icon={
            <img
              src={facebookIcon}
              className={classes.socialIcon}
              alt="facebook"
            />
          }
        />
      </Link>
    </Box>
  );
}

export default SocialMedia;
