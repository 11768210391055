/**
 *
 * CampaignInput
 *
 */

import { useState } from 'react';
import { useIntl } from 'react-intl';

import Clear from '@mui/icons-material/Clear';
import { Input, IconButton } from '@mamacrowd/ui-kit';

import Box from 'components/Box';

import SearchIcon from 'images/search-icon.svg';

import { useStyle } from './CampaignInput.style';
import messages from './messages';

type CampaignInputProps = {
  query: string;
  onSearchPress: (query: string) => void;
  onCleanPress: (query: string) => void;
};

function CampaignInput({
  query,
  onCleanPress,
  onSearchPress,
}: CampaignInputProps) {
  const intl = useIntl();
  const classes = useStyle();

  const [searchQuery, setSearchQuery] = useState<string>(query);

  const cleanSearch = () => {
    setSearchQuery('');
    onCleanPress('');
  };

  const handleSearchChange = (value: string) => {
    setSearchQuery(value);
  };

  const search = e => {
    e.preventDefault();

    if (!searchQuery || (searchQuery && searchQuery.length > 2)) {
      onSearchPress(searchQuery);
    }
  };

  const clearButtonJSX = searchQuery ? (
    <IconButton
      className={classes.iconButton}
      aria-label="clear"
      onClick={() => {
        cleanSearch();
      }}
      size="large"
      data-testid="campaigns-search-clear-icon"
      color="secondary"
      icon={<Clear className={classes.iconClear} />}
    />
  ) : (
    <Box className={classes.iconClearHidden} />
  );

  return (
    <Box className={classes.inputContainer}>
      <form
        className={classes.inputForm}
        noValidate
        autoComplete="off"
        onSubmit={search}
      >
        <Input
          placeholder={intl.formatMessage(messages.inputPlaceholder)}
          className={classes.inputSearch}
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: clearButtonJSX,
            startAdornment: (
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                type="submit"
                size="large"
                icon={
                  <img
                    src={SearchIcon}
                    alt="search"
                    className={classes.iconSearch}
                  />
                }
              />
            ),
          }}
          data-testid="test-campaign-input"
        />
      </form>
    </Box>
  );
}

export default CampaignInput;
