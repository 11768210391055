/**
 *
 * Chip
 *
 */

import PropTypes from 'prop-types';

import MuiChip from '@mui/material/Chip';
import Cancel from '@mui/icons-material/Cancel';

import FormattedMessage from 'components/FormattedMessage';

import messages from './messages';
import { useStyle } from './Chip.style';

function Chip({
  label,
  color = '',
  variant = 'filled',
  showNewBadge = false,
  deleteIcon = null,
  className = '',
  radius = 50,
  icon,
  ...rest
}) {
  const classes = useStyle({ color, variant, radius });
  return (
    <MuiChip
      label={
        <>
          {label}
          {showNewBadge && (
            <div className={classes.badgeNew}>
              <FormattedMessage messages={messages} messageId="new" />
            </div>
          )}
        </>
      }
      icon={icon || null}
      variant={variant}
      deleteIcon={deleteIcon || <Cancel />}
      className={`${classes.chip} ${className}`}
      {...rest}
    />
  );
}

Chip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['filled', 'outlined']),
  color: PropTypes.string,
  deleteIcon: PropTypes.node,
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  showNewBadge: PropTypes.bool,
};

export default Chip;
