import { takeLatest, call, put } from 'redux-saga/effects';
import Logger from 'loglevel';
import { request } from 'utils/request';
import makeApiUrl from 'utils/makeApiUrl';
import { ME_CAMPAIGN_ORDERS_URL } from 'utils/constants';
import { manageSagaError } from 'utils/errorManager';

import { DONATE } from './constants';

import { donateSuccess, donateError } from './actions';

// Individual exports for testing
export function* makeDonate(payload) {
  try {
    // Call our request helper (see 'utils/request'
    const requestDonateUrl = makeApiUrl(
      ME_CAMPAIGN_ORDERS_URL.replace(':campaignId', payload.campaignId),
    );

    const options = {
      method: 'POST',
      body: JSON.stringify(payload.donation),
    };

    const response = yield call(request, requestDonateUrl, options);

    Logger.log(['SAGAS', 'DONATE', response]);

    yield put(donateSuccess(response));
  } catch (err) {
    const error = yield manageSagaError('StepDonate', err);
    yield put(donateError(error));
  }
}

// Individual exports for testing
export default function* stepDonateSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(DONATE, makeDonate);
}
