import { createTheme, Theme } from '@mui/material/styles';
import { typography, consoleTypography } from './typography';
import { components, consoleComponents } from './components';
import { palette } from './palette';

// Tema principale
const baseTheme = createTheme({
  palette,
  typography,
});

export const theme: Theme = createTheme(baseTheme, {
  components: components(baseTheme),
});

// Tema per la console
const consoleBaseTheme = createTheme({
  palette,
  typography: consoleTypography,
});

export const consoleTheme: Theme = createTheme(consoleBaseTheme, {
  components: consoleComponents(consoleBaseTheme),
});
