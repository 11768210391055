import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles({
  socialMediaContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  socialIconContainer: {
    // marginRight: 10,
    padding: 5,
  },
  socialIcon: {
    width: 32,
    height: 32,
  },
});
