/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  LOAD_LOGIN,
  LOAD_LOGIN_SUCCESS,
  LOAD_LOGIN_ERROR,
  CLEAN_LOGIN_ERROR,
  LOAD_LOGOUT,
  LOAD_LOGOUT_SUCCESS,
  LOAD_LOGOUT_ERROR,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  LOAD_CONSOLE_ACTIVE_CAMPAIGN,
  LOAD_CONSOLE_ACTIVE_CAMPAIGN_ERROR,
  SET_CONSOLE_ACTIVE_CAMPAIGN,
  SET_CONSOLE_ACTIVE_COMPANY,
  SET_CONSOLE_ACTIVE_ORDER,
  SET_CONSOLE_ACTIVE_USER,
  SET_CONSOLE_ACTIVE_PARTNER,
  SET_CONSOLE_ACTIVE_TESTIMONIAL,
  SET_CONSOLE_ACTIVE_ARTICLE,
  SET_CONSOLE_ACTIVE_MESSAGE,
  SET_CONSOLE_ACTIVE_BANNER,
  SET_CONSOLE_ACTIVE_PAGE,
  SET_CONSOLE_ACTIVE_PRESS,
  SET_CONSOLE_ACTIVE_FAQ,
  SET_CONSOLE_ACTIVE_SETTING,
  SET_CONSOLE_ACTIVE_INDUSTRY,
} from './constants';

export function loadLogin(user, token, sessionId) {
  return {
    type: LOAD_LOGIN,
    user,
    token,
    sessionId,
  };
}

export function loadLoginSuccess(user) {
  return {
    type: LOAD_LOGIN_SUCCESS,
    user,
  };
}

export function loadLoginError(error) {
  return {
    type: LOAD_LOGIN_ERROR,
    error,
  };
}

export function cleanLoginError() {
  return {
    type: CLEAN_LOGIN_ERROR,
  };
}

export function loadLogout() {
  return {
    type: LOAD_LOGOUT,
  };
}

export function loadLogoutSuccess() {
  return {
    type: LOAD_LOGOUT_SUCCESS,
  };
}

export function loadLogoutError() {
  return {
    type: LOAD_LOGOUT_ERROR,
  };
}

export function loadUser() {
  return {
    type: LOAD_USER,
  };
}

export function loadUserSuccess(user) {
  return {
    type: LOAD_USER_SUCCESS,
    user,
  };
}

export function loadUserError() {
  return {
    type: LOAD_USER_ERROR,
  };
}

export function loadConsoleActiveCampaign(campaignId) {
  return {
    type: LOAD_CONSOLE_ACTIVE_CAMPAIGN,
    campaignId,
  };
}

export function loadConsoleActiveCampaignSuccess(activeCampaign) {
  return {
    type: SET_CONSOLE_ACTIVE_CAMPAIGN,
    activeCampaign,
  };
}

export function loadConsoleActiveCampaignError(error) {
  return {
    type: LOAD_CONSOLE_ACTIVE_CAMPAIGN_ERROR,
    error,
  };
}

export function setConsoleActiveCompanyId(companyId) {
  return {
    type: SET_CONSOLE_ACTIVE_COMPANY,
    companyId,
  };
}

export function setConsoleActiveOrderId(orderId) {
  return {
    type: SET_CONSOLE_ACTIVE_ORDER,
    orderId,
  };
}

export function setConsoleActiveUserId(userId) {
  return {
    type: SET_CONSOLE_ACTIVE_USER,
    userId,
  };
}

export function setConsoleActivePartnerId(partnerId) {
  return {
    type: SET_CONSOLE_ACTIVE_PARTNER,
    partnerId,
  };
}

export function setConsoleActiveTestimonialId(testimonialId) {
  return {
    type: SET_CONSOLE_ACTIVE_TESTIMONIAL,
    testimonialId,
  };
}

export function setConsoleActiveArticleId(articleId) {
  return {
    type: SET_CONSOLE_ACTIVE_ARTICLE,
    articleId,
  };
}

export function setConsoleActiveMessageId(messageId) {
  return {
    type: SET_CONSOLE_ACTIVE_MESSAGE,
    messageId,
  };
}

export function setConsoleActiveBannerId(bannerId) {
  return {
    type: SET_CONSOLE_ACTIVE_BANNER,
    bannerId,
  };
}

export function setConsoleActivePageId(pageId) {
  return {
    type: SET_CONSOLE_ACTIVE_PAGE,
    pageId,
  };
}

export function setConsoleActivePressId(pressId) {
  return {
    type: SET_CONSOLE_ACTIVE_PRESS,
    pressId,
  };
}

export function setConsoleActiveFaqId(faqId) {
  return {
    type: SET_CONSOLE_ACTIVE_FAQ,
    faqId,
  };
}

export function setConsoleActiveSettingId(settingId) {
  return {
    type: SET_CONSOLE_ACTIVE_SETTING,
    settingId,
  };
}

export function setConsoleActiveIndustryId(industryId) {
  return {
    type: SET_CONSOLE_ACTIVE_INDUSTRY,
    industryId,
  };
}
