/**
 *
 * FooterLastSection
 *
 */

import { Typography } from '@mamacrowd/ui-kit';
import FormattedMessage from 'components/FormattedMessage';
import Box from 'components/Box';
import { SHARE_CAPITAL, PEC, VAT_NUMBER } from 'utils/constants';
import { FooterMessages } from 'utils/globalMessages';
import { useStyle } from './FooterLastSection.style';

export interface FooterLastSectionProps {
  isLanding?: boolean;
}

function FooterLastSection({ isLanding = false }: FooterLastSectionProps) {
  const classes = useStyle();

  return isLanding ? (
    <Box className={classes.containerLanding}>
      <Typography color="textPrimary" className={classes.textLanding}>
        <FormattedMessage
          messages={FooterMessages}
          messageId="pecLanding"
          values={{
            currentYear: new Date().getFullYear(),
            vatNumber: VAT_NUMBER,
          }}
        />
      </Typography>
    </Box>
  ) : (
    <Box className={classes.container}>
      <Typography
        variant="caption"
        component="p"
        color="textPrimary"
        align="justify"
        fontStyle="italic"
      >
        <FormattedMessage messages={FooterMessages} messageId="disclaimer" />
      </Typography>
      &nbsp;
      <Typography
        variant="caption"
        component="p"
        color="textPrimary"
        className={classes.textPec}
      >
        <FormattedMessage
          messages={FooterMessages}
          messageId="pec"
          values={{
            currentYear: new Date().getFullYear(),
            shareCapital: SHARE_CAPITAL,
            pec: PEC,
            vatNumber: VAT_NUMBER,
          }}
        />
      </Typography>
    </Box>
  );
}

export default FooterLastSection;
