import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  isPowerUser?: boolean;
};

export const useStyle = makeStyles<Theme, Props>(theme => ({
  userButton: ({ isPowerUser }) => ({
    paddingLeft: isPowerUser ? 17 : 8,
    '& .MuiButton-icon': {
      position: 'relative',
    },
  }),
  userButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  powerUserIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(-50%, -3px)',
    [theme.breakpoints.down('md')]: {
      top: 12,
      left: 12,
    },
  },
  powerUserChip: {
    height: 'auto',
    marginTop: -4,
    '& .MuiChip-label': {
      padding: '0 4px',
      fontSize: 9,
    },
  },
}));
