/*
 *
 * UtmProvider actions
 *
 */

import {
  SET_USER_UTM,
  SET_USER_UTM_ERROR,
  SET_USER_UTM_SUCCESS,
} from './constants';

export function setUserUtm(userUtm) {
  return {
    type: SET_USER_UTM,
    userUtm,
  };
}

export function setUserUtmSuccess() {
  return {
    type: SET_USER_UTM_SUCCESS,
  };
}

export function setUserUtmError(error) {
  return {
    type: SET_USER_UTM_ERROR,
    error,
  };
}
