/*
 *
 * CampaignsPageFilters constants
 *
 */

export const CHANGE_FILTER = 'app/CampaignsPageFilters/CHANGE_FILTER';
export const CHANGE_QUERY = 'app/CampaignsPageFilters/CHANGE_QUERY';
export const CHANGE_TYPE = 'app/CampaignsPageFilters/CHANGE_TYPE';
export const CLEAN_QUERY = 'app/CampaignsPageFilters/CLEAN_QUERY';
export const CLEAR_FILTERS_CHANGED =
  'app/CampaignsPageFilters/CLEAR_FILTERS_CHANGED';
export const CLEAN_SEARCH_FILTERS =
  'app/CampaignsPageFilters/CLEAN_SEARCH_FILTERS';
