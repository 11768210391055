import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles((theme: Theme) => ({
  news: {
    color: theme.palette.error.main,
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  newsHint: {
    marginTop: theme.spacing(2),
  },
  newsHintTitle: {
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontSize: theme.typography.h3.fontSize,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  newsHintText: {
    lineHeight: '24px',
  },
  newsBadge: {
    width: '100%',
    marginTop: 10,
    '& .MuiBadge-badge': {
      marginLeft: 50,
      backgroundColor: '#fff',
      border: '1px solid red',
      color: 'red',
    },
  },
  incomeChip: {
    borderRadius: 5,
    backgroundColor: theme.palette.realEstate.main,
    color: theme.palette.realEstate.contrastText,
    height: 22.28,
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 600,
    padding: '1px 8px 0px',
    textTransform: 'uppercase',
    lineHeight: 1.5,
  },
  guideButton: {
    width: '100%',
  },
}));
