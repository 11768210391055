import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  iconButtonContainer: props => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: props.justify,
    flexDirection: props.direction,
  }),
  iconButtonText: props => ({
    cursor: props.disabled ? 'auto' : 'pointer',
    fontSize: 12,
  }),
  outlined: props =>
    /* eslint-disable indent */
    props.variant === 'outlined'
      ? {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: 4,
        }
      : {},
  /* eslint-enable indent */
}));
