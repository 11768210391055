import { call } from 'redux-saga/effects';
import User from 'utils/User';
import makeApiUrl from 'utils/makeApiUrl';
import { request } from 'utils/request';
import {
  ME_CAMPAIGN_RESTRICTED_URL,
  USER_RESTRICTED_CAMPAIGN_PROPERTY,
} from 'utils/constants';

export const getRestrictedKeyAndIDS = campaigns =>
  campaigns
    .filter(c => c.isMultiStageCampaign && c.isRestricted)
    .map(c => ({ key: 'id', value: c.id }));

export const filterOutRelatedCampaigns = (campaigns, relatedCampaignsIDs) =>
  campaigns.filter(c => !relatedCampaignsIDs.includes(c.id));

export const hasOneRestrictedCampaign = campaigns =>
  campaigns.some(c => c.isMultiStageCampaign && c.isRestricted);

export function* getCampaignsWithRestrictedCampaign(
  campaigns,
  requestOptions = {},
) {
  let campaignsHidden = [];
  const restrictedCampaigns = [];

  const hasOneRestrictedCampaigns = hasOneRestrictedCampaign(campaigns);
  if (
    User.getUserProp(USER_RESTRICTED_CAMPAIGN_PROPERTY) &&
    hasOneRestrictedCampaigns
  ) {
    const requestRestrictedUrl = makeApiUrl(ME_CAMPAIGN_RESTRICTED_URL);
    const restrictedCampaignsIds = getRestrictedKeyAndIDS(campaigns);
    const restrictedOptions = {
      ...requestOptions,
      repeatedQSParams: restrictedCampaignsIds,
    };

    const {
      campaignsActive: restrictedCampaignsActive,
      relatedPublicCampaignIds,
    } = yield call(request, requestRestrictedUrl, restrictedOptions);

    restrictedCampaignsActive.forEach(c => {
      restrictedCampaigns.push(c);
    });

    campaignsHidden = relatedPublicCampaignIds;
  }

  const filteredCampaigns = filterOutRelatedCampaigns(
    campaigns,
    campaignsHidden,
  );

  return { filteredCampaigns, restrictedCampaigns };
}
