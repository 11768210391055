import { isValid as isValidDate } from 'date-fns';
import { getCookieSettingsFromLocalStorage } from './localStorage';

export function getAllCookies() {
  if (document?.cookie) {
    const cookies = {};
    const cookiesArray = document.cookie.split('; ');
    cookiesArray.forEach(c => {
      const [key, value] = c.split('=');
      cookies[key] = value;
    });
    return cookies;
  }
  return null;
}

export function getCookie(name) {
  const cookies = getAllCookies();
  if (name && cookies) {
    return cookies[name] || '';
  }
  return '';
}

export function setCookie(name, value, date) {
  var expiresAt = new Date(date);
  if (name && value && isValidDate(expiresAt)) {
    document.cookie = `${name}=${value}; expires=${expiresAt.toUTCString()}; path=/`;
  }
}

export function getCookieSupport() {
  const cookieItem = getCookieSettingsFromLocalStorage();
  return cookieItem ? !!cookieItem.support : false;
}

export function getCookieAnalytics() {
  const cookieItem = getCookieSettingsFromLocalStorage();
  return cookieItem ? !!cookieItem.analytics : false;
}

export function getCookieThirdParty() {
  const cookieItem = getCookieSettingsFromLocalStorage();
  return cookieItem ? !!cookieItem.thirdParty : false;
}
