/*
 * CampaignsPageEmptyState Messages
 *
 * This contains all the text for the CampaignsPageEmptyState container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CampaignsPageEmptyState';

export default defineMessages({
  supTitle: {
    id: `${scope}.sup_title.message`,
    defaultMessage:
      'At the moment there are no Startup/SME projects to invest in',
  },
  reTitle: {
    id: `${scope}.re_title.message`,
    defaultMessage:
      'At the moment there are no real estate projects to invest in',
  },
  searchTitle: {
    id: `${scope}.search_title.message`,
    defaultMessage: 'No projects matching your search',
  },
  title: {
    id: `${scope}.title.message`,
    defaultMessage: 'At the moment there are no projects to invest in',
  },
  message: {
    id: `${scope}.message.message`,
    defaultMessage: 'We are working on new offers. Stay tuned!',
  },
  searchMessage: {
    id: `${scope}.search_message.message`,
    defaultMessage: 'Please search again',
  },
});
