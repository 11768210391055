export default {
  onboarding: 'onboarding',
  comingSoon: 'coming_soon',
  published: 'published',
  closing: 'closing', // NEW STATE for closing procedures
  closedFunded: 'closed_succesfully',
  closedNotFunded: 'closed_not_ok',
  exit: 'exit',
  canceled: 'canceled',
  revoked: 'revoked',
};
