import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  TIMING_OPACITY_ANIMATION,
  TIMING_OPACITY_ANIMATION_MOBILE,
} from 'utils/constants';

type Props = {
  delay?: string;
};

export const useStyle = makeStyles<Theme, Props>({
  start: {
    // TRANSFORM
    opacity: 0,
  },
  end: props => ({
    // TRANSITION
    transition: `opacity ${TIMING_OPACITY_ANIMATION}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s`,
    '-ms-transition': `opacity ${TIMING_OPACITY_ANIMATION}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s` /* IE 9 */,
    '-webkit-transition': `opacity ${TIMING_OPACITY_ANIMATION}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s` /* Safari and Chrome */,
    '-o-transition': `opacity ${TIMING_OPACITY_ANIMATION}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s` /* Opera */,
    '-moz-transition': `opacity ${TIMING_OPACITY_ANIMATION}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s` /* Firefox */,

    '@media screen and (max-width: 600px )': {
      flexDirection: 'column',
      transition: `opacity ${TIMING_OPACITY_ANIMATION_MOBILE}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s`,
      '-ms-transition': `opacity ${TIMING_OPACITY_ANIMATION_MOBILE}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s` /* IE 9 */,
      '-webkit-transition': `opacity ${TIMING_OPACITY_ANIMATION_MOBILE}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s` /* Safari and Chrome */,
      '-o-transition': `opacity ${TIMING_OPACITY_ANIMATION_MOBILE}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s` /* Opera */,
      '-moz-transition': `opacity ${TIMING_OPACITY_ANIMATION_MOBILE}s cubic-bezier(0.55, 0.06, 0.68, 0.19) 0s` /* Firefox */,
    },

    opacity: 1,
    transitionDelay: props.delay || '0s',
    '-ms-transition-delay': props.delay || '0s' /* IE 9 */,
    '-webkit-transition-delay': props.delay || '0s' /* Safari and Chrome */,
    '-o-transition-delay': props.delay || '0s' /* Opera */,
    '-moz-transition-delay': props.delay || '0s' /* Firefox */,
  }),
  animatedContainer: {
    display: 'inherit',
    width: '100%',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    height: '100%',
  },
});
