import checkFiscalCode from './isFiscalCodeValid';
import checkYourFiscalCode from './isYourFiscalCode';
import checkVatNumber, {
  isEuropeanCountry as checkEuropeanCountry,
} from './isVatNumberValid';
import checkIbanValid from './isIBANValid';
import checkPasswordValid from './isPasswordValid';
import checkLengthBetween from './isLengthBetween';
import checkLengthExact from './isLengthExact';
import checkLengthMin from './isLengthMin';
import checkLengthMax from './isLengthMax';
import checkAdultDate from './isAdultDate';
import checkIsNumber from './isNumber';
import checkPhoneNumber from './isPhoneNumberValid';
import checkSystemTitle from './isSystemTitleValid';
import checkMailValid from './isMailValid';
import checkEntityTypeValid from './isEntityTypeValid';
import isUuid from './isUuid';
import isUrl from './isUrl';
import isReaCode from './isREACodeValid';
import isLinkedinUrl from './isLinkedinUrl';
import checkHexColor from './isHexColor';

export const isFiscalCodeValid = checkFiscalCode;
export const isYourFiscalCode = checkYourFiscalCode;
export const isVatNumberValid = checkVatNumber;
export const isUECountry = checkEuropeanCountry;
export const isIBANValid = checkIbanValid;
export const isPasswordValid = checkPasswordValid;
export const isLengthBetween = checkLengthBetween;
export const isLengthExact = checkLengthExact;
export const isLengthMin = checkLengthMin;
export const isAdultDate = checkAdultDate;
export const isNumber = checkIsNumber;
export const isPhoneNumberValid = checkPhoneNumber;
export const isSystemTitleValid = checkSystemTitle;
export const isMailValid = checkMailValid;
export const isEntityTypeValid = checkEntityTypeValid;
export const isUuidValid = isUuid;
export const isUrlValid = isUrl;
export const isReaCodeValid = isReaCode;
export const isLinkedinUrlValid = isLinkedinUrl;
export const isLengthMax = checkLengthMax;
export const isHexColor = checkHexColor;
