import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles((theme: Theme) => ({
  hint: {
    position: 'relative',
    fontSize: 14,
    fontWeight: 400,
    padding: theme.spacing(2, 3),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1, 2),
    },
  },
  hintTitle: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(3),
    borderRadius: 22,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    padding: '4px 13px',
    transform: 'translateY(-50%)',
    color: theme.palette.error.main,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.error.main}`,
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      padding: '0 10px',
      left: theme.spacing(2),
    },
  },
}));
