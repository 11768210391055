/**
 *
 * StepDonate
 *
 */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Grid, InputAdornment } from '@mui/material';
import { Typography } from '@mamacrowd/ui-kit';
import { useIntl } from 'react-intl';

import { goToPrevStep } from 'containers/DonatePage/actions';
import { makeSelectStepBasicProfileBasicProfile } from 'containers/StepBasicProfile/selectors';

import Input from 'components/Input';
import Checkbox from 'basic/Checkbox';
import Button from 'basic/Button';
import FormattedMessage from 'components/FormattedMessage';

import ENUMInvestorType from 'utils/Enums/InvestorType';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { trackDonationAmount } from 'utils/trackers';
import { DONATIONS_TERMS_AND_CONDITION_ROUTE } from 'utils/constants';
import {
  makeSelectStepDonateLoading,
  makeSelectStepDonateError,
} from './selectors';
import { donate, cleanDonation } from './actions';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';

export function StepDonate({
  campaign,
  goToPrevStepData,
  cleanDonationData,
  donateData,
  loading,
  error,
  basicProfile,
}) {
  useInjectReducer({ key: 'stepDonate', reducer });
  useInjectSaga({ key: 'stepDonate', saga });

  const intl = useIntl();

  useEffect(
    () => () => {
      cleanDonationData();
    },
    [],
  );

  const [orderImport, setOrderImport] = useState('');
  const [anonymousInvestor, setAnonymousInvestor] = useState(false);
  const [checkExecute, setCheckExecute] = useState(false);

  const [errorImport, setErrorImport] = useState(null);
  const [showError, setShowError] = useState(null);

  const validateImport = value => {
    const isValid = !Number.isNaN(parseFloat(value, 10)) && value > 0;

    if (!isValid) {
      setErrorImport(
        <FormattedMessage messages={messages} messageId="importErrorHelper" />,
      );
    }
    return isValid;
  };

  const handleDonationClick = e => {
    if (e) {
      e.preventDefault();
    }

    setShowError(false);

    const newDonationOrder = {
      userType:
        basicProfile.vatNumber && basicProfile.businessName
          ? ENUMInvestorType.business
          : ENUMInvestorType.user,
      userProfile: null,
      businessProfile: null,
      mifid: null,
      basicProfile: { id: basicProfile.id },
      amount: parseFloat(orderImport, 10),
      isAnonymous: anonymousInvestor,
      declareProfessional: false,
      checkExecute,
      checkExpense: false,
      checkInfoMifid: false,
      checkIndemnifies: false,
    };

    const validAmount = validateImport(newDonationOrder.amount);
    if (validAmount) {
      trackDonationAmount(
        campaign,
        newDonationOrder.amount,
        newDonationOrder.userType,
      );
      donateData(campaign.id, newDonationOrder);
    } else {
      setShowError(true);
    }
  };

  const handleBackClick = () => {
    goToPrevStepData();
  };

  return (
    <form onSubmit={handleDonationClick} style={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Input
                id="import"
                name="import"
                type="number"
                label={
                  <FormattedMessage messages={messages} messageId="import" />
                }
                variant="standard"
                value={orderImport}
                error={errorImport}
                cleanErrorOnChange={setErrorImport}
                validate={validateImport}
                onBlur={setOrderImport}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            required
            label={
              <FormattedMessage
                messages={messages}
                messageId="acceptDonation"
                values={{
                  donationLink: (
                    <a
                      href={DONATIONS_TERMS_AND_CONDITION_ROUTE}
                      target="_blank"
                    >
                      {intl.formatMessage(messages.acceptDonationTextLink)}
                    </a>
                  ),
                }}
                isHtml
              />
            }
            checked={checkExecute}
            value={checkExecute ? 'true' : 'false'}
            onChange={setCheckExecute}
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            label={
              <FormattedMessage
                messages={messages}
                messageId="anonymousInvestor"
              />
            }
            checked={anonymousInvestor}
            value={anonymousInvestor ? 'true' : 'false'}
            onChange={setAnonymousInvestor}
          />
        </Grid>
        {showError && (
          <Grid item xs={12}>
            <Typography color="error">
              <FormattedMessage
                messages={messages}
                messageId="validationError"
              />
            </Typography>
          </Grid>
        )}
        {error && (
          <Grid item xs={12}>
            <Typography color="error">
              <FormattedMessage messages={messages} messageId="donateError" />
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="secondary"
                text={
                  <FormattedMessage messages={messages} messageId="goBack" />
                }
                onClick={handleBackClick}
              />
            </Grid>
            <Grid item>
              <Button
                text={
                  <FormattedMessage messages={messages} messageId="donate" />
                }
                type="submit"
                disabled={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

StepDonate.propTypes = {
  campaign: PropTypes.object.isRequired,
  goToPrevStepData: PropTypes.func.isRequired,
  donateData: PropTypes.func.isRequired,
  cleanDonationData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  basicProfile: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectStepDonateLoading(),
  error: makeSelectStepDonateError(),
  basicProfile: makeSelectStepBasicProfileBasicProfile(),
});

function mapDispatchToProps(dispatch) {
  return {
    goToPrevStepData: () => {
      dispatch(goToPrevStep());
    },
    donateData: (campaignId, donation) => {
      dispatch(donate(campaignId, donation));
    },
    cleanDonationData: () => {
      dispatch(cleanDonation());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(StepDonate);
