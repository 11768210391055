/*
 * ConsoleBreadcrumbs Messages
 *
 * This contains all the text for the ConsoleBreadcrumbs component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.console.components.ConsoleBreadcrumbs';

export default defineMessages({
  console: {
    id: `${scope}.console.message`,
    defaultMessage: 'Console',
  },
  new: {
    id: `${scope}.new.message`,
    defaultMessage: 'new',
  },
  annex3: {
    id: `${scope}.annex_3.message`,
    defaultMessage: 'Annex 3',
  },
  investorsListDraft: {
    id: `${scope}.investors_list_draft.message`,
    defaultMessage: 'Shereholders List (Draft)',
  },
  hubspot: {
    id: `${scope}.hubspot.message`,
    defaultMessage: 'Hubspot',
  },
});
