import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles((theme: Theme) => ({
  inputContainer: {
    width: '100%',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  inputForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    flexDirection: 'row',
  },
  inputSearch: {
    width: '100%',
    '&:hover:before': {
      borderBottom: `1px solid ${theme.palette.primary.main} !important`, // MMC2: !Important used
    },
    fontSize: 16,
    letterSpacing: '0.31px',
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
  iconSearch: {
    width: 21,
    height: 21,
  },
  iconClear: {
    width: 21,
    height: 21,
  },
  iconClearHidden: {
    padding: 16,
  },
  iconButton: {
    padding: 5,
    width: 31,
    height: 31,
  },
}));
