/**
 *
 * HeaderLink
 *
 */

import { ReactNode } from 'react';

import DefaultLink from './DefaultLink';
import PrimaryLink from './PrimaryLink';
import SecondaryButtonLink from './SecondaryButtonLink';

export interface HeaderLinkProps {
  item: {
    type: 'link' | 'button';
    variant: 'default' | 'primary' | 'secondary';
    translationId: string;
    link: string;
    icon?: string;
    hidden?: boolean;
  };
  messages: object;
}

function HeaderLink({ item, messages }) {
  let itemLink: ReactNode | null = null;

  if (!item.hidden) {
    if (item.type === 'link') {
      if (item.variant === 'default') {
        itemLink = <DefaultLink item={item} messages={messages} />;
      } else if (item.variant === 'primary') {
        itemLink = <PrimaryLink item={item} messages={messages} />;
      }
    } else if (item.type === 'button') {
      if (item.variant === 'secondary') {
        itemLink = <SecondaryButtonLink item={item} messages={messages} />;
      }
    }
  }

  return itemLink;
}

export default HeaderLink;
