import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollRestoration() {
  const pathname = useLocation();
  useEffect(() => {
    if (!(pathname.hash || pathname.search)) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}

export default ScrollRestoration;
