/*
 * CheckValidToken Messages
 *
 * This contains all the text for the CheckValidToken component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.CheckValidToken';

export default defineMessages({
  sessionExpired: {
    id: `${scope}.session_expired.message`,
    defaultMessage: 'Session expired',
  },
  makeLogin: {
    id: `${scope}.make_login.message`,
    defaultMessage: 'Please Login',
  },
  login: {
    id: `${scope}.login.message`,
    defaultMessage: 'Login',
  },
});
