import makeStyles from '@mui/styles/makeStyles';
import { MAX_WIDTH_PAGE } from 'utils/constants';

export const useStyle = makeStyles({
  footerContainer: {
    borderTop: '0.5px solid #eaeaea',
    padding: 20,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: MAX_WIDTH_PAGE,
    textAlign: 'center',
  },
  footerText: {
    fontSize: 10,
  },
});
