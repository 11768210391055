import Logger from 'loglevel';
// import { trackError } from 'utils/trackers';

// USE ONLY IN SAGA
export function* manageSagaError(component, error) {
  const parsedError =
    error && error.response && error.response.json
      ? yield error.response.json()
      : error;

  const errorToPropagate = parsedError?.error || parsedError;
  // trackError(error);

  Logger.error([
    component,
    `${error?.method} ${component} ${errorToPropagate?.status} ERROR`,
    error?.toString(),
  ]);

  return errorToPropagate;
}
