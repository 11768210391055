import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  intercomFacade: {
    width: 48,
    height: 48,
    bottom: 20,
    right: 20,
    zIndex: 101,
    position: 'fixed',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    transition: '.4s',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));
