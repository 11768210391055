import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  showLanguageText: boolean;
  textColor: string;
  color: string;
};

export const useStyle = makeStyles<Theme, Props>({
  languageText: props => ({
    fontSize: 14,
    textTransform: 'capitalize',
    color: props.textColor,
  }),
  languageButton: { minHeight: 50 },
  startIcon: props => ({ marginRight: props.showLanguageText ? 8 : 0 }),
  endIcon: props => ({ marginLeft: props.showLanguageText ? 8 : 0 }),
  iconColor: props => ({ color: props.color }),
});
