/*
 *
 * CampaignsPage actions
 *
 */

import { Campaign, CampaignType } from 'types/Campaign';
import { FEParsedError } from 'types/Error';
import {
  LOAD_ACTIVE_CAMPAIGNS,
  LOAD_ACTIVE_CAMPAIGNS_ERROR,
  LOAD_ACTIVE_CAMPAIGNS_SUCCESS,
  LOAD_CLOSED_CAMPAIGNS,
  LOAD_CLOSED_CAMPAIGNS_ERROR,
  LOAD_CLOSED_CAMPAIGNS_SUCCESS,
  FILTER_ACTIVE_CAMPAIGNS,
  FILTER_CLOSED_CAMPAIGNS,
  CLEAR_ACTIVE_CAMPAIGNS,
  CLEAR_CLOSED_CAMPAIGNS,
  CLEAR_CAMPAIGNS,
} from './constants';

export function loadActiveCampaigns(
  filter: string,
  query: string,
  campaignTypes: CampaignType[],
) {
  return {
    type: LOAD_ACTIVE_CAMPAIGNS,
    filter,
    query,
    campaignTypes,
  };
}

export function loadActiveCampaignsSuccess(
  campaignsPrivate: Campaign[],
  campaignsRestricted: Campaign[],
  campaignsActive: Campaign[],
  campaignsComingSoon: Campaign[],
  campaignsFeatured: Campaign[],
  campaignsClosing: Campaign[],
) {
  return {
    type: LOAD_ACTIVE_CAMPAIGNS_SUCCESS,
    campaignsPrivate,
    campaignsRestricted,
    campaignsActive,
    campaignsComingSoon,
    campaignsFeatured,
    campaignsClosing,
  };
}

export function loadActiveCampaignsError(error: FEParsedError) {
  return {
    type: LOAD_ACTIVE_CAMPAIGNS_ERROR,
    error,
  };
}

export function loadClosedCampaigns(
  page: number,
  filter: string,
  query: string,
  campaignTypes: CampaignType[],
  limit?: number,
) {
  return {
    type: LOAD_CLOSED_CAMPAIGNS,
    page,
    filter,
    query,
    campaignTypes,
    limit,
  };
}

export function loadClosedCampaignsSuccess(campaignsClosed: {
  total: number;
  results: Campaign[];
}) {
  return {
    type: LOAD_CLOSED_CAMPAIGNS_SUCCESS,
    campaignsClosed,
  };
}

export function loadClosedCampaignsError(error: FEParsedError) {
  return {
    type: LOAD_CLOSED_CAMPAIGNS_ERROR,
    error,
  };
}

export function filterActiveCampaigns(
  filter: string,
  query: string,
  campaignTypes: CampaignType[],
) {
  return {
    type: FILTER_ACTIVE_CAMPAIGNS,
    filter,
    query,
    campaignTypes,
  };
}

export function filterClosedCampaigns(
  page: number,
  filter: string,
  query: string,
  campaignTypes: CampaignType[],
) {
  return {
    type: FILTER_CLOSED_CAMPAIGNS,
    page,
    filter,
    query,
    campaignTypes,
  };
}

export function clearActiveCampaigns() {
  return {
    type: CLEAR_ACTIVE_CAMPAIGNS,
  };
}

export function clearClosedCampaigns() {
  return {
    type: CLEAR_CLOSED_CAMPAIGNS,
  };
}

export function clearCampaigns() {
  return {
    type: CLEAR_CAMPAIGNS,
  };
}
