/*
 *
 * DonateSuccessPage actions
 *
 */

import {
  LOAD_DONATION_ORDER,
  LOAD_DONATION_ORDER_SUCCESS,
  LOAD_DONATION_ORDER_ERROR,
  CLEAN_DONATION,
} from './constants';

export function loadDonationOrder(donationId) {
  return {
    type: LOAD_DONATION_ORDER,
    donationId,
  };
}

export function loadDonationOrderSuccess(donation) {
  return {
    type: LOAD_DONATION_ORDER_SUCCESS,
    donation,
  };
}

export function loadDonationOrderError(error) {
  return {
    type: LOAD_DONATION_ORDER_ERROR,
    error,
  };
}

export function cleanDonation() {
  return {
    type: CLEAN_DONATION,
  };
}
