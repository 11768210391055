/**
 *
 * CookiesBanner
 *
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import isEqual from 'lodash/isEqual';
import { injectIntl } from 'react-intl';

import { Grid, FormGroup, FormControlLabel, Drawer } from '@mui/material';
import { Typography } from '@mamacrowd/ui-kit';
import { CancelOutlined } from '@mui/icons-material';

import Link from 'basic/Link';
import Button from 'basic/Button';
import Checkbox from 'basic/Checkbox';
import IconButton from 'basic/IconButton';
import Box from 'components/Box';
import FormattedMessage from 'components/FormattedMessage';

import User from 'utils/User';
import { toggleConsent } from 'utils/trackers';
import { localStorageSupported } from 'utils/localStorage';
import { COOKIES_ROUTE } from 'utils/constants';
import {
  getCookieSettingsFromLocalStorage,
  setCookieSettingsToLocalStorage,
} from 'utils/localStorage';

import { useStyle } from './CookiesBanner.style';
import {
  makeSelectCookiesBannerError,
  makeSelectCookiesBannerLoading,
  makeSelectCookiesBannerShowCookiesBanner,
} from './selectors';
import {
  closeCookieSettings,
  openCookieSettings,
  setCookieSettings,
} from './actions';
import saga from './saga';
import reducer from './reducer';
import messages from './messages';

export function CookiesBanner({
  showCookiesBanner,
  setCookieSettingsData,
  openCookieSettingsData,
  closeCookieSettingsData,
}) {
  useInjectReducer({ key: 'cookiesBanner', reducer });
  useInjectSaga({ key: 'cookiesBanner', saga });
  const classes = useStyle({ notSupported: !localStorageSupported });

  const [showCookieSettings, setShowCookieSettings] = useState(false);
  const [cookieSupport, setCookieSupport] = useState(false);
  const [cookieAnalytics, setCookieAnalytics] = useState(false);
  const [cookieThirdParty, setCookieThirdParty] = useState(false);

  // functional: Cookie funzionali e navigazione
  // support: Cookie di navigazione utili per il supporto
  // analytics: Cookie di profilazione
  // thirdParty: Cookie di terze parti

  useEffect(() => {
    let cookiesDB = null;
    const cookiesLS = getCookieSettingsFromLocalStorage();
    if (User.isLogged()) {
      const details = User.getUserProp('details');
      if (details?.cookieChoices) {
        cookiesDB = {
          functional: details.cookieChoices.functional,
          support: details.cookieChoices.support,
          analytics: details.cookieChoices.analytics,
          thirdParty:
            // TODO: 2024-01-22 to be removed in the near future
            typeof details.cookieChoices.thirdParts !== 'undefined'
              ? details.cookieChoices.thirdParts
              : details.cookieChoices.thirdParty,
        };
      }
    }
    if (!cookiesLS && !cookiesDB) {
      openCookieSettingsData();
    } else if (!cookiesLS && cookiesDB) {
      handleSubmit(cookiesDB);
    } else if (cookiesLS && cookiesDB && !isEqual(cookiesLS, cookiesDB)) {
      handleSubmit(cookiesLS);
    }
  }, []);

  useEffect(() => {
    if (showCookiesBanner) {
      const cookiesLS = getCookieSettingsFromLocalStorage();
      setCookieSupport(cookiesLS?.support || false);
      setCookieAnalytics(cookiesLS?.analytics || false);
      setCookieThirdParty(cookiesLS?.thirdParty || false);
    } else {
      setShowCookieSettings(false);
    }
  }, [showCookiesBanner]);

  const handleSubmit = cookieSetting => {
    if (localStorageSupported) {
      setCookieSettingsToLocalStorage(cookieSetting);
      toggleConsent(cookieSetting.analytics);
      if (User.isLogged()) {
        setCookieSettingsData(cookieSetting);
      } else {
        closeCookieSettingsData();
      }
    }
  };

  const acceptAllCookies = () => {
    const cookieSetting = {
      functional: true,
      support: true,
      analytics: true,
      thirdParty: true,
    };
    handleSubmit(cookieSetting);
  };

  const acceptFunctionalCookies = () => {
    const cookieSetting = {
      functional: true,
      support: false,
      analytics: false,
      thirdParty: false,
    };
    handleSubmit(cookieSetting);
  };

  const acceptSelectedCookies = () => {
    const cookieSetting = {
      functional: true,
      support: cookieSupport,
      analytics: cookieAnalytics,
      thirdParty: cookieThirdParty,
    };
    handleSubmit(cookieSetting);
  };

  const hadleCloseCookiesBanner = () => {
    let cookiesDB = null;
    const cookiesLS = getCookieSettingsFromLocalStorage();
    if (User.isLogged()) {
      const details = User.getUserProp('details');
      cookiesDB = details?.cookieChoices || null;
    }
    if (cookiesLS || cookiesDB) {
      closeCookieSettingsData();
    } else {
      acceptFunctionalCookies();
    }
  };

  return (
    <Drawer anchor="bottom" open={showCookiesBanner || !localStorageSupported}>
      <Box className={classes.cookiesBannerContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  className={classes.cookiesBannerTitle}
                >
                  {localStorageSupported ? (
                    <FormattedMessage
                      messages={messages}
                      messageId={
                        showCookieSettings ? 'cookieSettingsTitle' : 'title'
                      }
                    />
                  ) : (
                    <FormattedMessage
                      messages={messages}
                      messageId="localStorageUnsupportedTitle"
                    />
                  )}
                </Typography>
              </Grid>
              {localStorageSupported && (
                <Grid item>
                  <IconButton
                    icon={<CancelOutlined color="action" />}
                    onClick={hadleCloseCookiesBanner}
                    size="small"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              className={classes.cookiesBannerText}
            >
              {localStorageSupported ? (
                <>
                  <FormattedMessage
                    messages={messages}
                    messageId={
                      showCookieSettings
                        ? 'cookieSettingsDescription'
                        : 'description'
                    }
                  />
                  {!showCookieSettings && (
                    <Link to={COOKIES_ROUTE} target="_blank">
                      <Typography
                        component="span"
                        className={classes.cookiesBannerText}
                      >
                        <FormattedMessage
                          messages={messages}
                          messageId="cookiesPolicy"
                        />
                      </Typography>
                    </Link>
                  )}
                </>
              ) : (
                <FormattedMessage
                  messages={messages}
                  messageId="localStorageUnsupportedDescription"
                />
              )}
            </Typography>
          </Grid>
          {localStorageSupported && (
            <Grid item xs={12}>
              {showCookieSettings ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        disableTypography
                        style={{ marginLeft: 0 }}
                        control={
                          <Checkbox checked name="Functional" color="default" />
                        }
                        label={
                          <Typography
                            component="span"
                            className={classes.cookiesBannerText}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="cookieFunctional"
                            />
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        disableTypography
                        style={{ marginLeft: 0 }}
                        control={
                          <Checkbox
                            checked={cookieSupport}
                            onChange={setCookieSupport}
                            name="Support"
                          />
                        }
                        label={
                          <Typography
                            component="span"
                            className={classes.cookiesBannerText}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="cookieSupport"
                            />
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        disableTypography
                        style={{ marginLeft: 0 }}
                        control={
                          <Checkbox
                            checked={cookieAnalytics}
                            onChange={setCookieAnalytics}
                            name="Analytics"
                          />
                        }
                        label={
                          <Typography
                            component="span"
                            className={classes.cookiesBannerText}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="cookieAnalytics"
                            />
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        disableTypography
                        style={{ marginLeft: 0 }}
                        control={
                          <Checkbox
                            checked={cookieThirdParty}
                            onChange={setCookieThirdParty}
                            name="Third Party"
                          />
                        }
                        label={
                          <Typography
                            component="span"
                            className={classes.cookiesBannerText}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="cookieThirdParty"
                            />
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      className={classes.revertOnDesk}
                    >
                      <Grid item xs={12} sm="auto">
                        <Grid
                          container
                          spacing={2}
                          justifyContent="space-between"
                          alignItems="center"
                          wrap="nowrap"
                        >
                          <Grid item xs={6} sm="auto">
                            <Button
                              variant="secondary"
                              text={
                                <FormattedMessage
                                  messages={messages}
                                  messageId="save"
                                />
                              }
                              onClick={acceptSelectedCookies}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6} sm="auto">
                            <Button
                              text={
                                <FormattedMessage
                                  messages={messages}
                                  messageId="accept"
                                />
                              }
                              onClick={acceptAllCookies}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm="auto" style={{ flexGrow: 1 }}>
                        <Link callback={() => setShowCookieSettings(false)}>
                          <Typography
                            component="span"
                            className={classes.cookiesBannerLink}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="back"
                            />
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  className={classes.revertOnDesk}
                >
                  <Grid item xs={12} sm="auto">
                    <Button
                      text={
                        <FormattedMessage
                          messages={messages}
                          messageId="accept"
                        />
                      }
                      onClick={acceptAllCookies}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm="auto" style={{ flexGrow: 1 }}>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item>
                        <Link callback={() => setShowCookieSettings(true)}>
                          <Typography
                            component="span"
                            className={classes.cookiesBannerLink}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="showCookies"
                            />
                          </Typography>
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link callback={acceptFunctionalCookies}>
                          <Typography
                            component="span"
                            className={classes.cookiesBannerLink}
                          >
                            <FormattedMessage
                              messages={messages}
                              messageId="acceptFunctional"
                            />
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Drawer>
  );
}

CookiesBanner.propTypes = {
  showCookiesBanner: PropTypes.bool.isRequired,
  setCookieSettingsData: PropTypes.func.isRequired,
  openCookieSettingsData: PropTypes.func.isRequired,
  closeCookieSettingsData: PropTypes.func.isRequired,
  intl: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectCookiesBannerLoading(),
  error: makeSelectCookiesBannerError(),
  showCookiesBanner: makeSelectCookiesBannerShowCookiesBanner(),
});

function mapDispatchToProps(dispatch) {
  return {
    setCookieSettingsData: cookieSettings => {
      dispatch(setCookieSettings(cookieSettings));
    },
    openCookieSettingsData: () => {
      dispatch(openCookieSettings());
    },
    closeCookieSettingsData: () => {
      dispatch(closeCookieSettings());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, injectIntl)(CookiesBanner);
