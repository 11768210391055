/*
 *
 * Header constants
 *
 */

export const LOAD_ADDITIONAL_HEADER = 'app/Header/LOAD_ADDITIONAL_HEADER';
export const LOAD_ADDITIONAL_HEADER_SUCCESS =
  'app/Header/LOAD_ADDITIONAL_HEADER_SUCCESS';
export const LOAD_ADDITIONAL_HEADER_ERROR =
  'app/Header/LOAD_ADDITIONAL_HEADER_ERROR';
