/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import { Children } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { IntlProvider } from 'react-intl';
import { useInjectReducer } from 'redux-injectors';
import reducer from './reducer';

import { makeSelectLocale } from './selectors';

export function LanguageProvider(props) {
  useInjectReducer({ key: 'language', reducer });

  return (
    <IntlProvider
      locale={props?.locale || 'it'}
      key={props?.locale || 'it'}
      messages={props.messages[props?.locale || 'it']}
      textComponent={React.Fragment}
    >
      {Children.only(props.children)}
    </IntlProvider>
  );
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
});

export default connect(mapStateToProps)(LanguageProvider);
