import { takeLatest, call, put } from 'redux-saga/effects';
import Logger from 'loglevel';
import { request } from 'utils/request';
import makeApiUrl from 'utils/makeApiUrl';
import { CAMPAIGN_QUOTA_URL } from 'utils/constants';
import { manageSagaError } from 'utils/errorManager';

import { LOAD_CAMPAIGN_INFO } from './constants';

import { loadCampaignInfoSuccess, loadCampaignInfoError } from './actions';

// Individual exports for testing
export function* makeDonateCampaignPage(payload) {
  try {
    // Call our request helper (see 'utils/request'
    const requestCampaignUrl = makeApiUrl(
      `${CAMPAIGN_QUOTA_URL.replace(
        ':systemTitle',
        payload.systemTitle.toLowerCase(),
      )}`,
    );

    const { campaign } = yield call(request, requestCampaignUrl);

    Logger.log(['SAGAS', 'DONATE', payload.id, campaign]);

    yield put(loadCampaignInfoSuccess(campaign));
  } catch (err) {
    const error = yield manageSagaError('DonatePage', err);
    yield put(loadCampaignInfoError(error));
  }
}
// Individual exports for testing
export default function* donatePageSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(LOAD_CAMPAIGN_INFO, makeDonateCampaignPage);
}
