// import { useEffect } from 'react';

const intercomScript = (appId, callback) => {
  // useEffect(() => {
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = (...args) => {
      i.c(args);
    };
    i.q = [];
    i.c = args => {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = () => {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${appId}`;
      s.crossOrigin = 'anonymous';
      s.defer = true;
      const x = d.getElementsByTagName('script')[0];
      try {
        x.parentNode.insertBefore(s, x);
      } catch (e) {
        console.error(`InserBefore Intercom - ${e.message}`);
      }
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
  if (callback && typeof callback === 'function') {
    callback();
  }
  // }, [appId]);
};

export const setIntercomUserData = (
  User,
  isCookiesBannerActived,
  userLogged,
) => {
  if (window.Intercom?.booted) {
    const options = {
      hide_default_launcher: isCookiesBannerActived,
    };
    if (userLogged) {
      const firstName = User.getUserProp('firstName') || '';
      const lastName = User.getUserProp('lastName') || '';

      options.name = `${firstName} ${lastName}`.trim();
      options.email = User.getUserProp('email');
      options.user_id = User.getUserProp('id');
      options.user_hash = User.getUserHash();
    }
    window.Intercom('update', options);
  }
};

export const startIntercom = (
  evt,
  appId,
  onShowCallback,
  User,
  isCookiesBannerActived,
  userLogged,
  isClicked = false,
) => {
  const options = {
    app_id: appId,
    alignment: 'right',
    hide_default_launcher: false,
  };
  if (window?.Intercom?.booted) {
    setIntercomUserData(User, isCookiesBannerActived, userLogged);
    window.Intercom('boot', options);
    window.Intercom('onShow', onShowCallback);
    if (isClicked) window.Intercom(evt);
  } else {
    intercomScript(appId, () => {
      window.Intercom('onShow', onShowCallback);
      window.Intercom('boot', options);
      if (isClicked) window.Intercom(evt);
    });
  }
};

export default intercomScript;
