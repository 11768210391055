/*
 *
 * DonatePage actions
 *
 */

import {
  LOAD_CAMPAIGN_INFO,
  LOAD_CAMPAIGN_INFO_SUCCESS,
  LOAD_CAMPAIGN_INFO_ERROR,
  GO_TO_NEXT_STEP,
  GO_TO_PREV_STEP,
  SET_ACTIVE_STEP,
  RESET_STEPS_STATE,
} from './constants';

export function loadCampaignInfo(systemTitle) {
  return {
    type: LOAD_CAMPAIGN_INFO,
    systemTitle,
  };
}

export function loadCampaignInfoError(error) {
  return {
    type: LOAD_CAMPAIGN_INFO_ERROR,
    error,
  };
}

export function loadCampaignInfoSuccess(campaign) {
  return {
    type: LOAD_CAMPAIGN_INFO_SUCCESS,
    campaign,
  };
}

export function goToNextStep() {
  return {
    type: GO_TO_NEXT_STEP,
  };
}

export function goToPrevStep() {
  return {
    type: GO_TO_PREV_STEP,
  };
}

export function setActiveStep(step) {
  return {
    type: SET_ACTIVE_STEP,
    step,
  };
}

export function resetStepsState() {
  return {
    type: RESET_STEPS_STATE,
  };
}
