/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */

// MMC-translations

const enPrivateTranslationMessages = require('./translations/en_private.json');
const enPublicTranslationMessages = require('./translations/en_public.json');
const enGlobalTranslationMessages = require('./translations/en_global.json');
const enTranslationMessages = {
  ...enGlobalTranslationMessages,
  ...enPrivateTranslationMessages,
  ...enPublicTranslationMessages,
};
const itPrivateTranslationMessages = require('./translations/it_private.json');
const itPublicTranslationMessages = require('./translations/it_public.json');
const itGlobalTranslationMessages = require('./translations/it_global.json');
const itTranslationMessages = {
  ...itGlobalTranslationMessages,
  ...itPrivateTranslationMessages,
  ...itPublicTranslationMessages,
};

let DEFAULT_LOCALE = 'it';
if (process.env.ENABLE_MULTILANGUAGE === 'true') {
  const sliced = navigator.language.slice(0, 2);
  if (sliced === 'it' || sliced === 'en') {
    DEFAULT_LOCALE = sliced;
  } else {
    DEFAULT_LOCALE = 'en';
  }
} else {
  DEFAULT_LOCALE = 'it';
}

// prettier-ignore
const appLocales = [
  'it',
  'en',
];

const formatTranslationMessages = (locale, messages) => {
  /* enTranslationMessages for default when en is enabled */
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, itTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  it: formatTranslationMessages('it', itTranslationMessages),
};

exports.appLocales = appLocales;
exports.formatTranslationMessages = formatTranslationMessages;
exports.translationMessages = translationMessages;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
