import { takeLatest, call, put } from 'redux-saga/effects';
import Logger from 'loglevel';
import { request } from 'utils/request';
import makeApiUrl from 'utils/makeApiUrl';
import { ME_USER_UTM_URL } from 'utils/constants';
import { manageSagaError } from 'utils/errorManager';

import { SET_USER_UTM } from './constants';
import { setUserUtmSuccess, setUserUtmError } from './actions';

export function* saveUserUtm({ userUtm }) {
  try {
    const requestUrl = makeApiUrl(ME_USER_UTM_URL);
    const options = {
      method: 'POST',
      body: JSON.stringify({ userUtm }),
    };
    yield call(request, requestUrl, options);
    Logger.log(['SAGAS', 'USER UTM', 'SUCCESS', userUtm]);
    yield put(setUserUtmSuccess());
  } catch (err) {
    const error = yield manageSagaError('UtmProvider', err);
    yield put(setUserUtmError(error));
  }
}

export default function* utmProviderSaga() {
  yield takeLatest(SET_USER_UTM, saveUserUtm);
}
