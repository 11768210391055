import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the donatePage state domain
 */

const selectDonatePageDomain = state => state.donatePage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by DonatePage
 */

const makeSelectDonatePage = () =>
  createSelector(selectDonatePageDomain, substate => substate);
const makeSelectDonatePageCampaign = () =>
  createSelector(selectDonatePageDomain, substate => substate.campaign);
const makeSelectDonatePageError = () =>
  createSelector(selectDonatePageDomain, substate => substate.error);
const makeSelectDonatePageLoading = () =>
  createSelector(selectDonatePageDomain, substate => substate.loading);
const makeSelectDonatePageActiveStep = () =>
  createSelector(selectDonatePageDomain, substate => substate.activeStep);

export default makeSelectDonatePage;
export {
  selectDonatePageDomain,
  makeSelectDonatePageCampaign,
  makeSelectDonatePageError,
  makeSelectDonatePageLoading,
  makeSelectDonatePageActiveStep,
};
