/**
 *
 * HintWithNewsBadge
 *
 */

import { Hint, Typography } from '@mamacrowd/ui-kit';
import { useStyle } from './HintWithNewsBadge.style';
import { ReactNode } from 'react';

export interface HintWithNewsBadgeProps {
  chipContent: ReactNode;
  hintTitle?: ReactNode;
  children: ReactNode;
}

export function HintWithNewsBadge({
  chipContent,
  hintTitle,
  children,
}: HintWithNewsBadgeProps) {
  const classes = useStyle();
  return (
    <Hint
      showIcon={false}
      elevation={2}
      title={hintTitle}
      className={classes.hint}
    >
      {chipContent && (
        <Typography className={classes.hintTitle}>{chipContent}</Typography>
      )}
      {children}
    </Hint>
  );
}

export default HintWithNewsBadge;
