import { takeLatest, call, put } from 'redux-saga/effects';
import Logger from 'loglevel';
import { request } from 'utils/request';
import makeApiUrl from 'utils/makeApiUrl';
import {
  CAMPAIGNS_PAGE_LIMIT,
  CAMPAIGNS_ACTIVE_URL,
  CAMPAIGNS_CLOSED_URL,
  ME_CAMPAIGN_PRIVATE_URL,
  USER_PRIVATE_CAMPAIGN_PROPERTY,
} from 'utils/constants';
import { manageSagaError } from 'utils/errorManager';
import { getCampaignsWithRestrictedCampaign } from 'utils/restrictedUtilities';
import User from 'utils/User';

import { Campaign, CampaignStatus } from 'types/Campaign';

import {
  LOAD_ACTIVE_CAMPAIGNS,
  LOAD_CLOSED_CAMPAIGNS,
  FILTER_ACTIVE_CAMPAIGNS,
  FILTER_CLOSED_CAMPAIGNS,
} from './constants';

import {
  loadActiveCampaigns,
  loadActiveCampaignsError,
  loadActiveCampaignsSuccess,
  loadClosedCampaigns,
  loadClosedCampaignsError,
  loadClosedCampaignsSuccess,
} from './actions';
import getCampaignUserInfo from 'utils/getCampaignUserInfo';

export function* activeCampaignsSagaPage(
  action: ReturnType<typeof loadActiveCampaigns>,
) {
  try {
    const { filter, query, campaignTypes } = action;
    const options: { qs: { sort: string; query: string; type?: string } } = {
      qs: {
        sort: filter,
        query,
      },
    };
    if (campaignTypes.length) {
      options.qs.type = campaignTypes.join();
    }
    const activeCampaigns: Campaign[] = [];
    const comingSoonCampaigns: Campaign[] = [];
    const closingCampaigns: Campaign[] = [];
    const restrictedCampaigns: Campaign[] = [];
    const privateCampaigns: Campaign[] = [];

    if (User.getUserProp(USER_PRIVATE_CAMPAIGN_PROPERTY)) {
      const states = [CampaignStatus.comingSoon, CampaignStatus.published];
      const privateCampaignsUrl: string = makeApiUrl(ME_CAMPAIGN_PRIVATE_URL);
      const { campaigns } = yield call(request, privateCampaignsUrl, {
        qs: { ...options.qs, states },
      });
      const privateCampaignsWithUserInfo = yield getCampaignUserInfo(campaigns);
      privateCampaigns.push(...privateCampaignsWithUserInfo);
    }

    const requestActiveUrl = makeApiUrl(CAMPAIGNS_ACTIVE_URL);
    const {
      campaignsActive,
      featuredCampaigns,
    }: { campaignsActive: Campaign[]; featuredCampaigns: Campaign[] } =
      yield call(request, requestActiveUrl, options);

    const activeCampaignsWithUserInfo = yield getCampaignUserInfo(
      campaignsActive,
    );
    const featuredCampaignsWithUserInfo = yield getCampaignUserInfo(
      featuredCampaigns,
    );

    const {
      filteredCampaigns: filteredActiveCampaigns,
      restrictedCampaigns: restrictedCampaignsForMe,
    }: { filteredCampaigns: Campaign[]; restrictedCampaigns: Campaign[] } =
      yield getCampaignsWithRestrictedCampaign(activeCampaignsWithUserInfo, {
        qs: { sort: filter },
      });

    const restrictedCampaignsForMeWithUserInfo = yield getCampaignUserInfo(
      restrictedCampaignsForMe,
    );

    restrictedCampaignsForMeWithUserInfo.forEach((c: Campaign) => {
      restrictedCampaigns.push(c);
    });

    filteredActiveCampaigns.forEach((c: Campaign) => {
      if (c.status === CampaignStatus.comingSoon) {
        comingSoonCampaigns.push(c);
      } else {
        activeCampaigns.push(c);
      }
    });

    Logger.log([
      'SAGAS',
      'CAMPAIGNS PAGE',
      privateCampaigns,
      restrictedCampaigns,
      activeCampaigns,
      comingSoonCampaigns,
      featuredCampaignsWithUserInfo,
      closingCampaigns,
    ]);

    yield put(
      loadActiveCampaignsSuccess(
        privateCampaigns,
        restrictedCampaigns,
        activeCampaigns,
        comingSoonCampaigns,
        featuredCampaignsWithUserInfo,
        closingCampaigns,
      ),
    );
  } catch (err) {
    const error = yield manageSagaError('CampaignsPage - ActiveCampaigns', err);
    yield put(loadActiveCampaignsError(error));
  }
}

// CAUTION: SEARCH AND CLOSED WILL HAVE THE SAME BEHAVIOR!
// CLOSED CAMPAIGNS WILL CALL DIRECTLY CAMPAIGNS AND THE RESTRICTED (NOT THE PRIVATE ONES).
// PRIVATE ONES, IF NOT CLOSED, WILL BE DISPLAYED IN THE EXCLUSIVE SECTION (THE SEARCH OR THE CLOSED WILL NOT AFFECT THIS SAGA AND THE SECTION WILL REMAIN ALWAYS VISIBLE, SO IT DOESN'T CHANGE ON SEARCH).
// RESTRICTED ONES, IF PUBLISHED, WILL BE DISPLAYED IN THE EXCLUSIVE SECTION AS THE PRIVATE, SO, IF I CAN ACCESS TO THAT CAMPAIGN, IT WILL BE REMOVED FROM THE CLOSED LIST.
export function* closedCampaignsSagaPage(
  action: ReturnType<typeof loadClosedCampaigns>,
) {
  try {
    const requestClosedUrl = makeApiUrl(CAMPAIGNS_CLOSED_URL);
    const {
      page,
      filter,
      query,
      campaignTypes,
      limit = CAMPAIGNS_PAGE_LIMIT,
    } = action;

    const states = [
      CampaignStatus.closing,
      CampaignStatus.closedFunded,
      CampaignStatus.exit,
    ];

    if (query) {
      states.unshift(
        CampaignStatus.comingSoon,
        CampaignStatus.published,
        CampaignStatus.closedNotFunded,
      );
    }

    const options: {
      qs: {
        sort: string;
        search?: string;
        type?: string;
        states?: CampaignStatus[];
        limit: number;
        page: number;
      };
    } = {
      qs: {
        limit,
        page,
        search: query,
        states,
        sort: filter,
      },
    };
    if (campaignTypes.length) {
      options.qs.type = campaignTypes.join();
    }

    const privateCampaigns: Campaign[] = [];

    if (User.getUserProp(USER_PRIVATE_CAMPAIGN_PROPERTY)) {
      const privateCampaignsUrl: string = makeApiUrl(ME_CAMPAIGN_PRIVATE_URL);
      const { campaigns } = yield call(request, privateCampaignsUrl, options);
      campaigns
        .filter(
          c =>
            ![CampaignStatus.comingSoon, CampaignStatus.published].includes(
              c.status,
            ),
        )
        .map(c => privateCampaigns.push(c));
    }

    const { campaigns }: { campaigns: { total: number; results: Campaign[] } } =
      yield call(request, requestClosedUrl, options);
    Logger.log(['SAGAS', 'CAMPAIGNS PAGE', 'CLOSED CAMPAIGNS', campaigns]);

    const {
      filteredCampaigns,
      restrictedCampaigns: restrictedCampaignsForMe,
    }: { filteredCampaigns: Campaign[]; restrictedCampaigns: Campaign[] } =
      yield getCampaignsWithRestrictedCampaign(campaigns.results, {
        qs: { sort: filter },
      });

    campaigns.results = yield getCampaignUserInfo([
      ...privateCampaigns,
      ...restrictedCampaignsForMe,
      ...filteredCampaigns,
    ]);

    campaigns.results = campaigns.results.filter(c => {
      if (restrictedCampaignsForMe.map(rc => rc.id).includes(c.id)) {
        return false;
      } else {
        return true;
      }
    });

    Logger.log([
      'SAGAS',
      'CAMPAIGNS PAGE',
      'CLOSED CAMPAIGNS RESTRICTED',
      campaigns,
    ]);

    yield put(loadClosedCampaignsSuccess(campaigns));
  } catch (err) {
    const error = yield manageSagaError('CampaignsPage - ClosedCampaigns', err);
    yield put(loadClosedCampaignsError(error));
  }
}

export default function* campaignsPageSaga() {
  yield takeLatest(LOAD_ACTIVE_CAMPAIGNS, activeCampaignsSagaPage);
  yield takeLatest(LOAD_CLOSED_CAMPAIGNS, closedCampaignsSagaPage);
  yield takeLatest(FILTER_ACTIVE_CAMPAIGNS, activeCampaignsSagaPage);
  yield takeLatest(FILTER_CLOSED_CAMPAIGNS, closedCampaignsSagaPage);
}
