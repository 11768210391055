/* eslint-disable react/jsx-no-useless-fragment */
/**
 *
 * PageLayout
 *
 */

import { IntlShape, injectIntl } from 'react-intl';

import { Typography } from '@mamacrowd/ui-kit';
import { Grid } from '@mui/material';

import Box from 'components/Box';
import LazyLoadImage from 'components/LazyLoadImage';

import ENUMPageLayout from 'utils/Enums/PageLayout';

import { useStyle } from './PageLayout.style';
import { ReactElement } from 'react';

interface IPageLayoutProps {
  title?: string | ReactElement;
  subTitle?: string | ReactElement;
  maxWidth?: 'thin' | 'large';
  coverMaxHeight?: number;
  coverImage?: string | ReactElement;
  image?: string | ReactElement;
  button?: ReactElement | null;
  type?: ENUMPageLayout;
  children?: any;
  intl: IntlShape;
}

function PageLayout({
  title,
  subTitle,
  maxWidth = 'large',
  coverMaxHeight,
  coverImage,
  image,
  button,
  type = ENUMPageLayout.simple,
  children,
  intl,
}: IPageLayoutProps) {
  const classes = useStyle({
    maxWidth,
    coverMaxHeight,
    hasIcon: type === ENUMPageLayout.withIcon,
    hasTitle: type !== ENUMPageLayout.noTitle,
  });
  let alt: string | undefined = '';
  if (type !== ENUMPageLayout.simple) {
    alt =
      title && typeof title !== 'string'
        ? intl.formatMessage(title.props.messages[title.props.messageId])
        : title;
  }

  return (
    <>
      {coverImage && type === ENUMPageLayout.withCover && (
        <>
          {typeof coverImage === 'string' ? (
            <LazyLoadImage
              src={coverImage}
              alt={alt}
              className={classes.coverImage}
            />
          ) : (
            coverImage
          )}
        </>
      )}
      <Box className={classes.pageLayout}>
        <Grid container>
          {type !== ENUMPageLayout.noTitle && (
            <Grid item xs={12} className={classes.pageLayoutHeader}>
              <Grid container>
                {image && type === ENUMPageLayout.withIcon && (
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    className={classes.imageContainer}
                    component="div"
                  >
                    {typeof image === 'string' ? (
                      <LazyLoadImage
                        src={image}
                        alt={alt}
                        className={classes.image}
                        transparentBgWhenLoaded
                      />
                    ) : (
                      image
                    )}
                  </Grid>
                )}
                <Grid item className={classes.titleContainer}>
                  <Grid container>
                    {title && (
                      <Grid item xs={12} style={{ paddingTop: 7 }}>
                        <Typography
                          variant="h1"
                          color="textPrimary"
                          className={classes.title}
                        >
                          {title}
                        </Typography>
                      </Grid>
                    )}
                    {subTitle && (
                      <Grid item xs={12} style={{ paddingTop: 30 }}>
                        <Typography
                          color="textPrimary"
                          className={classes.subTitle}
                        >
                          {subTitle}
                        </Typography>
                      </Grid>
                    )}
                    {button && (
                      <Grid item xs={12} style={{ paddingTop: 15 }}>
                        {button}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {children && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}

export default injectIntl(PageLayout);
