/*
 * SidebarMenu Messages
 *
 * This contains all the text for the SidebarMenu component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.console.components.SidebarMenu';

export default defineMessages({
  overview: {
    id: `${scope}.overview.message`,
    defaultMessage: 'Overview',
  },
  campaigns: {
    id: `${scope}.campaigns.message`,
    defaultMessage: 'Campaigns',
  },
  campaign: {
    id: `${scope}.campaign.message`,
    defaultMessage: 'Campaign',
  },
  investmentOrders: {
    id: `${scope}.investment_orders.message`,
    defaultMessage: 'Investment Orders',
  },
  incompleteOrdersList: {
    id: `${scope}.incomplete_orders_list.message`,
    defaultMessage: 'Incomplete Orders List',
  },
  investorsList: {
    id: `${scope}.investors_list.message`,
    defaultMessage: 'Investors List',
  },
  earlyBird: {
    id: `${scope}.early_bird.message`,
    defaultMessage: 'Early Bird',
  },
  followers: {
    id: `${scope}.followers.message`,
    defaultMessage: 'followers',
  },
  ordersForBank: {
    id: `${scope}.orders_for_bank.message`,
    defaultMessage: 'orders for bank',
  },
  sim: {
    id: `${scope}.sim.message`,
    defaultMessage: 'Sim',
  },
  billing: {
    id: `${scope}.billing.message`,
    defaultMessage: 'Billing',
  },
  stats: {
    id: `${scope}.stats.message`,
    defaultMessage: 'statistics',
  },
  operations: {
    id: `${scope}.operations.message`,
    defaultMessage: 'Operations',
  },
  companies: {
    id: `${scope}.companies.message`,
    defaultMessage: 'Companies',
  },
  companyMonitoring: {
    id: `${scope}.company_monitoring.message`,
    defaultMessage: 'Company Reports',
  },
  chamberDoc: {
    id: `${scope}.chamber_doc.message`,
    defaultMessage: 'Company Search',
  },
  orders: {
    id: `${scope}.orders.message`,
    defaultMessage: 'Orders',
  },
  order: {
    id: `${scope}.order.message`,
    defaultMessage: 'Order',
  },
  incompleteOrders: {
    id: `${scope}.incomplete_orders.message`,
    defaultMessage: 'Incomplete Orders',
  },
  latePayments: {
    id: `${scope}.late_payments.message`,
    defaultMessage: 'Late Payments',
  },
  billing: {
    id: `${scope}.billing.message`,
    defaultMessage: 'Billing',
  },
  users: {
    id: `${scope}.users.message`,
    defaultMessage: 'Users',
  },
  statistics: {
    id: `${scope}.statistics.message`,
    defaultMessage: 'Statistics',
  },
  competitors: {
    id: `${scope}.competitors.message`,
    defaultMessage: 'Competitors',
  },
  campaignApplication: {
    id: `${scope}.campaign_application.message`,
    defaultMessage: 'Campaign Application',
  },
  crmEmail: {
    id: `${scope}.crm_email.message`,
    defaultMessage: 'CRM Email',
  },
  customNotification: {
    id: `${scope}.custom_notification.message`,
    defaultMessage: 'custom notification',
  },
  customMarketingEmail: {
    id: `${scope}.custom_marketing_email.message`,
    defaultMessage: 'Custom Marketing Email',
  },
  emailServiceStatus: {
    id: `${scope}.email_service_status.message`,
    defaultMessage: 'Email Service Status',
  },
  cms: {
    id: `${scope}.cms.message`,
    defaultMessage: 'CMS',
  },
  messages: {
    id: `${scope}.messages.message`,
    defaultMessage: 'Messages',
  },
  banners: {
    id: `${scope}.banners.message`,
    defaultMessage: 'Banners',
  },
  articles: {
    id: `${scope}.articles.message`,
    defaultMessage: 'articles',
  },
  partners: {
    id: `${scope}.partners.message`,
    defaultMessage: 'Partners',
  },
  testimonials: {
    id: `${scope}.testimonials.message`,
    defaultMessage: 'Testimonials',
  },
  pages: {
    id: `${scope}.pages.message`,
    defaultMessage: 'Pages',
  },
  press: {
    id: `${scope}.press.message`,
    defaultMessage: 'Press',
  },
  faq: {
    id: `${scope}.faq.message`,
    defaultMessage: 'FAQ',
  },
  settings: {
    id: `${scope}.settings.message`,
    defaultMessage: 'Settings',
  },
  industries: {
    id: `${scope}.industries.message`,
    defaultMessage: 'Industries',
  },
  urlChecker: {
    id: `${scope}.url_checker.message`,
    defaultMessage: 'URL Checker',
  },
  media: {
    id: `${scope}.media.message`,
    defaultMessage: 'Media',
  },
  guides: {
    id: `${scope}.guides.message`,
    defaultMessage: 'Guides',
  },
  apiDocs: {
    id: `${scope}.api_docs.message`,
    defaultMessage: 'Api Docs',
  },
  apiTasksDocs: {
    id: `${scope}.api_tasks_docs.message`,
    defaultMessage: 'Api Tasks Docs',
  },
  apiRichdataDocs: {
    id: `${scope}.api_richdata_docs.message`,
    defaultMessage: 'Api Richdata Docs',
  },
  styleGuide: {
    id: `${scope}.style_guide.message`,
    defaultMessage: 'MMC Style Guide',
  },
  version: {
    id: `${scope}.version.message`,
    defaultMessage: 'Ver. {version}',
  },
});
