/**
 *
 * Input
 *
 */

import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { useStyle } from './Input.style';

function Input({
  label,
  onBlur,
  onTextChange,
  validateOnChange = false,
  value = '',
  variant = 'standard',
  disabled = false,
  error,
  validate,
  cleanErrorOnChange,
  multiline = false,
  helperText,
  fontSize = 'inherit',
  code = false,
  ...rest
}) {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (error && typeof cleanErrorOnChange === 'function') {
      cleanErrorOnChange('');
    }

    if (validateOnChange && typeof validate === 'function') {
      validate(inputValue);
    }
  }, [inputValue]);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleOnChange = e => {
    const newInputValue = e.target.value;
    if (typeof onTextChange === 'function') {
      onTextChange(newInputValue);
    }
    setInputValue(newInputValue);
  };

  const onTextBlur = e => {
    const newInputValue = e.target.value;
    if (typeof validate === 'function') {
      validate(newInputValue);
    }
    if (typeof onBlur === 'function') {
      onBlur(newInputValue);
    }
  };

  const classes = useStyle({ fontSize, code });
  return (
    <TextField
      variant={variant}
      value={inputValue || ''}
      label={label}
      error={!!error}
      helperText={error || helperText}
      onChange={handleOnChange}
      onBlur={onTextBlur}
      className={classes.textField}
      size="small"
      multiline={multiline}
      disabled={disabled || false}
      {...rest}
    />
  );
}

Input.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onTextChange: PropTypes.func,
  validateOnChange: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  validate: PropTypes.func,
  cleanErrorOnChange: PropTypes.func,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  width: PropTypes.string,
  multiline: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code: PropTypes.bool,
};

export default Input;
