import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { MessageBannerType } from 'types/MessageBanner';
import { MAX_WIDTH_PAGE } from 'utils/constants';

type Props = {
  type: `${MessageBannerType}`;
};

export const useStyle = makeStyles<Theme, Props>(theme => ({
  messageBannerContainer: props => {
    let color = theme.palette.primary;
    switch (props.type) {
      case 'alert':
        color = theme.palette.error;
        break;
      case 'warning':
        color = theme.palette.warning;
        break;
      case 'success':
        color = theme.palette.success;
        break;
      case 'info':
        color = theme.palette.webinar;
        break;
      default:
        color = theme.palette.primary;
    }
    return {
      backgroundColor: color.light,
      borderBottom: `0.5px solid ${color.main}`,
    };
  },
  messageBannerContent: {
    maxWidth: MAX_WIDTH_PAGE,
    margin: '0 auto',
    padding: '15px 40px',
    [theme.breakpoints.down('md')]: {
      padding: '10px 20px',
    },
  },
  messageBannerText: props => {
    let color = theme.palette.text.primary;
    switch (props.type) {
      case 'alert':
        color = theme.palette.text.primary;
        break;
      case 'warning':
        color = theme.palette.text.primary;
        break;
      case 'success':
        color = theme.palette.text.primary;
        break;
      case 'info':
        color = theme.palette.common.white;
        break;
      default:
        color = theme.palette.text.primary;
    }
    return {
      color,
      display: 'inline-block',
      paddingRight: 8,
      fontSize: 14,
    };
  },
  messageBannerCTA: props => {
    let color = theme.palette.primary.main;
    switch (props.type) {
      case 'alert':
        color = theme.palette.error.main;
        break;
      case 'warning':
        color = theme.palette.warning.main;
        break;
      case 'success':
        color = theme.palette.success.main;
        break;
      case 'info':
        color = theme.palette.common.white;
        break;
      default:
        color = theme.palette.primary.main;
    }
    return {
      color,
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 600,
      fontSize: 12,
    };
  },
  closeIcon: props => {
    let color = theme.palette.primary.main;
    switch (props.type) {
      case 'alert':
        color = theme.palette.error.main;
        break;
      case 'warning':
        color = theme.palette.warning.main;
        break;
      case 'success':
        color = theme.palette.success.main;
        break;
      case 'info':
        color = theme.palette.common.white;
        break;
      default:
        color = theme.palette.primary.main;
    }
    return {
      fontSize: 20,
      cursor: 'pointer',
      color,
    };
  },
}));
