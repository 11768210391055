/**
 *
 * Dialog
 *
 */

import PropTypes from 'prop-types';

import { Typography } from '@mamacrowd/ui-kit';
import {
  Dialog as MUIDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from '@mui/material';
import Close from '@mui/icons-material/Close';

import Button from 'basic/Button';
import IconButton from 'basic/IconButton';
import FormattedMessage from 'components/FormattedMessage';

import messages from './messages';
import { useStyle } from './Dialog.style';

function Dialog({
  open = false,
  onClose,
  title,
  children,
  onConfirm,
  onDecline,
  confirmButtonText,
  declineButtonText,
  actionButtons = [],
  scroll = 'paper',
  maxWidth = 'md',
  fullWidth = false,
  fullScreen = false,
  disableButtons = false,
  spinnerButtons = false,
  closeIconButton = false,
  ...rest
}) {
  const classes = useStyle();
  return (
    <MUIDialog
      aria-labelledby="dialog-confirm"
      onClose={onClose}
      open={open}
      scroll={scroll}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      {...rest}
    >
      {(title || closeIconButton) && (
        <DialogTitle
          id="dialog-confirm"
          component="div"
          className={classes.dialogTitle}
        >
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h3" color="textPrimary" component="p">
                {title}
              </Typography>
            </Grid>
            {closeIconButton && !!onClose && (
              <Grid item>
                <IconButton
                  icon={<Close fontSize="small" color="action" />}
                  onClick={onClose}
                  data-testid="dialog-close-icon"
                />
              </Grid>
            )}
          </Grid>
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
      {(onDecline || onConfirm || !!actionButtons.length) && (
        <DialogActions className={classes.dialogActions}>
          {onDecline && typeof onDecline === 'function' && (
            <Button
              variant="secondary"
              onClick={onDecline}
              disabled={disableButtons}
              text={
                declineButtonText || (
                  <FormattedMessage messages={messages} messageId="no" />
                )
              }
              data-testid="dialog-decline-button"
            />
          )}
          {onConfirm && typeof onConfirm === 'function' && (
            <Button
              onClick={onConfirm}
              disabled={disableButtons}
              spinner={spinnerButtons}
              text={
                confirmButtonText || (
                  <FormattedMessage messages={messages} messageId="yes" />
                )
              }
              data-testid="dialog-confirm-button"
            />
          )}
          {actionButtons.map(button => button)}
        </DialogActions>
      )}
    </MUIDialog>
  );
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.any,
  scroll: PropTypes.oneOf(['body', 'paper']),
  confirmButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  declineButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onConfirm: PropTypes.func,
  onDecline: PropTypes.func,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
  closeIconButton: PropTypes.bool,
  disableButtons: PropTypes.bool,
  spinnerButtons: PropTypes.bool,
  actionButtons: PropTypes.array,
};

export default Dialog;
