/*
 *
 * DonatePage reducer
 *
 */
import produce from 'immer';
import {
  LOAD_CAMPAIGN_INFO,
  LOAD_CAMPAIGN_INFO_ERROR,
  LOAD_CAMPAIGN_INFO_SUCCESS,
  GO_TO_NEXT_STEP,
  GO_TO_PREV_STEP,
  SET_ACTIVE_STEP,
  RESET_STEPS_STATE,
} from './constants';

export const initialState = {
  loading: false,
  campaign: null,
  error: false,
  activeStep: 0,
};

/* eslint-disable default-case, no-param-reassign */
const donatePageReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_CAMPAIGN_INFO:
        draft.loading = true;
        draft.campaign = null;
        draft.error = false;
        break;
      case LOAD_CAMPAIGN_INFO_SUCCESS:
        draft.loading = false;
        draft.campaign = action.campaign;
        draft.error = false;
        break;
      case LOAD_CAMPAIGN_INFO_ERROR:
        draft.loading = false;
        draft.campaign = null;
        draft.error = action.error || true;
        break;
      case GO_TO_NEXT_STEP:
        draft.activeStep += 1;
        break;
      case GO_TO_PREV_STEP:
        draft.activeStep -= 1;
        break;
      case SET_ACTIVE_STEP:
        draft.activeStep = action.step;
        break;
      case RESET_STEPS_STATE:
        draft.loading = false;
        draft.campaign = null;
        draft.error = false;
        draft.activeStep = 0;
        break;
    }
  });

export default donatePageReducer;
