/*
 * MessageBanner Messages
 *
 * This contains all the text for the MessageBanner container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MessageBanner';

export default defineMessages({
  join_community: {
    id: `${scope}.join_community.message`,
    defaultMessage: 'Join Community!', // Iscriviti alla community
  },
  enroll: {
    id: `${scope}.enroll.message`,
    defaultMessage: 'enroll!', // Iscriviti subito
  },
  subscribe_newsletter: {
    id: `${scope}.subscribe_newsletter.message`,
    defaultMessage: 'subscribe_newsletter!', // Iscriviti alla newsletter
  },
  upload_doc: {
    id: `${scope}.upload_doc.message`,
    defaultMessage: 'upload_doc!', // Carica Documento
  },
  relaunch_decree: {
    id: `${scope}.relaunch_decree.message`,
    defaultMessage: 'relaunch_decree!', // Scopri i dettagli del "Decreto Rilancio"
  },
  up_validate: {
    id: `${scope}.up_validate.message`,
    defaultMessage: 'up_validate!', // Valida i tuoi dati
  },
});
