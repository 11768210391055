import { takeLatest, put, call } from 'redux-saga/effects';
import Logger from 'loglevel';
import { request } from 'utils/request';
import makeApiUrl from 'utils/makeApiUrl';
import User from 'utils/User';
import { SEC_CAMPAIGN_URL } from 'utils/constants';
import { manageSagaError } from 'utils/errorManager';
import { trackLogout } from 'utils/trackers';
import {
  LOAD_USER,
  LOAD_CONSOLE_ACTIVE_CAMPAIGN,
  LOAD_LOGOUT,
} from './constants';

import {
  loadUserSuccess,
  loadUserError,
  loadConsoleActiveCampaignError,
  loadConsoleActiveCampaignSuccess,
  loadLogoutSuccess,
  loadLogoutError,
} from './actions';

export function* makeLoadUser() {
  try {
    const user = User.init();
    Logger.log(['SAGAS', 'USER', 'LOAD', 'SUCCESS', user]);
    yield put(loadUserSuccess(user));
  } catch (err) {
    const error = yield manageSagaError('App', err);
    yield put(loadUserError(error));
  }
}

export function* makeLoadActiveCampaign(payload) {
  try {
    const campaignUrl = makeApiUrl(
      SEC_CAMPAIGN_URL.replace(':campaignId', payload.campaignId),
    );
    const options = { qs: { details: 'company' } };
    const activeCampaign = yield call(request, campaignUrl, options);
    Logger.log(['SAGAS', 'GLOBAL', 'ACTIVE CAMPAIGN', activeCampaign]);
    yield put(loadConsoleActiveCampaignSuccess(activeCampaign));
  } catch (err) {
    const error = yield manageSagaError('App', err);
    yield put(loadConsoleActiveCampaignError(error));
  }
}

export function* userLogoutSaga() {
  try {
    User.logout();

    trackLogout();

    Logger.log(['SAGAS', 'USER', 'LOGOUT', 'SUCCESS']);
    yield put(loadLogoutSuccess());
  } catch (err) {
    const error = yield manageSagaError('App', err);
    yield put(loadLogoutError(error));
  }
}

export default function* appSaga() {
  yield takeLatest(LOAD_USER, makeLoadUser);
  yield takeLatest(LOAD_CONSOLE_ACTIVE_CAMPAIGN, makeLoadActiveCampaign);
  yield takeLatest(LOAD_LOGOUT, userLogoutSaga);
}
