import { call, put, takeLatest } from 'redux-saga/effects';
import Logger from 'loglevel';

import { request } from 'utils/request';
import makeApiUrl from 'utils/makeApiUrl';
import { SETTINGS_URL } from 'utils/constants';
import { manageSagaError } from 'utils/errorManager';

import { LOAD_ADDITIONAL_HEADER } from './constants';
import {
  loadAdditionalHeaderSuccess,
  loadAdditionalHeaderError,
} from './actions';

export function* makeHeader() {
  try {
    const requestHeaderLinkUrl = makeApiUrl(
      SETTINGS_URL.replace(':key', 'HP_EXTRA_MENU'),
    );
    const headerLink = yield call(request, requestHeaderLinkUrl);
    Logger.log(['SAGAS', 'HEADER', 'EXTRA HEADER', headerLink]);
    yield put(loadAdditionalHeaderSuccess(headerLink));
  } catch (err) {
    const error = yield manageSagaError('Header', err);
    yield put(loadAdditionalHeaderError(error));
  }
}
// Individual exports for testing
export default function* headerSaga() {
  yield takeLatest(LOAD_ADDITIONAL_HEADER, makeHeader);
}
