/**
 *
 * MeRoute
 *
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import User from 'utils/User';
import composeRedirectUrl from 'utils/composeRedirectUrl';
import { LOGIN_ROUTE, NOT_FOUND_PAGE_ROUTE } from 'utils/constants';

export function MeRoute({
  MainComponent,
  Layout,
  history,
  campaignOwnerRoute = false,
  layoutProps,
  ...rest
}) {
  useEffect(() => {
    if (!User.isLogged()) {
      const url = composeRedirectUrl(LOGIN_ROUTE);
      history.push(url);
    } else if (campaignOwnerRoute && !User.getUserProp('campaignOwner')) {
      history.push(`${NOT_FOUND_PAGE_ROUTE}`);
    }
  }, [campaignOwnerRoute]);

  return (
    <Route
      {...rest}
      exact={rest.exact}
      render={props => (
        <Layout {...layoutProps}>
          <MainComponent {...props} {...rest} />
        </Layout>
      )}
    />
  );
}

MeRoute.propTypes = {
  MainComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  Layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  campaignOwnerRoute: PropTypes.bool,
  layoutProps: PropTypes.object,
  history: PropTypes.object.isRequired,
};

export default withRouter(MeRoute);
