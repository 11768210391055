import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the donateSuccessPage state domain
 */

const selectDonateSuccessPageDomain = state =>
  state.donateSuccessPage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by DonateSuccessPage
 */

const makeSelectDonateSuccessPage = () =>
  createSelector(selectDonateSuccessPageDomain, substate => substate);

const makeSelectDonateSuccessPageDonation = () =>
  createSelector(selectDonateSuccessPageDomain, substate => substate.donation);
const makeSelectDonateSuccessPageError = () =>
  createSelector(selectDonateSuccessPageDomain, substate => substate.error);
const makeSelectDonateSuccessPageLoading = () =>
  createSelector(selectDonateSuccessPageDomain, substate => substate.loading);

export default makeSelectDonateSuccessPage;
export {
  selectDonateSuccessPageDomain,
  makeSelectDonateSuccessPageDonation,
  makeSelectDonateSuccessPageError,
  makeSelectDonateSuccessPageLoading,
};
