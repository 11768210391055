/**
 *
 * HeaderPrimaryLink
 *
 */

import { useHistory } from 'react-router-dom';

import { Typography } from '@mamacrowd/ui-kit';
import { Theme, useMediaQuery } from '@mui/material';

import Link from 'basic/Link';
import Button from 'basic/Button';
import FormattedMessage from 'components/FormattedMessage';

import { HeaderLinkProps } from '..';

export interface HeaderPrimaryLinkProps {
  item: HeaderLinkProps['item'];
  messages: object;
}

function HeaderPrimaryLink({ item, messages }: HeaderPrimaryLinkProps) {
  const navigate = useHistory();
  const isDownSM = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  const handleClick = () => {
    if (item.link) {
      navigate.push(item.link);
    }
  };

  return isDownSM ? (
    <Link to={item.link}>
      <Typography color="textPrimary">
        <FormattedMessage messages={messages} messageId={item.translationId} />
      </Typography>
    </Link>
  ) : (
    <Button
      variant="text"
      onClick={handleClick}
      text={
        <FormattedMessage messages={messages} messageId={item.translationId} />
      }
      style={{ minWidth: 100 }}
    />
  );
}

export default HeaderPrimaryLink;
