/**
 *
 * LiteLayout
 *
 */

import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

import Logo from 'components/Logo';
import Box from 'components/Box';
import Intercom from 'components/Intercom';
import CookiesBanner from 'containers/CookiesBanner';

import { useStyle } from './LiteLayout.style';

export function LiteLayout({ children }) {
  const classes = useStyle();
  const isOverMD = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <>
      <Box className={classes.headerContainer}>
        <Logo maxWidth={isOverMD ? 180 : 150} />
      </Box>
      <Box className={classes.pageContainer}>{children}</Box>
      <CookiesBanner />
      <Intercom />
    </>
  );
}

LiteLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default LiteLayout;
