import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 700,
    paddingTop: theme.spacing(2),
  },
  text: {
    paddingTop: theme.spacing(1),
  },
}));
