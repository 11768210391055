/*
 * LogoutButton Messages
 *
 * This contains all the text for the LogoutButton container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.LogoutButton';

export default defineMessages({
  logout: {
    id: `${scope}.logout.message`,
    defaultMessage: 'Logout',
  },
});
