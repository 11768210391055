import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  container: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    width: '100%',
    padding: '15px 0px',
    marginTop: 20,
  },
  containerLanding: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.secondary.main}`,
  },
  textLanding: {
    padding: '10px 4%',
    textAlign: 'center',
  },
  textPec: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'justify',
    },
  },
}));
