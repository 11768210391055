import { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import ErrorPage from 'containers/ErrorPage';
// import Logger from 'loglevel';

export interface ErrorBoundaryProps {
  children: ReactNode | ReactNode[];
}

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorBoundary;
