/**
 *
 * UserButton
 *
 */
import { Typography } from '@mamacrowd/ui-kit';
import { Grid, Theme, useMediaQuery } from '@mui/material';
import KeyboardArrowDownRounded from '@mui/icons-material/KeyboardArrowDownRounded';

import Chip from 'basic/Chip';
import Button from 'basic/Button';
import IconButton from 'basic/IconButton';
import HeaderLink from 'components/HeaderLink';
import messages from 'containers/Header/messages';
import FormattedMessage from 'components/FormattedMessage';
import { getAccountLinks } from 'containers/Header/HeaderLinks';

import UserLogged from 'images/UserLogged/user-logged-true.svg';
import UserNotLogged from 'images/UserLogged/user-logged-false.svg';
import PowerUserIcon from 'images/power-user-icon-blue.svg';

import { useStyle } from './UserButton.style';

export interface UserButtonProps {
  firstName?: string;
  isLogged?: boolean;
  isPowerUser?: boolean;
  onClick: () => void;
}

export function UserButton({
  firstName,
  isLogged,
  isPowerUser,
  onClick,
}: UserButtonProps) {
  const classes = useStyle({ isPowerUser });
  const isOverMD = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

  const userIconJSX = (
    <>
      <img
        src={isLogged ? UserLogged : UserNotLogged}
        alt={isLogged ? 'logged-user-icon' : 'user-icon'}
      />
      {isPowerUser && (
        <img src={PowerUserIcon} className={classes.powerUserIcon} />
      )}
    </>
  );

  if (!isOverMD) {
    return <IconButton onClick={onClick} icon={userIconJSX} text={null} />;
  }

  if (isLogged) {
    return (
      <Button
        variant="text"
        onClick={onClick}
        startIcon={userIconJSX}
        endIcon={<KeyboardArrowDownRounded />}
        text={
          firstName ? (
            <Typography variant="inherit" className={classes.userButtonLabel}>
              {firstName}
              {isPowerUser && (
                <Chip
                  label={
                    <FormattedMessage
                      messages={messages}
                      messageId="powerUser"
                    />
                  }
                  color="info"
                  variant="outlined"
                  icon={null}
                  className={classes.powerUserChip}
                />
              )}
            </Typography>
          ) : (
            ''
          )
        }
        loading={false}
        className={classes.userButton}
      />
    );
  }

  return (
    <Grid container columnSpacing={1}>
      {getAccountLinks().unlogged.map(item => (
        <Grid item key={item.translationId}>
          <HeaderLink item={item} messages={messages} />
        </Grid>
      ))}
    </Grid>
  );
}

export default UserButton;
