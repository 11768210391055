import Logger from 'loglevel';
import { isPast } from 'date-fns';
import * as Sentry from '@sentry/react';
import { matchPath } from 'react-router';

import makeApiUrl from 'utils/makeApiUrl';
import User from 'utils/User'; // eslint-disable-line import/no-cycle
import { CampaignType } from 'types/Campaign';
import ENUMInvestorType from 'utils/Enums/InvestorType';
import {
  CAMPAIGN_ROUTE,
  LOG_AWS_TRACK_EXCEPTION_ENABLED,
  LOG_ERROR_URL,
} from 'utils/constants';
import { getCookieAnalytics, getCookieSupport } from 'utils/cookie';

import GA4Tracker from './ga4';
// import GoogleTracker from './google';
// import FacebookTracker from './facebook';
import LinkedInTracker from './linkedin';
import pkgInfo from '../../../package.json';

const IC_ACTION_SIGNUP = 'registration';
const IC_ACTION_LOGIN = 'login';
const IC_ACTION_LOGOUT = 'logout';
const IC_ACTION_USER_ACTIVATE = 'registration_confirmed';
const IC_ACTION_CAMPAIGN_VIEW = 'campaign_view';
const IC_ACTION_DOCUMENT_DOWNLOAD = 'document_download';
// const IC_ACTION_CAMPAIGN_PLAY_VIDEO = 'play_video';
const IC_ACTION_CAMPAIGN_SHARE = 'campaign_share';
const IC_ACTION_CAMPAIGN_FOLLOW = 'campaign_follow';
const IC_ACTION_CAMPAIGN_UNFOLLOW = 'campaign_unfollow';
const IC_ACTION_CAMPAIGN_INTEREST = 'campaign_interest';
const IC_ACTION_COMMITMENT_BEGIN = 'commitment_begin';
const IC_ACTION_COMMITMENT_SPECIFY_AMOUNT = 'commitment_specify_equity';
const IC_ACTION_COMMITMENT_ORDER_PROCEED = 'commitment_order_proceed';
const IC_ACTION_COMMITMENT_USER_TYPE = 'commitment_specify_user_type';
const IC_ACTION_COMMITMENT_USER_PROFILE = 'commitment_save_user_profile';
const IC_ACTION_COMMITMENT_BUSINESS_PROFILE =
  'commitment_save_business_profile';
const IC_ACTION_COMMITMENT_BASIC_PROFILE = 'commitment_save_basic_profile';
const IC_ACTION_COMMITMENT_ACCEPT_ADVICE = 'appropriateness_accept_advice';
const IC_ACTION_COMMITMENT_MIFID = 'appropriateness_complete';
const IC_ACTION_COMMITMENT_COMPLETE = 'investment_complete';

// const ALLOWED_CUSTOM_DIMENSIONS = /(dimension|metric)\d{1,2}/;

const isProduction = process.env.NODE_ENV === 'production';

function isInUrlBlackList(url) {
  const blackListPaths = [CAMPAIGN_ROUTE];
  return blackListPaths.some(path => {
    const match = matchPath(url, { path });
    return match?.isExact || false;
  });
}

function getList(campaign) {
  switch (campaign.type) {
    case CampaignType.donation:
      return 'Donations';
    case CampaignType.realEstate:
    case CampaignType.realEstateIncome:
    case CampaignType.equity:
    default:
      return 'Mamacrowd';
  }
}

function sendIntercomEvent(event, metadata) {
  if (isProduction && window.Intercom && getCookieSupport())
    window.Intercom('trackEvent', event, metadata);
}

function trackIntercomEvent(campaign, action, options) {
  const { amount = 0, investorType = '', intercom } = options;
  sendIntercomEvent(action, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
    campaignList: getList(campaign),
    commitmentType: campaign.type,
    commitmentAmount: parseFloat(amount),
    ...(investorType ? { commitmentUserType: investorType } : {}),
    ...(intercom || {}),
  });
}

function setUser() {
  const uid = User.getUserProp('id') || '';
  GA4Tracker.setUser(uid);
}

export function toggleConsent(cookieAnalytics) {
  GA4Tracker.toggleConsent(cookieAnalytics);
}

export function trackersInit() {
  // GA4 init
  GA4Tracker.init();
  // LinkedIn init
  LinkedInTracker.init();
  toggleConsent(getCookieAnalytics());
}

export function trackEvent(action, label, category, value) {
  GA4Tracker.trackCustomEvent(action, label, category, value);
}

export function trackSignUp(uuid, isConfirmed, method) {
  setUser(uuid);
  GA4Tracker.trackSignUp(isConfirmed, method);
  if (isConfirmed) LinkedInTracker.trackRegister();
  sendIntercomEvent(isConfirmed ? IC_ACTION_USER_ACTIVATE : IC_ACTION_SIGNUP, {
    label: method,
    userId: uuid || '',
  });
}

export function trackLogin(uid, method) {
  setUser(uid);
  GA4Tracker.trackLogin(method);
  sendIntercomEvent(IC_ACTION_LOGIN, {
    label: method,
    userId: uid || '',
  });
}

export function trackLogout() {
  setUser();
  GA4Tracker.trackLogout();
  sendIntercomEvent(IC_ACTION_LOGOUT, {
    label: 'Logout',
    userId: User.getUserProp('id') || '',
  });
}

export function trackPageView(path, title) {
  if (!isInUrlBlackList(path.substring(3))) {
    setUser();
    GA4Tracker.trackPageView(path, title);
  }
}

export function trackViewItemList(campaignsList) {
  GA4Tracker.trackViewItemList(campaignsList);
}

export function trackSelectItem(campaign) {
  GA4Tracker.trackSelectItem(campaign);
}

export function trackCampaignView(campaign) {
  setUser();
  GA4Tracker.trackCampaignView(campaign);
  GA4Tracker.trackViewItem(campaign);
  sendIntercomEvent(IC_ACTION_CAMPAIGN_VIEW, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
  });
}

export function trackCampaignViewSection(campaignId, tab, section) {
  setUser();
  GA4Tracker.trackCampaignViewSection(campaignId, tab, section);
}

export function trackCampaignViewTab(campaignId, tab) {
  setUser();
  GA4Tracker.trackCampaignViewTab(campaignId, tab);
}

export function trackCampaignFollow(campaign) {
  setUser();
  GA4Tracker.trackAddToWishlist(campaign);
  LinkedInTracker.trackFollow(campaign);
  sendIntercomEvent(IC_ACTION_CAMPAIGN_FOLLOW, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
  });
}

export function trackCampaignInterest(campaign, value) {
  GA4Tracker.trackInterest(campaign, value);
  LinkedInTracker.trackInterest(campaign);
  sendIntercomEvent(IC_ACTION_CAMPAIGN_INTEREST, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
    amount: value,
  });
}

export function trackCampaignUnfollow(campaign) {
  sendIntercomEvent(IC_ACTION_CAMPAIGN_UNFOLLOW, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
  });
}

// MC2: NOT USED
export function trackCampaignShare(campaign, method) {
  setUser();
  GA4Tracker.trackShare(campaign, method);
  sendIntercomEvent(IC_ACTION_CAMPAIGN_SHARE, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
  });
}

export function trackCommitmentAmount(campaign, amount) {
  Logger.log(['TRACK AMOUNT', campaign, amount]);
  setUser();
  // GA4Tracker.trackRemoveToCart(campaign);
  LinkedInTracker.trackCommitmentSpecifyAmount(campaign);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_SPECIFY_AMOUNT, { amount });
}

export function trackCommitmentBegin(campaign, amount) {
  Logger.log(['0 TRACK STEP BEGIN', campaign, amount]);
  setUser();
  GA4Tracker.trackAddToCart(campaign);
  LinkedInTracker.trackCommitmentBegin(campaign);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_BEGIN, { amount });
}

export function trackCommitmentOrderProceed(campaign, amount) {
  Logger.log(['1 TRACK STEP ORDER PROCEED', campaign, amount]);
  setUser();
  GA4Tracker.trackBeginCheckout(campaign, parseFloat(amount));
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_ORDER_PROCEED, { amount });
}

export function trackCommitmentInvestorType(campaign, amount, investorType) {
  Logger.log(['2 TRACK STEP USER TYPE', campaign, amount, investorType]);
  setUser();
  GA4Tracker.trackStepInvestorType(campaign, investorType);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_USER_TYPE, {
    amount,
    investorType,
  });
}

export function trackCommitmentUserProfile(campaign, userProfileId, amount, investorType) { // eslint-disable-line prettier/prettier
  Logger.log(['3 TRACK STEP USER PROFILE', campaign, amount, investorType]); // eslint-disable-line prettier/prettier
  setUser();
  GA4Tracker.trackStepUserProfile(campaign, userProfileId);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_USER_PROFILE, {
    amount,
    investorType,
  });
}

export function trackCommitmentBusinessProfile(campaign, businessProfileId, amount, investorType) { // eslint-disable-line prettier/prettier
  Logger.log(['4 TRACK STEP BUSINESS PROFILE', campaign, amount, investorType]); // eslint-disable-line prettier/prettier
  setUser();
  GA4Tracker.trackStepBusinessProfile(campaign, businessProfileId);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_BUSINESS_PROFILE, {
    amount,
    investorType,
  });
}

export function trackCommitmentKnowledge(campaign, investorKnowledgeTestId, amount, investorType) { // eslint-disable-line prettier/prettier
  Logger.log(['5 TRACK STEP KNOWLEDGE', campaign, amount, investorType]);
  setUser();
  GA4Tracker.trackStepKnowledgeTest(campaign, investorKnowledgeTestId);
  // trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_KNOWLEDGE_TEST, {
  //   amount,
  //   investorType,
  // });
}

export function trackCommitmentBearLossesSimulation(campaign, investorKnowledgeTestId, amount, investorType) { // eslint-disable-line prettier/prettier
  Logger.log(['5 TRACK STEP BEAR LOSSES SIMULATION', campaign, amount, investorType]); // eslint-disable-line prettier/prettier
  setUser();
  GA4Tracker.trackStepBearLossesSimulation(campaign, investorKnowledgeTestId);
  // trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_BEAR_LOSSES_SIMULATION, {
  //   amount,
  //   investorType,
  // });
}

export function trackCommitmentMifid(campaign, amount, investorType) {
  Logger.log(['5 TRACK STEP MIFID', campaign, amount, investorType]);
  setUser();
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_MIFID, {
    amount,
    investorType,
  });
}

export function trackCommitmentReviewAndAccept(campaign, amount, investorType) {
  Logger.log(['6 TRACK STEP REVIEW & ACCEPT', campaign, amount, investorType]); // eslint-disable-line prettier/prettier
  setUser();
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_ACCEPT_ADVICE, {
    amount,
    investorType,
  });
}

export function trackCommitmentComplete(campaign, amount, commitmentId, investorType) { // eslint-disable-line prettier/prettier
  Logger.log(['7 TRACK ORDER COMPLETE', campaign, amount, commitmentId, investorType]); // eslint-disable-line prettier/prettier
  setUser();
  GA4Tracker.trackPurchase(campaign, parseFloat(amount), commitmentId);
  LinkedInTracker.trackPurchase(campaign);
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_COMPLETE, {
    amount,
    intercom: {
      commitmentId,
      commitmentCurrency: 'EUR',
    },
  });
}

export const trackCommitmentInitialSteps = ({
  campaign,
  equity,
  investorType,
  userProfile,
  businessProfile,
  mifid,
  trackFirstStep,
  isCampaignEquityFund = false,
}) => {
  Logger.log([
    'ADD TO CHART ORDER',
    'campaign:',
    campaign,
    'equity:',
    equity,
    'investorType:',
    investorType,
    'userProfile:',
    userProfile,
    'businessProfile:',
    businessProfile,
    'mifid:',
    mifid,
    'equity fund',
    isCampaignEquityFund,
  ]);

  const amount =
    equity && equity.calcValues && equity.calcValues.sharePriceProposed;

  if (trackFirstStep) {
    trackCommitmentOrderProceed(campaign, amount);
  }

  if (equity && investorType) {
    trackCommitmentInvestorType(campaign, amount, investorType);
    if (userProfile) {
      trackCommitmentUserProfile(campaign, amount, investorType);
      if (investorType === ENUMInvestorType.business && businessProfile) {
        trackCommitmentBusinessProfile(campaign, amount, investorType);
      }
      if (
        (investorType === ENUMInvestorType.user ||
          (investorType === ENUMInvestorType.business && businessProfile)) &&
        (isCampaignEquityFund || (mifid && !isPast(new Date(mifid.expiresAt))))
      ) {
        trackCommitmentMifid(campaign, amount, investorType);
      }
    }
  }
};

export const trackDonationBegin = trackCommitmentBegin;

export function trackDonationBasicProfile(campaign) {
  setUser();
  trackIntercomEvent(campaign, IC_ACTION_COMMITMENT_BASIC_PROFILE, {});
}

export const trackDonationComplete = trackCommitmentComplete;

export function trackDocumentDownload(campaign, document) {
  setUser();
  sendIntercomEvent(IC_ACTION_DOCUMENT_DOWNLOAD, {
    campaignId: campaign.id,
    campaignSystemTitle: campaign.systemTitle,
    documentId: document.id,
    documentName: document.title,
  });
}

export function trackException(description) {
  if (LOG_AWS_TRACK_EXCEPTION_ENABLED) {
    try {
      const logErrorUrl = makeApiUrl(LOG_ERROR_URL);
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      if (User.getUserToken()) {
        headers.append('Authorization', `Bearer ${User.getUserToken()}`);
      }
      window.fetch(logErrorUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          message: description,
          version: pkgInfo.version,
          details: {
            userId: User.getUserProp('id') || '',
            environment: process.env.NODE_ENV,
            page: {
              location: window.location.href,
              search: window.location.search,
              referrer: document.referrer,
            },
            navigator: window.navigator
              ? {
                userAgent: window.navigator.userAgent, // eslint-disable-line
                vendor: window.navigator.vendor, // eslint-disable-line
              } : null, // eslint-disable-line
            timestamp: Date.now(),
          },
        }),
      });
    } catch (e) {
      Logger.log([e, description]);
    }
  }
}

export function trackLog(level, message) {
  switch (level) {
    case 'error':
      Sentry.captureMessage(message, 'error');
      break;
    case 'warning':
      Sentry.captureMessage(message, 'warning');
      break;
    case 'info':
      Sentry.captureMessage(message, 'info');
      break;
    case 'debug':
      Sentry.captureMessage(message, 'debug');
      break;
    case 'critical':
      Sentry.captureMessage(message, 'critical');
      break;
    default:
      Sentry.captureMessage(message, level);
      break;
  }
}

export function trackError(error) {
  Sentry.captureException(error);
}

// ########################################
// ##### DEPRECATED TRACKER FUNCTIONS #####
// ########################################

export function trackDonationAmount(/* campaign, amount, investorType */) {
  setUser();
  // FacebookTracker.trackDonationAmount(campaign, { amount, investorType });
}

export function trackLeadApplicationBegin(type) {
  Logger.log(['0 TRACK APPLICATION STEP BEGIN', type]);
  // GoogleTracker.trackLeadApplicationBegin(type);
}

export function trackLeadApplicationStepUser(type, fiscalCode) {
  Logger.log(['1 TRACK APPLICATION STEP USER', type, fiscalCode]);
  // GoogleTracker.trackLeadApplicationStepUser(type, fiscalCode);
}

export function trackLeadApplicationStepCompany(type, vatNumber) {
  Logger.log(['2 TRACK APPLICATION STEP COMPANY', type, vatNumber]);
  // GoogleTracker.trackLeadApplicationStepCompany(type, vatNumber);
}

export function trackLeadApplicationStepLead(type, vatNumber) {
  Logger.log(['3 TRACK APPLICATION STEP PROJECT', type, vatNumber]);
  // GoogleTracker.trackLeadApplicationStepLead(type, vatNumber);
}

export function trackLeadApplicationComplete(type, vatNumber) {
  Logger.log(['9 TRACK APPLICATION STEP COMPLETE', type, vatNumber]);
  // GoogleTracker.trackLeadApplicationComplete(type, vatNumber);
}

export function trackApplicationBegin(type) {
  Logger.log(['0 TRACK APPLICATION STEP BEGIN', type]);
  // GoogleTracker.trackApplicationBegin(type);
}

export function trackApplicationStepUser(type, fiscalCode) {
  Logger.log(['1 TRACK APPLICATION STEP USER', type, fiscalCode]);
  // GoogleTracker.trackApplicationStepUser(type, fiscalCode);
}

export function trackApplicationStepCompany(type, vatNumber) {
  Logger.log(['2 TRACK APPLICATION STEP COMPANY', type, vatNumber]);
  // GoogleTracker.trackApplicationStepCompany(type, vatNumber);
}

export function trackApplicationStepProject(type, vatNumber) {
  Logger.log(['3 TRACK APPLICATION STEP PROJECT', type, vatNumber]);
  // GoogleTracker.trackApplicationStepProject(type, vatNumber);
}

export function trackApplicationStepRound(type, vatNumber) {
  Logger.log(['4 TRACK APPLICATION STEP ROUND', type, vatNumber]);
  // GoogleTracker.trackApplicationStepRound(type, vatNumber);
}

export function trackApplicationStepFinancials(type, vatNumber) {
  Logger.log(['5 TRACK APPLICATION STEP FINANCIALS', type, vatNumber]);
  // GoogleTracker.trackApplicationStepFinancials(type, vatNumber);
}

export function trackApplicationStepForecast(type, vatNumber) {
  Logger.log(['6 TRACK APPLICATION STEP FORECAST', type, vatNumber]);
  // GoogleTracker.trackApplicationStepForecast(type, vatNumber);
}

export function trackApplicationStepMarketing(type, vatNumber) {
  Logger.log(['7 TRACK APPLICATION STEP MARKETING', type, vatNumber]);
  // GoogleTracker.trackApplicationStepMarketing(type, vatNumber);
}

export function trackApplicationStepDocs(type, vatNumber) {
  Logger.log(['8 TRACK APPLICATION STEP DOCS', type, vatNumber]);
  // GoogleTracker.trackApplicationStepDocs(type, vatNumber);
}

export function trackApplicationComplete(type, vatNumber) {
  Logger.log(['9 TRACK APPLICATION STEP COMPLETE', type, vatNumber]);
  // GoogleTracker.trackApplicationComplete(type, vatNumber);
}

export function trackCookieChoices(cookieFunctional, cookieAnalytics, cookieThirdParty) { // eslint-disable-line prettier/prettier
  const cookieSwitch = s => `cookie_${s ? 'on' : 'off'}`;
  Logger.log(['TRACK COOKIE CHOICES', cookieFunctional, cookieAnalytics, cookieThirdParty, cookieSwitch]); // eslint-disable-line prettier/prettier

  // GoogleTracker.trackCustomEvent(cookieSwitch(cookieFunctional),'functional','Privacy'); // eslint-disable-line prettier/prettier
  // GoogleTracker.trackCustomEvent(cookieSwitch(cookieAnalytics),'analytics','Privacy'); // eslint-disable-line prettier/prettier
  // GoogleTracker.trackCustomEvent(cookieSwitch(cookieThirdParty),'third_party','Privacy'); // eslint-disable-line prettier/prettier
}

export const trackCampaignMobileSection = campaignMobileSection => {
  Logger.log(campaignMobileSection);
  // GoogleTracker.trackCustomEvent('click_campaign_section',campaignMobileSection,'Nav'); // eslint-disable-line prettier/prettier
};

export const trackCampaignOwnerKnowledgeSection = ownerSection => {
  Logger.log(ownerSection);
  // GoogleTracker.trackCustomEvent('click_knowledge_owner_section',ownerSection,'Nav'); // eslint-disable-line prettier/prettier
};

export const trackInvestorKnowledgeSection = investorSection => {
  Logger.log(investorSection);
  // GoogleTracker.trackCustomEvent('click_investor_section',investorSection,'Nav'); // eslint-disable-line prettier/prettier
};

export const trackChangeLanguage = (lang, userIsLogged) => {
  Logger.log(lang, userIsLogged);
  // GoogleTracker.trackCustomEvent(userIsLogged ? 'click_lang_profile' : 'click_lang_public',lang,'Nav'); // eslint-disable-line prettier/prettier
};

export function trackVideoStart(systemTitle, videoId, isVideoPitch) {
  Logger.log(systemTitle, videoId, isVideoPitch);
  setUser();
}

export function trackVideoEnd(systemTitle, videoId, isVideoPitch) {
  Logger.log(systemTitle, videoId, isVideoPitch);
  setUser();
}
