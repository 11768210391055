import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  appPageContainer: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}));
