/**
 *
 * Tooltip
 *
 */

import { useState } from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

function Tooltip({
  children,
  tooltipText,
  leaveTouchDelay = 4000,
  disabled = false,
  ...rest
}) {
  const [showTooltip, setShowTooltip] = useState(false);

  const openTooltip = () => {
    if (!disabled) {
      setShowTooltip(true);
    }
  };

  const closeTooltip = () => {
    if (!disabled) {
      setShowTooltip(false);
    }
  };

  const handleTooltip = () => {
    if (!disabled) {
      setShowTooltip(!showTooltip);
    }
  };

  return (
    <MuiTooltip
      title={tooltipText}
      open={showTooltip}
      leaveTouchDelay={leaveTouchDelay}
      onOpen={openTooltip}
      onClose={closeTooltip}
      onClick={handleTooltip}
      arrow
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
}

Tooltip.propTypes = {
  children: PropTypes.any.isRequired,
  tooltipText: PropTypes.any.isRequired,
  leaveTouchDelay: PropTypes.number,
  disabled: PropTypes.bool,
};

export default Tooltip;
