/**
 *
 * FadeInBottomUp
 *
 */

import { useState, useRef, useEffect, ReactNode } from 'react';
import { DEFAULT_ANIMATION_MARGIN } from 'utils/constants';
import { useStyle } from './FadeInBottomUp.style';

export interface FadeInBottomUpProps {
  children: ReactNode;
  delay?: string;
  rootMargin?: string;
  translateY?: string;
}

function FadeInBottomUp({ children, delay, rootMargin }: FadeInBottomUpProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyle({ delay });
  const [divClass, setDivClass] = useState(classes.start);

  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined') {
      const animationObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            const { isIntersecting, intersectionRatio } = entry;
            if (isIntersecting || intersectionRatio > 0) {
              const animatedContainer = entry.target;
              setDivClass(classes.end);

              animationObserver.unobserve(animatedContainer);
              animationObserver.disconnect();
            }
          });
        },
        {
          root: null,
          rootMargin: rootMargin || DEFAULT_ANIMATION_MARGIN,
        },
      );
      if (containerRef.current) {
        animationObserver.observe(containerRef.current);
      }
    } else {
      setDivClass(classes.end);
    }
  }, []);

  return (
    <div
      className={[divClass, classes.animatedContainer].join(' ')}
      ref={containerRef}
    >
      {children}
    </div>
  );
}

export default FadeInBottomUp;
