/*
 *
 * Header actions
 *
 */

import {
  LOAD_ADDITIONAL_HEADER,
  LOAD_ADDITIONAL_HEADER_SUCCESS,
  LOAD_ADDITIONAL_HEADER_ERROR,
} from './constants';

export function loadAdditionalHeader() {
  return {
    type: LOAD_ADDITIONAL_HEADER,
  };
}

export function loadAdditionalHeaderSuccess(headerLink) {
  return {
    type: LOAD_ADDITIONAL_HEADER_SUCCESS,
    headerLink,
  };
}

export function loadAdditionalHeaderError() {
  return {
    type: LOAD_ADDITIONAL_HEADER_ERROR,
  };
}
