/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = state => state.global || initialState;

const selectRouter = state => state.router;

const makeSelectUser = () =>
  createSelector(selectGlobal, globalState => globalState.user);

const makeSelectUserLogged = () =>
  createSelector(selectGlobal, globalState => globalState.userLogged);

const makeSelectLoading = () =>
  createSelector(selectGlobal, globalState => globalState.loading);

const makeSelectInitialized = () =>
  createSelector(selectGlobal, globalState => globalState.initialized);

const makeSelectError = () =>
  createSelector(selectGlobal, globalState => globalState.error);

const makeSelectLocation = () =>
  createSelector(selectRouter, routerState => routerState.location);

const makeSelectConsoleActiveCampaignLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActiveCampaignLoading,
  );

const makeSelectConsoleActiveCampaignError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActiveCampaignError,
  );

const makeSelectConsoleActiveCampaign = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActiveCampaign,
  );

const makeSelectConsoleActiveCompanyId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActiveCompanyId,
  );

const makeSelectConsoleActiveOrderId = () =>
  createSelector(selectGlobal, globalState => globalState.consoleActiveOrderId);

const makeSelectConsoleActiveUserId = () =>
  createSelector(selectGlobal, globalState => globalState.consoleActiveUserId);

const makeSelectConsoleActivePartnerId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActivePartnerId,
  );

const makeSelectConsoleActiveTestimonialId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActiveTestimonialId,
  );

const makeSelectConsoleActiveArticleId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActiveArticleId,
  );

const makeSelectConsoleActiveMessageId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActiveMessageId,
  );
const makeSelectConsoleActiveBannerId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActiveBannerId,
  );
const makeSelectConsoleActivePageId = () =>
  createSelector(selectGlobal, globalState => globalState.consoleActivePageId);

const makeSelectConsoleActivePressId = () =>
  createSelector(selectGlobal, globalState => globalState.consoleActivePressId);

const makeSelectConsoleActiveFaqId = () =>
  createSelector(selectGlobal, globalState => globalState.consoleActiveFaqId);

const makeSelectConsoleActiveSettingId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActiveSettingId,
  );

const makeSelectConsoleActiveIndustryId = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.consoleActiveIndustryId,
  );

export {
  selectGlobal,
  makeSelectUserLogged,
  makeSelectUser,
  makeSelectLoading,
  makeSelectLocation,
  makeSelectError,
  makeSelectInitialized,
  makeSelectConsoleActiveCampaignLoading,
  makeSelectConsoleActiveCampaignError,
  makeSelectConsoleActiveCampaign,
  makeSelectConsoleActiveCompanyId,
  makeSelectConsoleActiveOrderId,
  makeSelectConsoleActiveUserId,
  makeSelectConsoleActivePartnerId,
  makeSelectConsoleActiveTestimonialId,
  makeSelectConsoleActiveArticleId,
  makeSelectConsoleActiveMessageId,
  makeSelectConsoleActiveBannerId,
  makeSelectConsoleActivePageId,
  makeSelectConsoleActivePressId,
  makeSelectConsoleActiveFaqId,
  makeSelectConsoleActiveSettingId,
  makeSelectConsoleActiveIndustryId,
};
