import { makeStyles } from '@mui/styles';

export const useStyle = makeStyles(theme => ({
  chip: props => {
    const [color1, color2] = props.color.split('.');
    let color = theme.palette[color1];
    if (!color) {
      color = theme.palette.secondary;
    }
    if (props.variant === 'outlined') {
      return {
        backgroundColor: theme.palette.common.white,
        borderColor: color[color2] || color.main,
        color: color[color2] || color.main,
        borderRadius: props.radius,
      };
    }
    return {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: color[color2] || color.light,
      backgroundColor: color[color2] || color.light,
      borderRadius: props.radius,
      color: color.contrastText || 'inherit',

      '& .MuiChip-icon': {
        color: color[color2] || color.main,
      },
    };
  },
  badgeNew: {
    position: 'absolute',
    top: -5,
    right: 0,
    transform: 'translate(-0%, -50%)',
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.common.white,
    borderRadius: 50,
    padding: '2px 10px',
    fontSize: 9,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
}));
