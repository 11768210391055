/**
 *
 * Button
 *
 */

import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

export function Button({
  variant = 'primary',
  text,
  disabled = false,
  spinner = false,
  ...rest
}) {
  let variantButton = 'contained';
  if (variant === 'text') {
    variantButton = 'text';
  } else if (variant === 'secondary') {
    variantButton = 'outlined';
  }
  return (
    <LoadingButton
      variant={variantButton}
      startIcon={!spinner && rest.startIcon ? rest.startIcon : null}
      endIcon={!spinner && rest.endIcon ? rest.endIcon : null}
      disabled={disabled}
      loading={spinner}
      loadingIndicator={<CircularProgress color="inherit" size={25} />}
      disableElevation
      {...rest}
    >
      {text}
    </LoadingButton>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  spinner: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary', 'text']),
  size: PropTypes.oneOf(['large', 'medium']),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  colorText: PropTypes.string,
};

export default Button;
