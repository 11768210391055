/**
 *
 * Asynchronously loads the component for HomePage
 *
 */
import PageLoadingPlaceholder from 'components/PageLoadingPlaceholder';
import loadable from 'utils/loadable';

export default loadable(() => import('./index'), {
  fallback: <PageLoadingPlaceholder />,
});
