/*
 *
 * StepBasicProfile constants
 *
 */

export const GET_BASIC_PROFILE = 'app/StepBasicProfile/GET_BASIC_PROFILE';
export const GET_BASIC_PROFILE_SUCCESS =
  'app/StepBasicProfile/GET_BASIC_PROFILE_SUCCESS';
export const GET_BASIC_PROFILE_ERROR =
  'app/StepBasicProfile/GET_BASIC_PROFILE_ERROR';

export const SET_BASIC_PROFILE = 'app/StepBasicProfile/SET_BASIC_PROFILE';
export const SET_BASIC_PROFILE_SUCCESS =
  'app/StepBasicProfile/SET_BASIC_PROFILE_SUCCESS';
export const SET_BASIC_PROFILE_ERROR =
  'app/StepBasicProfile/SET_BASIC_PROFILE_ERROR';

export const CLEAN_BASIC_PROFILE = 'app/StepBasicProfile/CLEAN_BASIC_PROFILE';
