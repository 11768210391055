/**
 *
 * DonatePage
 *
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Logger from 'loglevel';
import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepContent,
} from '@mui/material';
import { Typography } from '@mamacrowd/ui-kit';
import StepBasicProfile from 'containers/StepBasicProfile';
import StepDonate from 'containers/StepDonate';
import { makeSelectStepDonateDonation } from 'containers/StepDonate/selectors';

import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import Section from 'components/Section';
import Box from 'components/Box';
import FormattedMessage from 'components/FormattedMessage';

import { trackDonationBegin, trackDonationBasicProfile } from 'utils/trackers';
import { ERROR_ROUTE, CAMPAIGN_DONATION_SUCCESS_ROUTE } from 'utils/constants';

import {
  makeSelectDonatePageCampaign,
  makeSelectDonatePageError,
  makeSelectDonatePageLoading,
  makeSelectDonatePageActiveStep,
} from './selectors';

import { loadCampaignInfo, setActiveStep, resetStepsState } from './actions';
import reducer from './reducer';
import saga from './saga';
import { useStyle } from './DonatePage.style';
import messages from './messages';

export function DonatePage({
  match,
  resetStepsStateData,
  loadCampaignInfoData,
  setActiveStepData,
  campaign,
  error,
  loading,
  history,
  activeStep,
  donation,
}) {
  useInjectReducer({ key: 'donatePage', reducer });
  useInjectSaga({ key: 'donatePage', saga });

  const { systemTitle } = match.params;

  useEffect(() => {
    if (loadCampaignInfoData && systemTitle) {
      loadCampaignInfoData(systemTitle);
    }
  }, [loadCampaignInfoData]);

  useEffect(() => {
    if (error) {
      history.push(ERROR_ROUTE);
    }
  }, [error]);

  useEffect(() => {
    if (donation && donation.id) {
      history.push(
        CAMPAIGN_DONATION_SUCCESS_ROUTE.replace(
          ':systemTitle',
          systemTitle,
        ).replace(':investmentOrderId', donation.id),
      );
    }
  }, [donation]);

  useEffect(
    () => () => {
      resetStepsStateData();
    },
    [],
  );

  // tracking
  useEffect(() => {
    if (activeStep >= 0 && campaign && campaign.systemTitle === systemTitle) {
      switch (activeStep) {
        case 0:
          trackDonationBegin(campaign, 1, {});
          break;
        case 1:
          trackDonationBasicProfile(campaign, {});
          break;
        default:
          Logger.error('activeStep not recognized', activeStep);
          break;
      }
    }
  }, [campaign, activeStep]);

  const handleStepClick = stepKey => {
    switch (stepKey) {
      case 'BasicProfileStep':
        setActiveStepData(0);
        break;
      default:
        Logger.error('Step not recognized', stepKey);
    }
  };

  const classes = useStyle();

  return campaign && !loading ? (
    <Section title={campaign.title}>
      <Box className={classes.subTitleContainer}>
        <Typography color="textSecondary" className={classes.subTitle}>
          <FormattedMessage messages={messages} messageId="donationSubTitle" />
        </Typography>
      </Box>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={classes.stepper}
      >
        <Step key="BasicProfileStep">
          <StepButton
            onClick={() => {
              handleStepClick('BasicProfileStep');
            }}
          >
            <StepLabel>
              <FormattedMessage
                messages={messages}
                messageId="basicProfileStep"
              />
            </StepLabel>
          </StepButton>

          <StepContent>
            <StepBasicProfile />
          </StepContent>
        </Step>
        <Step key="DonateStep">
          <StepLabel>
            <FormattedMessage messages={messages} messageId="donateStep" />
          </StepLabel>

          <StepContent>
            <StepDonate campaign={campaign} />
          </StepContent>
        </Step>
      </Stepper>
    </Section>
  ) : null;
}

DonatePage.propTypes = {
  loadCampaignInfoData: PropTypes.func.isRequired,
  setActiveStepData: PropTypes.func.isRequired,
  resetStepsStateData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  campaign: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  loading: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
  donation: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  campaign: makeSelectDonatePageCampaign(),
  error: makeSelectDonatePageError(),
  loading: makeSelectDonatePageLoading(),
  activeStep: makeSelectDonatePageActiveStep(),
  donation: makeSelectStepDonateDonation(),
});

function mapDispatchToProps(dispatch) {
  return {
    loadCampaignInfoData: systemTitle => {
      dispatch(loadCampaignInfo(systemTitle));
    },
    setActiveStepData: step => {
      dispatch(setActiveStep(step));
    },
    resetStepsStateData: () => {
      dispatch(resetStepsState());
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(DonatePage);
