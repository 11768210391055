import { takeLatest, call, put } from 'redux-saga/effects';
import Logger from 'loglevel';
import { request } from 'utils/request';
import makeApiUrl from 'utils/makeApiUrl';
import { ME_WEB_MESSAGE_URL } from 'utils/constants';
import { manageSagaError } from 'utils/errorManager';
import { LOAD_MESSAGE } from './constants';
import { loadMessageSuccess, loadMessageError } from './actions';

export function* getMessageArray() {
  const requestUrl = makeApiUrl(ME_WEB_MESSAGE_URL);

  try {
    const { messages } = yield call(request, requestUrl);
    Logger.log(['SAGAS', 'MESSAGE', 'SUCCESS', messages]);
    yield put(loadMessageSuccess(messages));
  } catch (err) {
    const error = yield manageSagaError('HeaderMessage', err);
    yield put(loadMessageError(error));
  }
}

export default function* headerMessageSaga() {
  yield takeLatest(LOAD_MESSAGE, getMessageArray);
}
