/*
 * DonateSuccessPage Messages
 *
 * This contains all the text for the DonateSuccessPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.DonateSuccessPage';

export default defineMessages({
  SEO_TITLE: {
    id: `${scope}.SEO_TITLE.message`,
    defaultMessage: 'Donation Success',
  },
  SEO_DESCRIPTION: {
    id: `${scope}.SEO_DESCRIPTION.message`,
    defaultMessage: 'Donation completed!',
  },
  congratulations: {
    id: `${scope}.congratulations.message`,
    defaultMessage: 'Congratulations!',
  },
  investmentSuccess: {
    id: `${scope}.investment_success.message`,
    defaultMessage: 'Donation successfully done',
  },
  lastStep: {
    id: `${scope}.last_step.message`,
    defaultMessage: 'Well done! Now you only need one last step:',
  },
  becameMember: {
    id: `${scope}.became_member.message`,
    defaultMessage: 'Make the transfer to donate at ',
  },
  transferTo: {
    id: `${scope}.tranfer_to.message`,
    defaultMessage: 'Transfer to: <b>{companyName}</b>',
  },
  iban: {
    id: `${scope}.iban.message`,
    defaultMessage: 'IBAN: <b>{companyBank}</b>',
  },
  swift: {
    id: `${scope}.swift.message`,
    defaultMessage: 'SWIFT: <b>{companySwift}</b>',
  },
  reason: {
    id: `${scope}.reason.message`,
    defaultMessage:
      'Reason: <b>Donation no. {investmentNumber} on {companyName}</b>',
  },
  total: {
    id: `${scope}.total.message`,
    defaultMessage: 'Import: <b>{amount}</b>',
  },
  confirmRecepit: {
    id: `${scope}.confirm_recepit.message`,
    defaultMessage:
      'You will receive an e-mail from us to confirm receipt of the transfer.',
  },
  supportMessage: {
    id: `${scope}.support_message.message`,
    defaultMessage: 'For any need please contact us at ',
  },
  goToLink: {
    id: `${scope}.go_to_link.message`,
    defaultMessage: 'Go to home page',
  },
});
