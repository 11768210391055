import { Components, Theme } from '@mui/material';
import { inputBaseClasses } from '@mui/material/InputBase';
import { buttonClasses } from '@mui/material/Button';

const MuiInputBaseRoot = (theme: Theme) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.black,

  // MUI SELECT FOCUS BG
  '& .MuiSelect-select': {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  // MUI INPUT BORDER FIXED WHEN HOVERING
  [`&:hover`]: {
    [`label:not(.${inputBaseClasses.error}):not(.${inputBaseClasses.disabled})`]:
      {
        color: theme.palette.primary.main,
      },
    [`.MuiInput-underline:not(.${inputBaseClasses.disabled}):not(${inputBaseClasses.error}):before`]:
      {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
  },

  // MUI INPUT DISABLED STYLE
  [`.${inputBaseClasses.disabled}`]: {
    backgroundColor: `#F2F2F280`, // opacity 0.8
    color: `#000000B3`,
  },
});

const MuiInputBase = (theme: Theme): Components['MuiInputBase'] => ({
  styleOverrides: {
    root: MuiInputBaseRoot(theme),
  },
});

const ConsoleMuiInputBase = (theme: Theme): Components['MuiInputBase'] => ({
  styleOverrides: {
    root: MuiInputBaseRoot(theme),
    input: {
      fontSize: 14,
      fontWeight: 400,
    },
  },
});

const MuiInput: Components['MuiInput'] = {
  styleOverrides: {
    input: {
      padding: '2px 0px 2px',
    },
  },
};

// Stile bottone di base
const MuiButtonRoot = (theme: Theme) => ({
  fontSize: 14,
  fontWeight: 400,
  [`&.${buttonClasses.contained}`]: {
    color: theme.palette.common.white,
    [`&.${buttonClasses.disabled}`]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  [`&.${buttonClasses.outlined}`]: {
    backgroundColor: theme.palette.common.white,
    [`&.${buttonClasses.disabled}`]: {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
    },
  },
  [`&.${buttonClasses.text}`]: {
    letterSpacing: 0.5,
    textTransform: 'none',
    fontWeight: 700,
    [`&.${buttonClasses.disabled}`]: {
      color: theme.palette.secondary.main,
    },
  },
  [`& > .${buttonClasses.icon}`]: {
    color: 'inherit',
  },
});

// Componente Button per web
const MuiButton = (theme: Theme): Components['MuiButton'] => ({
  styleOverrides: {
    root: {
      ...MuiButtonRoot(theme),
      height: 50,
      fontWeight: 600,
    },
  },
});

// Componente Button di console
const ConsoleMuiButton = (theme: Theme): Components['MuiButton'] => ({
  styleOverrides: {
    root: MuiButtonRoot(theme),
  },
});

const MuiFormControl = (theme: Theme): Components['MuiFormControl'] => ({
  styleOverrides: {
    root: {
      [`&:hover`]: {
        [`.MuiFormLabel-root:not(.${inputBaseClasses.error}):not(.${inputBaseClasses.disabled})`]:
          {
            color: theme.palette.primary.main,
          },
        [`.MuiInput-underline:not(.${inputBaseClasses.disabled}):not(${inputBaseClasses.error}):before`]:
          {
            borderBottom: `1px solid ${theme.palette.primary.main}`,
          },
      },
    },
  },
});

const MuiCheckbox: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      marginRight: 4,
      padding: 0,
    },
  },
};

const MuiSlider: Components['MuiSlider'] = {
  styleOverrides: {
    markLabel: {
      fontSize: 11,
    },
    markLabelActive: {
      fontSize: 11,
    },
  },
};

const MuiStepButton: Components['MuiStepButton'] = {
  styleOverrides: {
    root: {
      margin: 0,
      marginLeft: -8,
    },
  },
};

const MuiTableCell: Components['MuiTableCell'] = {
  styleOverrides: {
    sizeSmall: {
      padding: 4,
      '&:last-child': {
        paddingRight: 4,
      },
    },
  },
};

const MuiChip = (theme: Theme, isConsole = false): Components['MuiChip'] => {
  const styleOverrides = {
    colorPrimary: {
      backgroundColor: theme.palette.primary.light,
    },
    colorSecondary: {
      backgroundColor: theme.palette.secondary.light,
    },
  };
  return isConsole
    ? { styleOverrides: { ...styleOverrides, root: { height: 24 } } }
    : { styleOverrides };
};

const MuiAlert = (theme: Theme): Components['MuiAlert'] => ({
  styleOverrides: {
    filledSuccess: {
      backgroundColor: theme.palette.success.light,
    },
  },
});

export const components = (theme: Theme): Components => ({
  MuiCheckbox,
  MuiFormControl: MuiFormControl(theme),
  MuiInputBase: MuiInputBase(theme),
  MuiInput,
  MuiSlider,
  MuiStepButton,
  MuiButton: MuiButton(theme),
  MuiChip: MuiChip(theme),
  MuiAlert: MuiAlert(theme),
});

export const consoleComponents = (theme: Theme): Components => ({
  MuiCheckbox,
  MuiFormControl: MuiFormControl(theme),
  MuiInputBase: ConsoleMuiInputBase(theme),
  MuiInput,
  MuiSlider,
  MuiStepButton,
  MuiTableCell,
  MuiButton: ConsoleMuiButton(theme),
  MuiChip: MuiChip(theme, true),
  MuiAlert: MuiAlert(theme),
});
