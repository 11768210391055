import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import SessionStorage from 'utils/sessionStorage';

const CLEAN_DATA_ON_HISTORY_PUSH_KEY = 'historyTracking';

export const useCleanDataOnHistoryPush = (
  callbackFn: () => void,
  options: { active?: boolean } = { active: true },
) => {
  const { active } = options;
  const location = useLocation();
  const historyTracking =
    SessionStorage.getItem(CLEAN_DATA_ON_HISTORY_PUSH_KEY) || {};

  useLayoutEffect(() => {
    if (active && location && typeof callbackFn === 'function') {
      if (
        historyTracking &&
        historyTracking[location.pathname] &&
        historyTracking[location.pathname] !== location.key
      ) {
        callbackFn();
      }
    }

    return () => {
      if (location) {
        SessionStorage.setItem(CLEAN_DATA_ON_HISTORY_PUSH_KEY, {
          ...historyTracking,
          [location.pathname]: location.key,
        });
      }
    };
  }, [location, active, callbackFn]);
};

export default useCleanDataOnHistoryPush;
