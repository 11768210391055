import Logger from 'loglevel';
import { COOKIE_SETTINGS } from './constants';

export const localStorageSupported = (() => {
  try {
    localStorage.setItem('test', true);
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    Logger.error('Local storage not supported!', e);
    return false;
  }
})();

const LocalStorage = {
  setItem: (key, item) => {
    if (localStorageSupported) {
      if (typeof item === 'string') {
        localStorage.setItem(key, item);
      } else {
        localStorage.setItem(key, JSON.stringify(item));
      }
    } else {
      Logger.error('Local storage is not supported');
    }
  },
  getItem: key => {
    if (localStorageSupported) {
      const localStorageItem = localStorage.getItem(key);
      if (localStorageItem) {
        try {
          return JSON.parse(localStorageItem);
        } catch (e) {
          return localStorageItem;
        }
      }
    }
    return null;
  },
  removeItem: key => {
    if (localStorageSupported) {
      localStorage.removeItem(key);
    }
  },
};

export function setCookieSettingsToLocalStorage(cookieSettings) {
  const user = LocalStorage.getItem('user');
  if (user && user.token) {
    if (user.user.details) {
      user.user.details = {
        ...user.user.details,
        cookieChoices: cookieSettings,
      };
    } else {
      user.user = { ...user.user, details: { cookieChoices: cookieSettings } };
    }
    LocalStorage.setItem('user', user);
  }
  return LocalStorage.setItem(COOKIE_SETTINGS, cookieSettings);
}

export function getCookieSettingsFromLocalStorage() {
  let cs = LocalStorage.getItem(COOKIE_SETTINGS);
  if (
    cs &&
    (typeof cs.thirdParts !== 'undefined' || typeof cs.support === 'undefined')
  ) {
    cs = {
      analytics: cs.analytics,
      support: cs.thirdParts,
      functional: cs.functional,
      thirdParty: cs.thirdParts,
    };
    setCookieSettingsToLocalStorage(cs);
  }
  return cs;
}

export default LocalStorage;
