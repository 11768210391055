import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  becameMemberText: {
    fontWeight: 500,
    fontSize: 28,
  },
  companyLogo: {
    maxWidth: 50,
    marginLeft: 10,
  },
  companyValues: {
    padding: '5px 0',
  },
  remindMessageText: {
    lineHeight: '28px',
    display: 'contents',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  },
  link: {
    textDecoration: 'none',
  },
  InvestmentLink: {
    display: 'block',
  },
}));
