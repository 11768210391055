import { call } from 'redux-saga/effects';

import makeApiUrl from 'utils/makeApiUrl';
import { request } from 'utils/request';
import User from 'utils/User';

import { Campaign, CampaignStatus } from 'types/Campaign';

import { ME_INFO_CAMPAIGN_URL } from './constants';

export default function* getCampaignUserInfo(
  campaigns: Campaign[] = [],
  statuses: CampaignStatus[] | undefined = [],
) {
  if (User.isLogged() && campaigns.length) {
    const url = makeApiUrl(ME_INFO_CAMPAIGN_URL);
    const campaignIdsQS = campaigns.map(c => ({
      key: 'campaignIds',
      value: c.id,
    }));
    const statusesQS = statuses.map(s => ({ key: 'statuses', value: s }));
    const options = {
      repeatedQSParams: [...campaignIdsQS, ...statusesQS],
    };
    const campaignsUserInfo = yield call(request, url, options);
    return campaigns.map(c => ({
      ...c,
      userInfo: campaignsUserInfo[c.id] || null,
    }));
  }
  return campaigns;
}
