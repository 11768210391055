/**
 *
 * Box
 *
 */

import MUIBox, { BoxProps as MUIBoxProps } from '@mui/material/Box';

export interface BoxProps extends MUIBoxProps {}

export default function Box({ ...props }: BoxProps) {
  return <MUIBox {...props} />;
}
