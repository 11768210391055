/*
 *
 * CookiesBanner constants
 *
 */

export const OPEN_COOKIES_SETTINGS = 'app/CookiesBanner/OPEN_COOKIES_SETTINGS';
export const CLOSE_COOKIES_SETTINGS =
  'app/CookiesBanner/CLOSE_COOKIES_SETTINGS';
export const SET_COOKIES_SETTINGS = 'app/CookiesBanner/SET_COOKIES_SETTINGS';
export const SET_COOKIES_SETTINGS_SUCCESS =
  'app/CookiesBanner/SET_COOKIES_SETTINGS_SUCCESS';
export const SET_COOKIES_SETTINGS_ERROR =
  'app/CookiesBanner/SET_COOKIES_SETTINGS_ERROR';
