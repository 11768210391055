import { CommonColors, PaletteOptions, TypeText } from '@mui/material';
import { getContrastRatio } from '@mui/material/styles';

const text: TypeText = {
  primary: '#000000',
  secondary: '#B9B9B9',
  disabled: 'rgba(0,0,0,0.38)',
  // CUSTOM
  footerLink: '#949494',
  darkGray: '#777777',
};

const common: CommonColors = {
  black: '#000000',
  white: '#FFFFFF',
  // CUSTOM
  alternateRow: '#F5F5F5',
  borderColor: '#D8D8D8',
  bgHeaderDev: '#FFFFBE',
  bgPlaceholder: '#F3F3F3',
  bgPortfolioRow: '#F9F8F8',
};

export const palette: PaletteOptions = {
  mode: 'light',
  text,
  common,
  primary: {
    dark: '#368485',
    main: '#4EBDBF',
    light: '#DDF4F5',
  },
  secondary: {
    dark: '#CCCCCC',
    main: '#949494',
    light: '#E8E8E8',
    contrastText: '#000000',
  },
  success: {
    dark: '#00986a',
    main: '#10BF56',
    light: '#C1F1E0',
  },
  error: {
    dark: '#E85644',
    main: '#FF0000',
    light: '#EECAC5',
    contrastText: '#FFFFFF',
  },
  warning: {
    dark: '#F8EEE8',
    main: '#FF985A',
    light: '#F6CFB6',
  },
  info: {
    dark: '#007ac1',
    main: '#006ADC',
    light: '#EDF6FF',
  },
  // CUSTOM
  earlyBird: {
    dark: '#007ac1',
    main: '#006adc',
    light: '#edf6ff',
    contrastText: getContrastRatio('#006ADC', '#fff') > 4.5 ? '#fff' : '#111',
  },
  youtube: {
    light: '#ff6666',
    main: '#f00000',
    dark: '#c80000',
  },
  background: {
    default: '#ffffff',
    primary: '#EDF8F8',
  },
  spid: {
    main: '#0066CC',
    dark: '#00478F',
  },
  webinar: {
    dark: '#9DBBC7',
    main: '#9DC7BD',
    light: '#95B0B1',
  },
  closing: {
    main: '#F0BF10',
  },
  closedFunded: {
    main: '#10BF56',
  },
  exit: {
    main: '#B638CA',
    contrastText: getContrastRatio('#B638CA', '#fff') > 4.5 ? '#fff' : '#111',
  },
  equity: {
    light: '#E5FFFD',
    main: '#59D3C9',
    contrastText: '#fff',
  },
  realEstate: {
    light: '#F8EEE8',
    main: '#FF985A',
    contrastText: '#fff',
  },
  equityFund: {
    light: '#EDF1FF',
    main: '#5B7FFF',
  },
  campaignOffline: {
    light: '#FEECFB',
    main: '#FF21CE',
  },
  restricted: {
    light: '#000000',
    main: '#000000',
    contrastText: '#000000',
  },
};
