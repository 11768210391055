/*
 *
 * StepDonate actions
 *
 */

import {
  DONATE,
  DONATE_SUCCESS,
  DONATE_ERROR,
  CLEAN_DONATION,
} from './constants';

export function donate(campaignId, donation) {
  return {
    type: DONATE,
    campaignId,
    donation,
  };
}

export function donateSuccess(donation) {
  return {
    type: DONATE_SUCCESS,
    donation,
  };
}

export function donateError(error) {
  return {
    type: DONATE_ERROR,
    error,
  };
}

export function cleanDonation() {
  return {
    type: CLEAN_DONATION,
  };
}
