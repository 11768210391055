import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles({
  textField: props => ({
    width: '100%',
    '& input': {
      fontSize: props.fontSize,
      fontFamily: props.code ? 'monospace' : 'inherit',
    },
    '& textarea': {
      minHeight: 29,
      fontSize: props.fontSize,
      fontFamily: props.code ? 'monospace' : 'inherit',
    },
    '& .MuiFormHelperText-root': {
      margin: '10px 0 0',
    },
  }),
});
