import isLengthMax from 'utils/Validations/isLengthMax';

export default (value, isItalian, isRequired = true) => {
  const regExp = /(^[A-Za-z]{2})(\d{5,7}$)/;
  if (isRequired || value) {
    if (isItalian) {
      return regExp.test(value);
    }
    return value && isLengthMax(value, 20);
  }

  return true;
};
