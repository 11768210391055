import hasNumbers from 'utils/Validations/hasNumbers';
import hasOneLowerCase from 'utils/Validations/hasOneLowerCase';
import hasOneUpperCase from 'utils/Validations/hasOneUpperCase';
import isBetween from 'utils/Validations/isLengthBetween';

export default value => {
  if (
    hasNumbers(value) &&
    hasOneLowerCase(value) &&
    hasOneUpperCase(value) &&
    isBetween(value, 12, 100)
  )
    return true;
  return false;
};
