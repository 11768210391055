/**
 *
 * Footer
 *
 */

import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import LocaleToggle from 'containers/LocaleToggle';

import Box from 'components/Box';
import Logo from 'components/Logo';
import SocialMedia from 'components/SocialMedia';
import FormattedMessage from 'components/FormattedMessage';

import {
  mamacrowdSection,
  crowdfundingServicesSection,
  supportSection,
} from './FooterLinkList';

import FooterSectionDesktop from './FooterSectionDesktop';
import FooterLastSection from './FooterLastSection';
import { useStyle } from './Footer.style';
import messages from './messages';

function Footer() {
  const classes = useStyle();
  const theme = useTheme();
  const isOverMD = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box className={classes.footer}>
      <Box className={classes.footerSectionsContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Logo variant="black" maxWidth={isOverMD ? 240 : 280} />
              </Grid>
              <Grid item xs={12}>
                <SocialMedia variant="black" />
              </Grid>
              {process.env.ENABLE_MULTILANGUAGE === 'true' ? (
                <Grid item xs={12}>
                  <LocaleToggle
                    color={theme.palette.text.primary}
                    textColor={theme.palette.text.primary}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FooterSectionDesktop
              links={mamacrowdSection.links}
              title={
                <FormattedMessage
                  messages={messages}
                  messageId={mamacrowdSection.translationIdTitle}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FooterSectionDesktop
              links={crowdfundingServicesSection.links}
              title={
                <FormattedMessage
                  messages={messages}
                  messageId={crowdfundingServicesSection.translationIdTitle}
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FooterSectionDesktop
              links={supportSection.links}
              title={
                <FormattedMessage
                  messages={messages}
                  messageId={supportSection.translationIdTitle}
                />
              }
            />
          </Grid>
        </Grid>
        <FooterLastSection />
      </Box>
    </Box>
  );
}

export default Footer;
