export default (firstName, lastName, fiscalCode) => {
  const codLastName = `${extractConsonants(lastName)}${extractVowels(
    lastName,
  )}XXX`;
  const lastNameFc = codLastName.substr(0, 3).toUpperCase();

  let codFirstName = extractConsonants(firstName);
  if (codFirstName.length >= 4) {
    codFirstName =
      codFirstName.charAt(0) + codFirstName.charAt(2) + codFirstName.charAt(3);
  } else {
    codFirstName += `${extractVowels(firstName)}XXX`;
    codFirstName = codFirstName.substr(0, 3);
  }
  const firstNameFc = codFirstName.toUpperCase();

  return (
    fiscalCode.substr(0, 6).toUpperCase() === `${lastNameFc}${firstNameFc}`
  );
};

export function extractVowels(str) {
  return str.replace(/[^AEIOU]/gi, '');
}
export function extractConsonants(str) {
  return str.replace(/[^BCDFGHJKLMNPQRSTVWXYZ]/gi, '');
}
