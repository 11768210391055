/*
 *
 * CookiesBanner reducer
 *
 */
import produce from 'immer';
import {
  CLOSE_COOKIES_SETTINGS,
  OPEN_COOKIES_SETTINGS,
  SET_COOKIES_SETTINGS,
  SET_COOKIES_SETTINGS_ERROR,
  SET_COOKIES_SETTINGS_SUCCESS,
} from './constants';

export const initialState = {
  showCookiesBanner: false,
};

/* eslint-disable default-case, no-param-reassign */
const cookiesBannerReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case OPEN_COOKIES_SETTINGS:
        draft.showCookiesBanner = true;
        break;
      case CLOSE_COOKIES_SETTINGS:
        draft.showCookiesBanner = false;
        break;
      case SET_COOKIES_SETTINGS:
        // draft.showCookiesBanner = true;
        break;
      case SET_COOKIES_SETTINGS_SUCCESS:
        draft.showCookiesBanner = false;
        break;
      case SET_COOKIES_SETTINGS_ERROR:
        draft.showCookiesBanner = false;
        break;
    }
  });

export default cookiesBannerReducer;
