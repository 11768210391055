/**
 *
 * Section
 *
 */

import { ReactNode } from 'react';
import { Typography } from '@mamacrowd/ui-kit';
import { Grid, Theme, useMediaQuery } from '@mui/material';

import Link from 'basic/Link';
import FadeInBottomUp from 'components/Animations/FadeInBottomUp';
import FadeInRightToLeft from 'components/Animations/FadeInRightToLeft';

import { useStyle } from './Section.style';

export type SectionProps = {
  children: ReactNode;
  className?: string;
  title?: ReactNode;
  link?: ReactNode;
  linkTo?: string;
  noLinkBottom?: boolean;
  subTitle?: ReactNode;
  isHighlight?: boolean;
  button?: ReactNode;
  enableAnimations?: boolean;
};

function Section({
  children,
  className = '',
  title = null,
  subTitle = null,
  button = null,
  link = null,
  linkTo = undefined,
  noLinkBottom = false,
  isHighlight = false,
  enableAnimations = false,
  ...rest
}: SectionProps) {
  const isUnderSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md'),
  );
  const classes = useStyle({ isHighlight });

  const linkJSX = (
    <Link to={linkTo} arrow>
      {link}
    </Link>
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.pageSection}
    >
      <Grid
        item
        xs={12}
        className={`${classes.pageContainer} ${className}`}
        {...rest}
      >
        <Grid container>
          {(title || link) && (
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
                className={classes.sectionHeaderTitle}
              >
                {title ? (
                  <Grid item xs={12} md="auto">
                    {enableAnimations ? (
                      <FadeInBottomUp translateY="36">
                        <Typography variant="h2" color="textPrimary">
                          {title}
                        </Typography>
                      </FadeInBottomUp>
                    ) : (
                      <Typography variant="h2" color="textPrimary">
                        {title}
                      </Typography>
                    )}
                  </Grid>
                ) : null}
                {(link || button) && (
                  <Grid item>
                    <Grid container>
                      {link ? (
                        <Grid
                          item
                          xs={12}
                          md={button ? 6 : 12}
                          className={classes.sectionHeaderLinkDesktop}
                        >
                          {enableAnimations ? (
                            <FadeInRightToLeft
                              translateX="30"
                              delay={isUnderSM ? '0.3s' : '0.5s'}
                            >
                              {linkJSX}
                            </FadeInRightToLeft>
                          ) : (
                            linkJSX
                          )}
                        </Grid>
                      ) : null}
                      {button ? (
                        <Grid item xs={12} md={link ? 6 : 12}>
                          {button}
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          {subTitle ? (
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="flex-start"
                className={classes.sectionHeaderSubTitle}
              >
                <Grid item xs={12}>
                  {enableAnimations ? (
                    <FadeInBottomUp
                      translateY="30"
                      delay={isUnderSM ? '0.3s' : '0.5s'}
                    >
                      <Typography color="textPrimary">{subTitle}</Typography>
                    </FadeInBottomUp>
                  ) : (
                    <Typography color="textPrimary">{subTitle}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            {children}
          </Grid>
          {link && !noLinkBottom ? (
            <Grid item xs={12} md={6}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                className={classes.sectionHeaderLinkMobile}
              >
                <Grid item>
                  {enableAnimations ? (
                    <FadeInBottomUp
                      translateY="30"
                      delay={isUnderSM ? '0.3s' : '0.5s'}
                    >
                      {linkJSX}
                    </FadeInBottomUp>
                  ) : (
                    linkJSX
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Section;
