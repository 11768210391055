/**
 *
 * AuthLayout
 *
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useStyle } from './AuthLayout.style';
import AuthLayoutFooter from './AuthLayoutFooter/AuthLayoutFooter';
import CookiesBanner from 'containers/CookiesBanner';

export function AuthLayout({ children }) {
  const classes = useStyle();

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-end"
      className={classes.liteContainer}
    >
      <Grid item xs={12}>
        {children}
      </Grid>
      <Grid item xs={12} align="center">
        <AuthLayoutFooter />
      </Grid>
      <CookiesBanner />
    </Grid>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AuthLayout;
