const stringify = json =>
  process.env.NODE_ENV === 'production'
    ? JSON.stringify(json)
    : JSON.stringify(json, null, 2);

const getSchemaGraph = schema =>
  stringify({
    '@context': 'http://schema.org',
    '@graph': [schema],
  });

const formatAvailability = status => {
  switch (status) {
    case 'published':
      return 'https://schema.org/InStock';
    case 'closed_succesfully':
      return 'https://schema.org/SoldOut';
    case 'closed_not_ok':
      return 'https://schema.org/Discontinued';
    default:
      return 'https://schema.org/OutOfStock';
  }
};

export function Product(campaign, campaignMetrics) {
  if (
    !campaign ||
    !campaign.company ||
    !campaignMetrics ||
    !campaignMetrics.minQuotaValue
  )
    return null;

  const schema = {
    '@type': 'Product',
    '@id': `${window.location.origin}/project/${campaign.systemTitle}`,
    name: campaign.title,
    image: campaign.company.logoImgUrl,
    sku: campaign.id,
    logo: {
      '@type': 'ImageObject',
      width: 512,
      height: 512,
      url: 'http://d2rf4y8woysqse.cloudfront.net/logo/mamaicon.png',
    },
    description: campaign.info.abstract,
    brand: {
      '@type': 'Brand',
      name: campaign.title,
    },
    offers: {
      '@type': 'AggregateOffer',
      lowPrice: campaignMetrics.minQuotaValue,
      priceCurrency: 'EUR',
      validFrom: campaign.openDate,
      validThrough: campaign.closeDate,
      priceValidUntil: campaign.closeDate,
      availability: formatAvailability(campaign.status),
      seller: {
        '@type': 'Organization',
        name: campaign.company.businessName,
      },
    },
  };

  if (campaign.company?.logoImgUrl) {
    schema.brand.logo = {
      '@type': 'ImageObject',
      width: 300,
      height: 300,
      url: campaign.company.logoImgUrl,
    };

    schema.offers.seller.logo = {
      '@type': 'ImageObject',
      url: campaign.company.logoImgUrl,
    };
  }

  return getSchemaGraph(schema);
}

export const Organization = () => {
  const schema = {
    '@type': 'Organization',
    name: 'Mamacrowd',
    legalName: 'Mamacrowd Srl',
    url: 'https://mamacrowd.com',
    logo: {
      '@type': 'ImageObject',
      width: 512,
      height: 512,
      url: 'https://mmc-asset.s3.eu-central-1.amazonaws.com/logo/mamaicon.png',
    },
    sameAs: [
      'https://www.facebook.com/mamacrowdcom/',
      'https://twitter.com/mamacrowdcom',
      'https://www.instagram.com/mamacrowdcom/',
      'https://www.linkedin.com/company/mamacrowd',
      'https://www.youtube.com/channel/UCSp9JIV6Er98NmVmZh593VQ',
    ],
    founder: {
      '@type': 'Person',
      familyName: 'Giudici',
      givenName: 'Dario',
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Via Timavo, 34',
      addressLocality: 'Milano',
      addressRegion: 'MI',
      postalCode: '20124',
      addressCountry: 'Italy',
    },
  };

  return getSchemaGraph(schema);
};

export const SearchBox = () => {
  const schema = {
    '@type': 'WebSite',
    name: 'Mamacrowd',
    url: 'https://mamacrowd.com',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://mamacrowd.com/search?q={search_term_string}',
      'query-input': 'required name=search_term_string',
    },
  };

  return getSchemaGraph(schema);
};

export const SiteNavigation = () => {
  const schema = {
    '@type': 'ItemList',
    itemListElement: [
      {
        '@type': 'SiteNavigationElement',
        position: 1,
        name: 'Tutti i progetti',
        description: 'Scopri tutti i progetti',
        url: 'https://mamacrowd.com/projects',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 2,
        name: 'Accedi',
        description: 'Accedi',
        url: 'https://mamacrowd.com/login',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 3,
        name: 'News',
        description: 'News',
        url: 'https://mamacrowd.com/news',
      },
      {
        '@type': 'SiteNavigationElement',
        position: 4,
        name: 'Chi siamo',
        description: 'Scopri chi è Mamacrowd e tutto il suo team',
        url: 'https://mamacrowd.com/_/chi-siamo',
      },
    ],
  };

  return getSchemaGraph(schema);
};

export function NewsArticle(news) {
  if (!news) return null;

  const schema = {
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${window.location.origin}/news/${news.route}`,
    },
    name: news.title,
    datePublished: news.createdAt,
    keywords: news.metaKeywords,
    headline: news.metaDescription && news.metaDescription.substr(0, 110),
    image: news.imgUrl,
    author: {
      '@type': 'Organization',
      name: 'Mamacrowd',
    },
    publisher: {
      '@type': 'Organization',
      name: 'Mamacrowd',
      logo: {
        '@type': 'ImageObject',
        width: 512,
        height: 512,
        url: 'https://mmc-asset.s3.eu-central-1.amazonaws.com/logo/mamaicon.png',
      },
    },
  };

  return getSchemaGraph(schema);
}

export function ProjectList(projects) {
  if (!projects && !projects.length) return null;

  const schema = {
    '@type': 'ItemList',
    itemListElement: projects.map((p, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      url: `${window.location.origin}/project/${p.systemTitle}`,
    })),
  };

  return getSchemaGraph(schema);
}

export function NewsList(news) {
  if (!news && !news.length) return null;

  const schema = {
    '@type': 'ItemList',
    itemListElement: news.map((n, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      url: `${window.location.origin}/news/${n.route}`,
    })),
  };

  return getSchemaGraph(schema);
}
