/**
 *
 * EmptyState
 *
 */
import { ReactNode } from 'react';

import FormattedMessage from 'components/FormattedMessage';

import NoProjectsWithoutType from 'images/EmptyStates/no_projects.svg';
import NoProjectsRE from 'images/EmptyStates/no_projects_re.svg';
import NoProjectsSUP from 'images/EmptyStates/no_projects_sup.svg';
import NoProjectsSearch from 'images/EmptyStates/no_projects_search.svg';
import { CampaignType } from 'types/Campaign';

import messages from './messages';
import { useStyle } from './EmptyState.style';
import { Typography } from '@mamacrowd/ui-kit';
import { Grid } from '@mui/material';
import Fade from 'components/Animations/Fade';

type EmptyStateProps = {
  types: CampaignType[];
  query?: string;
};

export function EmptyState({ types, query }: EmptyStateProps) {
  const classes = useStyle();
  let titleJSX: ReactNode = (
    <FormattedMessage messages={messages} messageId="title" />
  );
  let messageJSX: ReactNode = (
    <FormattedMessage messages={messages} messageId="message" />
  );

  let imgJSX: ReactNode = <img src={NoProjectsWithoutType} />;

  if (query) {
    imgJSX = <img src={NoProjectsSearch} />;
    titleJSX = <FormattedMessage messages={messages} messageId="searchTitle" />;
    messageJSX = (
      <FormattedMessage messages={messages} messageId="searchMessage" />
    );
  } else if (types.includes(CampaignType.equity) && types.length === 1) {
    imgJSX = <img src={NoProjectsSUP} />;
    titleJSX = <FormattedMessage messages={messages} messageId="supTitle" />;
  } else if (
    types.includes(CampaignType.realEstate) &&
    types.includes(CampaignType.realEstateIncome) &&
    types.length === 2
  ) {
    // must include also real_estate_income_types
    imgJSX = <img src={NoProjectsRE} />;
    titleJSX = <FormattedMessage messages={messages} messageId="reTitle" />;
  }

  return (
    <Fade delay="0.4s">
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>{imgJSX}</Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.title}
            textAlign="center"
          >
            {titleJSX}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            color="textPrimary"
            textAlign="center"
            className={classes.text}
          >
            {messageJSX}
          </Typography>
        </Grid>
      </Grid>
    </Fade>
  );
}

export default EmptyState;
