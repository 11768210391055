/* eslint-disable prettier/prettier */
/*
 *
 * CampaignsPage constants
 *
 */

export const LOAD_ACTIVE_CAMPAIGNS = 'app/CampaignsPage/LOAD_ACTIVE_CAMPAIGNS';
export const LOAD_ACTIVE_CAMPAIGNS_ERROR = 'app/CampaignsPage/LOAD_ACTIVE_CAMPAIGNS_ERROR';
export const LOAD_ACTIVE_CAMPAIGNS_SUCCESS = 'app/CampaignsPage/LOAD_ACTIVE_CAMPAIGNS_SUCCESS';
export const LOAD_CLOSED_CAMPAIGNS = 'app/CampaignsPage/LOAD_CLOSED_CAMPAIGNS';
export const LOAD_CLOSED_CAMPAIGNS_ERROR = 'app/CampaignsPage/LOAD_CLOSED_CAMPAIGNS_ERROR';
export const LOAD_CLOSED_CAMPAIGNS_SUCCESS = 'app/CampaignsPage/LOAD_CLOSED_CAMPAIGNS_SUCCESS';
export const FILTER_ACTIVE_CAMPAIGNS = 'app/CampaignsPage/FILTER_ACTIVE_CAMPAIGNS';
export const FILTER_CLOSED_CAMPAIGNS = 'app/CampaignsPage/FILTER_CLOSED_CAMPAIGNS';
export const CLEAR_ACTIVE_CAMPAIGNS = 'app/CampaignsPage/CLEAR_ACTIVE_CAMPAIGNS';
export const CLEAR_CLOSED_CAMPAIGNS = 'app/CampaignsPage/CLEAR_CLOSED_CAMPAIGNS';
export const CLEAR_CAMPAIGNS = 'app/CampaignsPage/CLEAR_CAMPAIGNS';
