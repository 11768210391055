/*
 *
 * LanguageProvider reducer
 *
 */

import produce from 'immer';

import LocalStorage from 'utils/localStorage';
import User from 'utils/User';

import { CHANGE_LOCALE } from './constants';
import { DEFAULT_LOCALE } from '../../i18n';
const regPathnameLang = /^\/(it|en)\//;
const [, pathLang] = window.location.pathname.match(regPathnameLang)
  ? window.location.pathname.match(regPathnameLang)
  : [undefined, undefined];

export const initialState = {
  locale: process.env.ENABLE_MULTILANGUAGE === 'true' // eslint-disable-line
      ? (typeof User.isLogged === 'function' && User.isLogged() && User.getUserProp('language')) || // eslint-disable-line
      pathLang || // eslint-disable-line
      LocalStorage.getItem('user-language') || // eslint-disable-line
      DEFAULT_LOCALE // eslint-disable-line
    : DEFAULT_LOCALE, // eslint-disable-line
};

/* eslint-disable default-case, no-param-reassign */
const languageProviderReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_LOCALE:
        draft.locale = action.locale;
        break;
    }
  });

export default languageProviderReducer;
