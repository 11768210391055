import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles({
  subTitleContainer: {
    paddingBottom: 20,
    width: '100%',
  },
  subTitle: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  stepper: {
    width: '100%',
    padding: 0,
  },
});
