/**
 *
 * MeLayout
 *
 */

import PropTypes from 'prop-types';
import Header from 'containers/Header';
import Footer from 'components/Footer';
// import ScrollToTop from 'components/ScrollToTop';
import CheckValidToken from 'components/CheckValidToken';
import Box from 'components/Box';
import CookiesBanner from 'containers/CookiesBanner';
import Intercom from 'components/Intercom';

import { useStyle } from './MeLayout.style';

export function MeLayout({ children }) {
  const classes = useStyle();

  return (
    <>
      <Header />
      <Box className={classes.pageContainer}>{children}</Box>
      <Footer />
      <CookiesBanner />
      <CheckValidToken />
      <Intercom />
    </>
  );
}

MeLayout.propTypes = {
  children: PropTypes.any.isRequired,
  isPortfolio: PropTypes.bool,
  isPortfolioDetail: PropTypes.bool,
};

export default MeLayout;
