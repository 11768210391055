/**
 *
 * UserMenu
 *
 */

import {
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  useMediaQuery,
} from '@mui/material';
import BookmarkBorder from '@mui/icons-material/BookmarkBorder';
import ArticleOutlined from '@mui/icons-material/ArticleOutlined';
import ComputerIcon from '@mui/icons-material/Computer';
import Favorite from '@mui/icons-material/Favorite';

import HeaderLink from 'components/HeaderLink';
import LogoutButton from 'containers/LogoutButton/index';
import FormattedMessage from 'components/FormattedMessage';
import { getAccountLinks } from 'containers/Header/HeaderLinks';
import messages from 'containers/Header/messages';

import Dashboard from 'images/UserDropdown/dashboard.svg';
import Portfolio from 'images/UserDropdown/portfolio.svg';
import Profile from 'images/UserDropdown/profile.svg';
import Projects from 'images/UserDropdown/projects.svg';

import { useStyle } from './UserMenu.style';
import Chip from 'basic/Chip';

export interface UserMenuProps {
  isInSec?: boolean;
  isLogged?: boolean;
  isPowerUser?: boolean;
}

export function UserMenu({
  isInSec = false,
  isLogged = false,
  isPowerUser = false,
}: UserMenuProps) {
  const isOverMD = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
  const classes = useStyle();

  const getIcon = icon => {
    switch (icon) {
      case 'Portfolio':
        return <img src={Portfolio} alt="Portfolio" />;
      case 'Watchlist':
        return <Favorite color="primary" style={{ fontSize: 15 }} />;
      case 'Profile':
        return <img src={Profile} alt="Profile" />;
      case 'Dashboard':
        return <img src={Dashboard} alt="Dashboard" />;
      case 'MyCampaign':
        return <img src={Projects} alt="MyCampaign" />;
      case 'MyCompanyReports':
        return <ArticleOutlined color="primary" style={{ fontSize: 15 }} />;
      case 'Sim':
        return <BookmarkBorder color="primary" style={{ fontSize: 15 }} />;
      case 'Monitoring':
        return <ComputerIcon color="primary" style={{ fontSize: 15 }} />;
      default:
        return null;
    }
  };

  const loggedLinksJSX = getAccountLinks().logged.map(item => {
    if (!item.hidden) {
      return isOverMD || isInSec ? (
        <MenuItem key={item.translationId} style={{ minHeight: 'auto' }}>
          <ListItemIcon>{getIcon(item.icon)}</ListItemIcon>
          <ListItemText>
            <HeaderLink item={item} messages={messages} />
          </ListItemText>
        </MenuItem>
      ) : (
        <Grid
          item
          className={classes.linkContainer}
          key={item.translationId}
          style={{ position: 'relative' }}
        >
          {isPowerUser && item.translationId === 'profileLink' && (
            <Chip
              variant="outlined"
              color="info"
              label={
                <FormattedMessage messages={messages} messageId="powerUser" />
              }
              icon={null}
              className={classes.powerUserChip}
            />
          )}
          <HeaderLink item={item} messages={messages} />
        </Grid>
      );
    }
    return null;
  });

  const unloggedLinksJSX = getAccountLinks().unlogged.map(item => (
    <Grid item className={classes.linkContainer} key={item.translationId}>
      <HeaderLink item={item} messages={messages} />
    </Grid>
  ));

  return isLogged ? (
    <>
      {loggedLinksJSX}
      <LogoutButton isInSec={isInSec} />
    </>
  ) : (
    <>{unloggedLinksJSX}</>
  );
}

export default UserMenu;
