import LinkedInTag from 'utils/linkedIn';
import ENUMCampaignStatus from 'utils/Enums/CampaignStatus';
import { getCookieThirdParty } from 'utils/cookie';

const linkedInTag = process.env.LINKEDIN_TAG;
const linkedInConversionRegister = process.env.LINKEDIN_TAG_CONVERSION_REGISTER;
const linkedInConversionFollow = process.env.LINKEDIN_TAG_CONVERSION_FOLLOW;
const linkedInConversionInterest = process.env.LINKEDIN_TAG_CONVERSION_INTEREST;
const linkedInConversionCommitmentBegin =
  process.env.LINKEDIN_TAG_CONVERSION_COMMITMENT_BEGIN;
const linkedInConversionCommitmentSpecifyAmount =
  process.env.LINKEDIN_TAG_CONVERSION_COMMITMENT_SPECIFY_AMOUNT;
const linkedInConversionPurchase = process.env.LINKEDIN_TAG_CONVERSION_PURCHASE;

function getCampaignTrackers(campaign) {
  if (
    campaign &&
    [ENUMCampaignStatus.comingSoon, ENUMCampaignStatus.published].includes(
      campaign.status,
    ) &&
    getCookieThirdParty()
  ) {
    return campaign?.info?.trackers?.linkedIn || null;
  }
  return null;
}

const LinkedInTracker = {
  init: () => {
    LinkedInTag.init(linkedInTag);
  },

  trackRegister: () => {
    LinkedInTag.track(linkedInConversionRegister);
  },

  trackFollow: campaign => {
    LinkedInTag.track(linkedInConversionFollow);
    const linkedIn = getCampaignTrackers(campaign);
    if (!linkedIn) return;
    if (linkedIn.conversionFollowId && linkedIn.partnerId) {
      LinkedInTag.track(linkedIn.conversionFollowId, linkedIn.partnerId);
    }
  },

  trackInterest: campaign => {
    LinkedInTag.track(linkedInConversionInterest);
    const linkedIn = getCampaignTrackers(campaign);
    if (!linkedIn) return;
    if (linkedIn.conversionInterestId && linkedIn.partnerId) {
      LinkedInTag.track(linkedIn.conversionInterestId, linkedIn.partnerId);
    }
  },

  trackCommitmentBegin: campaign => {
    LinkedInTag.track(linkedInConversionCommitmentBegin);
    const linkedIn = getCampaignTrackers(campaign);
    if (!linkedIn) return;
    if (linkedIn.conversionCommitmentBeginId && linkedIn.partnerId) {
      LinkedInTag.track(
        linkedIn.conversionCommitmentBeginId,
        linkedIn.partnerId,
      );
    }
  },

  trackCommitmentSpecifyAmount: campaign => {
    LinkedInTag.track(linkedInConversionCommitmentSpecifyAmount);
    const linkedIn = getCampaignTrackers(campaign);
    if (!linkedIn) return;
    if (
      linkedIn &&
      linkedIn.conversionCommitmentSpecifyAmountId &&
      linkedIn.partnerId
    ) {
      LinkedInTag.track(
        linkedIn.conversionCommitmentSpecifyAmountId,
        linkedIn.partnerId,
      );
    }
  },

  trackPurchase: campaign => {
    LinkedInTag.track(linkedInConversionPurchase);
    const linkedIn = getCampaignTrackers(campaign);
    if (!linkedIn) return;
    if (linkedIn.conversionPurchaseId && linkedIn.partnerId) {
      LinkedInTag.track(linkedIn.conversionPurchaseId, linkedIn.partnerId);
    }
  },
};

export default LinkedInTracker;
