import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  dialogTitle: {
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  dialogContent: {
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  dialogActions: {
    paddingTop: 0,
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
}));
