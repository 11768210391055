/* eslint-disable import/no-import-module-exports */
/* eslint-disable no-console */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Logger from 'loglevel';
import WebFont from 'webfontloader';
import 'sanitize.css/sanitize.css';
import * as Sentry from '@sentry/react';
// Material UI Theme
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

// Error boundary
import ErrorTrackingToBoundary from 'components/ErrorTrackingToBoundary';
import ErrorBoundary from 'components/ErrorBoundary';
// Import root app
import App from 'containers/App';
// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

import history from 'utils/history';
import LocalStorage from 'utils/localStorage';
import { trackersInit } from 'utils/trackers';
import { setCookie } from 'utils/cookie';

// Load the favicon and the .htaccess file
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
// import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions

// SLIDER
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// Mamacrowd Theme
import { theme } from 'theme';

/* END FONT LOADING */

//* *** LOG APP NAME and VERSION ****
// const name = "Mamacrowd"
import pkg from '../package.json';

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)

// Load all trackers
trackersInit();

WebFont.load({
  google: {
    families: [
      'Montserrat:200,400,500,600,700',
      'Roboto:300,400,500,700',
      'Roboto Condensed:300,400,700',
      'Titillium Web:400,600&display=swap',
    ],
  },
});

console.log(
  `%c${pkg.name} ver:${pkg.version}`,
  'background: #ff0; color: #00f',
);

if (process.env.NODE_ENV !== 'production') {
  console.log(`FE:${process.env.NODE_ENV}`);
}

console.log(`${new Date().getFullYear()}, ${pkg.copyright}`);

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

if (process.env.NODE_ENV === 'production') {
  Logger.setLevel('error');
} else if (process.env.NODE_ENV === 'development') {
  Logger.setLevel(process.env.LOGGER_LEVEL || 'trace');
}

const searchParams = new URLSearchParams(window.location.search);
const sessionId = searchParams.get('sessionId');
const mmfc = searchParams.get('fbclid');

if (sessionId) {
  LocalStorage.setItem('session-id', decodeURIComponent(sessionId));
}

if (mmfc) {
  const expiresAt = new Date();
  expiresAt.setDate(expiresAt.getDate() + 90);
  setCookie('mmfc', mmfc, expiresAt);
}

Sentry.init({
  environment:
    process.env.NODE_ENV === 'production'
      ? process.env.SERVER_BE
      : process.env.NODE_ENV,
  dsn: process.env.SENTRY_DSN_TOKEN,
  release: `${process.env.SENTRY_PROJECT_NAME}@${pkg.version}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    // react-router v6
    // new Sentry.BrowserTracing({
    //   // See docs for support of different versions of variation of react router
    //   // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    //   routingInstrumentation: Sentry.reactRouterV6Instrumentation(
    //     React.useEffect,
    //     useLocation,
    //     useNavigationType,
    //     createRoutesFromChildren,
    //     matchRoutes
    //   ),
    // }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.httpClientIntegration({
      // This array can contain tuples of `[begin, end]` (both inclusive),
      // single status codes, or a combination of both.
      // default: [[500, 599]]
      failedRequestStatusCodes: [400, [403, 599]],

      // This array can contain Regexes, strings, or a combination of both.
      // default: [/.*/]
      failedRequestTargets: [/.*/],
    }),
  ],
  allowUrls: [
    `${window.location.protocol}//${window.location.host}`,
    process.env.API_DOMAIN,
  ],
  tracePropagationTargets: [
    `${window.location.protocol}//${window.location.host}`,
    process.env.API_BASE_URL,
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === 'development' ? 0 : 0.1, //  Capture 10% of the transactions
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === 'development' ? 0 : 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'development' ? 0 : 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  profilesSampleRate: process.env.NODE_ENV === 'development' ? 0 : 0.1, // Capture 10% of the profiles
  normalizeDepth: process.env.NODE_ENV === 'development' ? 0 : 10, // Or however deep you want your state context to be.
  // https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
  sendDefaultPii: true,
});

const root = createRoot(MOUNT_NODE);

const renderApp = messages => {
  root.render(
    <ErrorBoundary>
      <ErrorTrackingToBoundary>
        <Provider store={store}>
          <LanguageProvider messages={messages}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </StyledEngineProvider>
          </LanguageProvider>
        </Provider>
      </ErrorTrackingToBoundary>
    </ErrorBoundary>,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    // root.unmount();
    renderApp(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
const injectIntl = () => {
  if (!window.Intl) {
    new Promise(resolve => {
      resolve(import('intl'));
    })
      .then(() =>
        Promise.all([
          // MMC-translations
          // import('intl/locale-data/jsonp/en.js'),
          import('intl/locale-data/jsonp/it.js'), // eslint-disable-line
          import('intl/locale-data/jsonp/en.js'), // eslint-disable-line
        ]),
      ) // eslint-disable-line prettier/prettier
      .then(() => renderApp(translationMessages))
      .catch(err => {
        throw err;
      });
  } else {
    renderApp(translationMessages);
  }
};

injectIntl();

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
// if (process.env.NODE_ENV === 'production') {
//   // eslint-disable-next-line global-require
//   const runtime = require('offline-plugin/runtime');

//   runtime.install({
//     onUpdating: () => {
//       console.log('SW Event:', 'onUpdating');
//     },
//     onUpdateReady: () => {
//       console.log('SW Event:', 'onUpdateReady');
//       // Tells to new SW to take control immediately
//       runtime.applyUpdate();
//     },
//     onUpdated: () => {
//       console.log('SW Event:', 'onUpdated');
//       // Reload the webpage to load into the new version
//       window.location.reload();
//     },

//     onUpdateFailed: () => {
//       console.log('SW Event:', 'onUpdateFailed');
//     },
//   });
// }
