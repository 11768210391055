/**
 *
 * LazyLoadImage
 *
 */
import { ImgHTMLAttributes, useEffect, useRef, useState } from 'react';

import { useStyle } from './LazyLoadImage.style';

export interface LazyLoadImageProps
  extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt?: string;
  className?: string;
  transparentBgWhenLoaded?: boolean;
}

function LazyLoadImage({
  src,
  alt,
  className = '',
  transparentBgWhenLoaded = false,
  ...rest
}: LazyLoadImageProps) {
  const [loaded, setLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined') {
      const lazyImageObserver = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            const { isIntersecting, intersectionRatio } = entry;
            if (isIntersecting || intersectionRatio > 0) {
              const lazyImage = entry.target as HTMLImageElement;
              lazyImage.src = lazyImage.dataset.src ?? '';
              lazyImageObserver.unobserve(lazyImage);
              lazyImageObserver.disconnect();
            }
          });
        },
        {
          root: null,
          rootMargin: '50px',
        },
      );
      if (imageRef.current) {
        lazyImageObserver.observe(imageRef.current);
      }
    } else if (imageRef.current && imageRef.current.dataset.src) {
      imageRef.current.src = imageRef.current.dataset.src;
    }
  }, []);

  const classes = useStyle({ loaded, transparent: transparentBgWhenLoaded });

  return (
    <img
      data-src={src}
      onLoad={onLoad}
      onError={onLoad}
      ref={imageRef}
      className={`${classes.image} ${className}`}
      alt={loaded ? alt : ''}
      {...rest}
    />
  );
}

export default LazyLoadImage;
