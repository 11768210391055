/**
 *
 * PageLoadingPlaceholder
 *
 */

function PageLoadingPlaceholder() {
  return <div style={{ height: 2000 }} />;
}

export default PageLoadingPlaceholder;
