/*
 *
 * StepBasicProfile reducer
 *
 */
import produce from 'immer';
import { RESET_STEPS_STATE } from 'containers/DonatePage/constants';
import {
  GET_BASIC_PROFILE,
  GET_BASIC_PROFILE_ERROR,
  GET_BASIC_PROFILE_SUCCESS,
  SET_BASIC_PROFILE,
  SET_BASIC_PROFILE_ERROR,
  SET_BASIC_PROFILE_SUCCESS,
  CLEAN_BASIC_PROFILE,
} from './constants';

export const initialState = {
  getLoading: false,
  getError: false,
  basicProfile: null,
  setLoading: false,
  setError: false,
};

/* eslint-disable default-case, no-param-reassign */
const stepBasicProfileReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_BASIC_PROFILE:
        draft.getLoading = true;
        draft.getError = false;
        draft.basicProfile = null;
        break;
      case GET_BASIC_PROFILE_SUCCESS:
        draft.getLoading = false;
        draft.getError = false;
        draft.basicProfile = action.basicProfile;
        break;
      case GET_BASIC_PROFILE_ERROR:
        draft.getLoading = false;
        draft.getError = action.error || true;
        draft.basicProfile = null;
        break;
      case SET_BASIC_PROFILE:
        draft.setLoading = true;
        draft.setError = false;
        draft.basicProfile = null;
        break;
      case SET_BASIC_PROFILE_SUCCESS:
        draft.setLoading = false;
        draft.setError = false;
        draft.basicProfile = action.basicProfile;
        break;
      case SET_BASIC_PROFILE_ERROR:
        draft.setLoading = false;
        draft.setError = action.error || true;
        draft.basicProfile = null;
        break;
      case CLEAN_BASIC_PROFILE:
        draft.getLoading = false;
        draft.getError = false;
        draft.setLoading = false;
        draft.setError = false;
        draft.basicProfile = null;
        break;
      case RESET_STEPS_STATE:
        draft.getLoading = false;
        draft.getError = false;
        draft.setLoading = false;
        draft.setError = false;
        draft.basicProfile = null;
        break;
    }
  });

export default stepBasicProfileReducer;
