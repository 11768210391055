import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles({
  root: {
    alignItems: 'flex-start',
    margin: 0,
  },
  label: {
    marginTop: 4,
  },
});
