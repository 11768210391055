/**
 *
 * LanguageToggle
 *
 */
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Typography } from '@mamacrowd/ui-kit';
import {
  MenuItem,
  Menu,
  Button,
  useMediaQuery,
  Theme,
  Breakpoint,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Language } from '@mui/icons-material';

import { ME_PROFILE_ROUTE } from 'utils/constants';

import { useStyle } from './LanguageToggle.style';

export interface LanguageToggleProps {
  onToggle: (language: string) => void;
  values: string[];
  value: string;
  locale: string;
  messages: object;
  textLanguageBreakpoint?: Breakpoint;
  userIsLogged?: boolean;
  color?: string;
  textColor?: string;
}

function LanguageToggle({
  onToggle,
  values,
  value,
  messages,
  textLanguageBreakpoint,
  locale,
  userIsLogged,
  color = '#949494',
  textColor = '#949494',
}: LanguageToggleProps) {
  const [anchorEl, setAnchorEl] = useState(null);

  const showLanguageText = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(textLanguageBreakpoint || ('xs' as Breakpoint)),
  );
  const classes = useStyle({ showLanguageText, textColor, color });
  const theme = useTheme();
  const navigate = useHistory();
  const intl = useIntl();

  const open = Boolean(anchorEl);
  let content: ReactNode[] = [<option>--</option>];

  const handleClick = event => {
    if (userIsLogged) {
      navigate.push(ME_PROFILE_ROUTE);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = selectedLanguage => {
    setAnchorEl(null);
    onToggle(selectedLanguage);
  };

  // If we have items, render them
  if (values) {
    content = values.map(language => (
      <MenuItem
        value={language}
        key={`toggle-${language}`}
        onClick={() => {
          handleMenuItemClick(language);
        }}
        style={{
          backgroundColor:
            value === language
              ? theme.palette.primary.light
              : theme.palette.common.white,
        }}
      >
        <Typography
          style={{
            color: value === language ? theme.palette.primary.main : textColor,
          }}
          className={classes.languageText}
        >
          {messages[language]
            ? intl.formatMessage(messages[language])
            : language}
        </Typography>
      </MenuItem>
    ));
  }

  return (
    <>
      <Button
        startIcon={<Language className={classes.iconColor} />}
        onClick={handleClick}
        className={classes.languageButton}
        classes={{ endIcon: classes.endIcon, startIcon: classes.startIcon }}
      >
        {showLanguageText && (
          <Typography color="textSecondary" className={classes.languageText}>
            {messages[locale] ? intl.formatMessage(messages[locale]) : locale}
          </Typography>
        )}
      </Button>
      <Menu
        elevation={1}
        id="language-toggle"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {content}
      </Menu>
    </>
  );
}

export default LanguageToggle;
