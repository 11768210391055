/**
 *
 * InternetExplorerDialog
 *
 */

import { useEffect, useState } from 'react';

import { Typography } from '@mamacrowd/ui-kit';

import Dialog from 'basic/Dialog';
import FormattedMessage from 'components/FormattedMessage';

import LocalStorage from 'utils/localStorage';

import messages from './messages';

export interface InternetExplorerDialogProps {
  open?: boolean;
}

function InternetExplorerDialog({ open }: InternetExplorerDialogProps) {
  const [openDialog, setOpenDialog] = useState(open || false);

  useEffect(() => {
    if (open) {
      setOpenDialog(true);
    }
  }, [open]);

  const handleDialogClose = () => {
    LocalStorage.setItem('popupMessage-oldBrowserIE', true);
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      maxWidth="sm"
      onClose={undefined}
      onConfirm={undefined}
      onDecline={handleDialogClose}
      confirmButtonText={undefined}
      declineButtonText={
        <FormattedMessage messages={messages} messageId="close" />
      }
      title={<FormattedMessage messages={messages} messageId="warning" />}
      fullWidth
    >
      <Typography color="textPrimary">
        <FormattedMessage messages={messages} messageId="infoAboutIE" />
      </Typography>
    </Dialog>
  );
}

export default InternetExplorerDialog;
