import { Component, ErrorInfo, PropsWithChildren } from 'react';
import { trackException, trackEvent } from 'utils/trackers';
import log from 'loglevel';

class ErrorTrackingToBoundary extends Component<PropsWithChildren> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    trackEvent('ErrorBoundary', `${error?.message}`, 'Error');
    trackException(`${error} ${error.message}`);
    log.error('ErrorBoundary:', error?.name, error, errorInfo);
    throw error;
  }

  render() {
    return this.props.children;
  }
}

export default ErrorTrackingToBoundary;
