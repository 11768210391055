/**
 *
 * Asynchronously loads the component for NewsPage
 *
 */
import LoadableEmptyPlaceholder from 'components/LoadableEmptyPlaceholder';

import loadable from 'utils/loadable';

export default loadable(() => import('./index'), {
  fallback: <LoadableEmptyPlaceholder />,
});
