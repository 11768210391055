import { DOMAIN_URL } from './constants';

export default (link = '', replaceDomain = false) => {
  const tmpLink = link || '';
  if (replaceDomain) {
    return /^https?:\/\//.test(tmpLink.replace(DOMAIN_URL, ''));
  }

  return /^https?:\/\//.test(tmpLink);
};

export const removeDomainFromLink = link => {
  if (typeof link === 'string') {
    const tmpLink = link || '';
    return tmpLink.replace(DOMAIN_URL, '');
  }

  return link || '';
};
