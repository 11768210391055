/**
 *
 * DefaultLink
 *
 */

import { Typography } from '@mamacrowd/ui-kit';

import Link from 'basic/Link';
import FormattedMessage from 'components/FormattedMessage';

import { removeDomainFromLink } from 'utils/isExternalLink';

import { HeaderLinkProps } from '..';

export interface DefaultLinkProps {
  item: HeaderLinkProps['item'];
  messages: object;
}

function DefaultLink({ item, messages }: DefaultLinkProps) {
  const optimizedLink = removeDomainFromLink(item.link);

  return (
    <Link to={optimizedLink || '/'}>
      <Typography color="textPrimary">
        <FormattedMessage messages={messages} messageId={item.translationId} />
      </Typography>
    </Link>
  );
}

export default DefaultLink;
