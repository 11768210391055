/* eslint-disable import/no-import-module-exports */
/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createInjectorsEnhancer } from 'redux-injectors';
import * as Sentry from '@sentry/react';
import createReducer from './reducers';

export default function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
    // actionTransformer: action => {
    //   if (action.type === 'GOVERNMENT_SECRETS') {
    //     // Return null to not log the action to Sentry
    //     return null;
    //   }
    //   if (action.type === 'SET_PASSWORD') {
    //     // Return a transformed action to remove sensitive information
    //     return {
    //       ...action,
    //       password: null,
    //     };
    //   }
    //   return action;
    // },
    // stateTransformer: state => {
    //   if (state.topSecret.doNotSend) {
    //     // Return null to not send this version of the state.
    //     return null;
    //   }
    //   // Transform the state to remove sensitive information
    //   const transformedState = {
    //     ...state,
    //     topSecret: {
    //       ...state.topSecret,
    //       // Replace sensitive information with something else
    //       nuclearLaunchCodes: 'I love pizza',
    //       // or just remove it entirely
    //       hiddenTreasureLocation: null,
    //     },
    //     // You should also remove large data that is irrelevant to debugging to not clutter your Sentry issues
    //     giganticState: null,
    //   };
    //   return transformedState;
    // },
  });

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    /* eslint-enable */
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  if (
    process.env.NODE_ENV === 'development' &&
    process.env.ENABLE_REDUX_LOGGER === 'true'
  ) {
    const logger = createLogger({
      collapsed: true,
    });

    middlewares.push(logger);
  }

  const enhancers = [applyMiddleware(...middlewares), sentryReduxEnhancer];

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(
      ...enhancers,
      createInjectorsEnhancer({
        createReducer,
        runSaga: sagaMiddleware.run,
      }),
    ),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
