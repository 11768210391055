/* eslint-disable import/no-cycle */
/**
 *
 * UtmProvider
 *
 */

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useInjectSaga, useInjectReducer } from 'redux-injectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { makeSelectUserLogged } from 'containers/App/selectors';

import {
  parseUtm,
  getUtmFromLocalStorage,
  clearUtmFromLocalStorage,
} from 'utils/utm';
import { trackLog } from 'utils/trackers';

import {
  makeSelectUtmProviderError,
  makeSelectUtmProviderLoading,
  makeSelectUtmProviderUtmSaved,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import { setUserUtm } from './actions';

export function UtmProvider({
  children,
  // loading,
  // error,
  utmSaved,
  setUserUtmData,
  userLogged,
}) {
  useInjectReducer({ key: 'utmProvider', reducer });
  useInjectSaga({ key: 'utmProvider', saga });

  useEffect(() => {
    parseUtm(logUtmWrong);
  }, []);

  useEffect(() => {
    if (userLogged) {
      const userUtm = getUtmFromLocalStorage() || [];
      if (userUtm.length) {
        setUserUtmData(userUtm);
      }
    }
  }, [userLogged]);

  useEffect(() => {
    if (utmSaved) {
      clearUtmFromLocalStorage();
    }
  }, [utmSaved]);

  const logUtmWrong = () => {
    trackLog('warning', 'UTM Wrong - No UTM_SOURCE FOUND');
  };

  return children || null;
}

UtmProvider.propTypes = {
  children: PropTypes.any.isRequired,
  // loading: PropTypes.bool.isRequired,
  // error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  utmSaved: PropTypes.bool.isRequired,
  setUserUtmData: PropTypes.func.isRequired,
  userLogged: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectUtmProviderLoading(),
  error: makeSelectUtmProviderError(),
  utmSaved: makeSelectUtmProviderUtmSaved(),
  userLogged: makeSelectUserLogged(),
});

function mapDispatchToProps(dispatch) {
  return {
    setUserUtmData: userUtm => {
      dispatch(setUserUtm(userUtm));
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UtmProvider);
