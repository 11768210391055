/*
 *
 * Faq reducer
 *
 */
import produce from 'immer';
import {
  LOAD_ADDITIONAL_HEADER,
  LOAD_ADDITIONAL_HEADER_ERROR,
  LOAD_ADDITIONAL_HEADER_SUCCESS,
} from './constants';

export const initialState = {
  loading: false,
  additionalLink: null,
  called: false,
  error: false,
};

/* eslint-disable default-case, no-param-reassign */
const headerReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_ADDITIONAL_HEADER:
        draft.loading = true;
        break;
      case LOAD_ADDITIONAL_HEADER_SUCCESS:
        draft.additionalLink = action.headerLink;
        draft.called = true;
        break;
      case LOAD_ADDITIONAL_HEADER_ERROR:
        draft.error = action.error || true;
        draft.additionalLink = null;
        draft.called = true;
        break;
    }
  });

export default headerReducer;
