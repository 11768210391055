import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  linkContainer: {
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 20,
      borderBottom: '1px solid #eaeaea',
    },
  },
  linkContainerMultilang: {
    width: '100%',
    padding: '5px 15px',
  },
}));
