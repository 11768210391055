import makeStyles from '@mui/styles/makeStyles';
import { CONSOLE_SIDEBAR_WIDTH } from 'utils/constants';

export const useStyle = makeStyles(theme => ({
  contentContainer: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${CONSOLE_SIDEBAR_WIDTH}px)`,
      '@media print': {
        width: '100%',
      },
    },
    padding: 15,
  },
}));
