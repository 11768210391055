import { createSelector } from 'reselect';
import { initialState, InitialStateType } from './reducer';

/**
 * Direct selector to the homePage state domain
 */

const selectCampaignsPageFiltersDomain = (state: {
  campaignsPageFilters: InitialStateType;
}) => state.campaignsPageFilters || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by HomePage
 */

const makeSelectCampaignsPageFilters = () =>
  createSelector(selectCampaignsPageFiltersDomain, substate => substate);

const makeChangeFilter = () =>
  createSelector(selectCampaignsPageFiltersDomain, substate => substate.filter);

const makeSelectQuery = () =>
  createSelector(selectCampaignsPageFiltersDomain, substate => substate.query);

const makeSelectTypes = () =>
  createSelector(selectCampaignsPageFiltersDomain, substate => substate.types);

const makeSelectFiltersChanged = () =>
  createSelector(
    selectCampaignsPageFiltersDomain,
    substate => substate.filtersChanged,
  );

export default selectCampaignsPageFiltersDomain;

export {
  makeSelectCampaignsPageFilters,
  makeChangeFilter,
  makeSelectQuery,
  makeSelectTypes,
  makeSelectFiltersChanged,
};
