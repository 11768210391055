/*
 * DropDown Messages
 *
 * This contains all the text for the DropDown container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.CampaignsPageFilters';

export default defineMessages({
  inputPlaceholder: {
    id: `${scope}.input_placeholder.message`,
    defaultMessage: 'Search',
  },
});
