/*
 *
 * DonateSuccessPage constants
 *
 */

export const LOAD_DONATION_ORDER = 'app/DonateSuccessPage/LOAD_DONATION_ORDER';
export const LOAD_DONATION_ORDER_SUCCESS =
  'app/DonateSuccessPage/LOAD_DONATION_ORDER_SUCCESS';
export const LOAD_DONATION_ORDER_ERROR =
  'app/DonateSuccessPage/LOAD_DONATION_ORDER_ERROR';
export const CLEAN_DONATION = 'app/DonateSuccessPage/CLEAN_DONATION';
