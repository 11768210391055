/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import {
  LOAD_LOGIN_SUCCESS,
  LOAD_LOGIN,
  LOAD_LOGIN_ERROR,
  CLEAN_LOGIN_ERROR,
  LOAD_LOGOUT,
  LOAD_LOGOUT_SUCCESS,
  LOAD_LOGOUT_ERROR,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_USER_ERROR,
  SET_CONSOLE_ACTIVE_CAMPAIGN,
  SET_CONSOLE_ACTIVE_ORDER,
  LOAD_CONSOLE_ACTIVE_CAMPAIGN,
  LOAD_CONSOLE_ACTIVE_CAMPAIGN_ERROR,
  SET_CONSOLE_ACTIVE_COMPANY,
  SET_CONSOLE_ACTIVE_USER,
  SET_CONSOLE_ACTIVE_PARTNER,
  SET_CONSOLE_ACTIVE_TESTIMONIAL,
  SET_CONSOLE_ACTIVE_ARTICLE,
  SET_CONSOLE_ACTIVE_MESSAGE,
  SET_CONSOLE_ACTIVE_BANNER,
  SET_CONSOLE_ACTIVE_PAGE,
  SET_CONSOLE_ACTIVE_PRESS,
  SET_CONSOLE_ACTIVE_FAQ,
  SET_CONSOLE_ACTIVE_SETTING,
  SET_CONSOLE_ACTIVE_INDUSTRY,
} from './constants';

// The initial state of the App
export const initialState = {
  initialized: false,
  loading: false,
  error: false,
  user: null,
  userLogged: false,
  consoleActiveCampaignLoading: false,
  consoleActiveCampaign: null,
  consoleActiveCampaignError: false,
  consoleActiveCompanyId: '',
  consoleActiveOrderId: '',
  consoleActiveUserId: '',
  consoleActivePartnerId: '',
  consoleActiveTestimonialId: '',
  consoleActiveArticleId: '',
  consoleActiveMessageId: '',
  consoleActiveBannerId: '',
  consoleActivePageId: '',
  consoleActivePressId: '',
  consoleActiveFaqId: '',
  consoleActiveSettingId: '',
  consoleActiveIndustryId: '',
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_USER:
        draft.loading = true;
        break;
      case LOAD_USER_SUCCESS:
        draft.initialized = true;
        draft.user = action.user || null;
        draft.loading = false;
        draft.userLogged = !!action.user;
        draft.error = false;
        break;
      case LOAD_USER_ERROR:
        draft.initialized = true;
        draft.user = null;
        draft.loading = false;
        draft.userLogged = false;
        draft.error = action.error || true;
        break;
      case LOAD_LOGIN:
        draft.loading = true;
        draft.error = false;
        draft.user = null;
        draft.userLogged = false;
        break;
      case LOAD_LOGIN_SUCCESS:
        draft.user = action.user;
        draft.loading = false;
        draft.userLogged = true;
        draft.error = false;
        break;
      case LOAD_LOGIN_ERROR:
        draft.error = action.error || true;
        draft.loading = false;
        draft.user = null;
        draft.userLogged = false;
        break;
      case CLEAN_LOGIN_ERROR:
        draft.error = false;
        draft.loading = false;
        break;
      case LOAD_LOGOUT:
        draft.loading = true;
        draft.error = false;
        break;
      case LOAD_LOGOUT_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.user = null;
        draft.userLogged = false;
        break;
      case LOAD_LOGOUT_ERROR:
        draft.error = action.error || true;
        draft.loading = false;
        break;
      case LOAD_CONSOLE_ACTIVE_CAMPAIGN:
        draft.consoleActiveCampaignLoading = true;
        draft.consoleActiveCampaign = null;
        draft.consoleActiveCampaignError = false;
        break;
      case SET_CONSOLE_ACTIVE_CAMPAIGN:
        draft.consoleActiveCampaignLoading = false;
        draft.consoleActiveCampaign = action.activeCampaign || null;
        draft.consoleActiveCampaignError = false;
        break;
      case LOAD_CONSOLE_ACTIVE_CAMPAIGN_ERROR:
        draft.consoleActiveCampaignLoading = false;
        draft.consoleActiveCampaign = null;
        draft.consoleActiveCampaignError = action.error ? action.error : true;
        break;
      case SET_CONSOLE_ACTIVE_COMPANY:
        draft.consoleActiveCompanyId = action.companyId;
        break;
      case SET_CONSOLE_ACTIVE_ORDER:
        draft.consoleActiveOrderId = action.orderId;
        break;
      case SET_CONSOLE_ACTIVE_USER:
        draft.consoleActiveUserId = action.userId;
        break;
      case SET_CONSOLE_ACTIVE_PARTNER:
        draft.consoleActivePartnerId = action.partnerId;
        break;
      case SET_CONSOLE_ACTIVE_TESTIMONIAL:
        draft.consoleActiveTestimonialId = action.testimonialId;
        break;
      case SET_CONSOLE_ACTIVE_ARTICLE:
        draft.consoleActiveArticleId = action.articleId;
        break;
      case SET_CONSOLE_ACTIVE_MESSAGE:
        draft.consoleActiveMessageId = action.messageId;
        break;
      case SET_CONSOLE_ACTIVE_BANNER:
        draft.consoleActiveBannerId = action.bannerId;
        break;
      case SET_CONSOLE_ACTIVE_PAGE:
        draft.consoleActivePageId = action.pageId;
        break;
      case SET_CONSOLE_ACTIVE_PRESS:
        draft.consoleActivePressId = action.pressId;
        break;
      case SET_CONSOLE_ACTIVE_FAQ:
        draft.consoleActiveFaqId = action.faqId;
        break;
      case SET_CONSOLE_ACTIVE_SETTING:
        draft.consoleActiveSettingId = action.settingId;
        break;
      case SET_CONSOLE_ACTIVE_INDUSTRY:
        draft.consoleActiveIndustryId = action.industryId;
        break;
    }
  });

export default appReducer;
