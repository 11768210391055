/*
 *
 * Campaigns Page Filters reducer
 *
 */
import produce from 'immer';

import { CampaignType } from 'types/Campaign';

import {
  CHANGE_FILTER,
  CHANGE_QUERY,
  CHANGE_TYPE,
  CLEAN_QUERY,
  CLEAN_SEARCH_FILTERS,
  CLEAR_FILTERS_CHANGED,
} from './constants';

export type InitialStateType = {
  filter: string;
  query: string;
  types: CampaignType[];
  filtersChanged: boolean;
};

export const initialState: InitialStateType = {
  filter: 'investment',
  query: '',
  types: [],
  filtersChanged: false,
};

/* eslint-disable default-case, no-param-reassign */
const campaignsPageFiltersReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_FILTER:
        draft.filter = action.filter;
        draft.filtersChanged = true;
        break;
      case CHANGE_QUERY:
        draft.query = action.query;
        draft.filtersChanged = true;
        break;
      case CHANGE_TYPE:
        draft.types = action.campaignTypes;
        draft.filtersChanged = true;
        break;
      case CLEAR_FILTERS_CHANGED:
        draft.filtersChanged = false;
        break;
      case CLEAN_QUERY:
        draft.query = action.newQuery;
        draft.filtersChanged = true;
        break;
      case CLEAN_SEARCH_FILTERS:
        draft.filter = 'investment';
        draft.query = '';
        draft.types = [];
        draft.filtersChanged = false;
        break;
    }
  });

export default campaignsPageFiltersReducer;
