/*
 *
 * StepDonate reducer
 *
 */
import produce from 'immer';
import { RESET_STEPS_STATE } from 'containers/DonatePage/constants';
import {
  DONATE,
  DONATE_SUCCESS,
  DONATE_ERROR,
  CLEAN_DONATION,
} from './constants';

export const initialState = {
  loading: false,
  error: false,
  donation: null,
};

/* eslint-disable default-case, no-param-reassign */
const stepDonateReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case DONATE:
        draft.loading = true;
        draft.error = false;
        draft.donation = null;
        break;
      case DONATE_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.donation = action.donation;
        break;
      case DONATE_ERROR:
        draft.loading = false;
        draft.error = action.error || true;
        draft.donation = null;
        break;
      case CLEAN_DONATION:
        draft.loading = false;
        draft.error = false;
        draft.donation = null;
        break;
      case RESET_STEPS_STATE:
        draft.loading = false;
        draft.error = false;
        draft.donation = null;
        break;
    }
  });

export default stepDonateReducer;
