import makeStyles from '@mui/styles/makeStyles';
import { MIN_CARD_WIDTH } from 'utils/constants';

export const useStyle = makeStyles({
  '@keyframes loadingPlaceholder': {
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: 'translateX(100%)',
    },
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0.5,
    },
    to: {
      opacity: 1,
    },
  },
  card: {
    height: 435,
    minWidth: 250,
    margin: 10,
    maxWidth: MIN_CARD_WIDTH,
    animationName: '$fadeIn',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
  },
  image: {
    background: '#e9ebee',
    height: 135,
  },
  bars: {
    height: 85,
  },
  bar: {
    background: '#e9ebee',
    margin: '0 13px 13px',
    height: 19,
    borderRadius: 10,
  },
  bar1: {
    width: '80%',
    marginTop: 17,
  },
  bar2: {
    width: '30%',
  },
  loading: {
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      position: 'absolute',
      content: "''",
      display: 'block',
      backgroundColor: '#dddfe2',
      width: '100%',
      height: '100%',
      transform: 'translateX(0)',
      animationName: '$loadingPlaceholder',
      animationDuration: '1.5s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    },
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
  },
  footerBar: {
    width: '40%',
  },
});
