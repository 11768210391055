/* eslint-disable import/no-cycle */
import LocalStorage from 'utils/localStorage';
import { USER_UTM } from './constants';

const utmStorageKey = USER_UTM;

export function parseUtm(logWarning) {
  const userUtm = Array.isArray(getUtmFromLocalStorage())
    ? getUtmFromLocalStorage()
    : [];
  const urlUTM = getUTMFromUrl();

  if (urlUTM.source) {
    userUtm.push({
      ...urlUTM,
      timestamp: new Date().toISOString(),
      url: window.location.href,
      referrer: document.referrer || null,
    });
    LocalStorage.setItem(utmStorageKey, userUtm);
  } else if (
    !urlUTM.source &&
    (urlUTM.campaign ||
      urlUTM.medium ||
      urlUTM.content ||
      urlUTM.thirdParty?.financeAdsId)
  ) {
    logWarning();
  }
}

function getUTMFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source') || null;
  const utmMedium = urlParams.get('utm_medium') || null;
  const utmCampaign = urlParams.get('utm_campaign') || null;
  const utmContent = urlParams.get('utm_content') || null;
  const financeAdsId = urlParams.get('s_id') || null;
  const leadSource = urlParams.get('lead_source') || null;

  if (utmSource) {
    const utm = {
      source: utmSource,
      campaign: utmCampaign,
      medium: utmMedium,
      content: utmContent,
      thirdParty: {},
    };

    if (financeAdsId) {
      utm.thirdParty.financeAdsId = financeAdsId;
    }

    if (leadSource) {
      utm.thirdParty.leadSource = leadSource;
    }

    return utm;
  }

  if (leadSource) {
    return {
      source: leadSource,
      campaign: 'application',
      medium: 'unknown',
      content: null,
      thirdParty: {
        leadSource,
      },
    };
  }

  return {
    source: utmSource,
    campaign: utmCampaign,
    medium: utmMedium,
    content: utmContent,
    thirdParty: financeAdsId ? { financeAdsId } : {},
  };
}

export const getUtmFromLocalStorage = () => LocalStorage.getItem(utmStorageKey);

export const clearUtmFromLocalStorage = () =>
  LocalStorage.removeItem(utmStorageKey);
