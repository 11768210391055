/**
 * Asynchronously loads the component for NotFoundPage
 */

import { Loader } from '@mamacrowd/ui-kit';
import loadable from 'utils/loadable';

export default loadable(() => import('./index'), {
  fallback: <Loader isLoading />,
});
