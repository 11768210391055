/**
 *
 * ProtectedRoute
 *
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, withRouter } from 'react-router-dom';

import User from 'utils/User';
import composeRedirectUrl from 'utils/composeRedirectUrl';
import { LOGIN_ROUTE, CAMPAIGNS_ROUTE } from 'utils/constants';

export function ProtectedRoute({
  MainComponent,
  Layout,
  permissions,
  history,
  ...rest
}) {
  useEffect(() => {
    if (!User.isLogged()) {
      const redirectUrl = composeRedirectUrl(LOGIN_ROUTE);
      history.push(redirectUrl);
    }
  }, []);

  return (
    <Route
      {...rest}
      exact={rest.exact}
      render={props => {
        if (User.hasPermission(permissions)) {
          return (
            <Layout>
              <MainComponent permissions={permissions} {...props} {...rest} />
            </Layout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: `${CAMPAIGNS_ROUTE}`,
            }}
          />
        );
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  MainComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  location: PropTypes.object,
  Layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  history: PropTypes.object.isRequired,
};

export default withRouter(ProtectedRoute);
