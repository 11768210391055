import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the utmProvider state domain
 */

const selectUtmProviderDomain = state => state.utmProvider || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by UtmProvider
 */

const makeSelectUtmProvider = () =>
  createSelector(selectUtmProviderDomain, substate => substate);

const makeSelectUtmProviderLoading = () =>
  createSelector(selectUtmProviderDomain, substate => substate.loading);

const makeSelectUtmProviderError = () =>
  createSelector(selectUtmProviderDomain, substate => substate.error);

const makeSelectUtmProviderUtmSaved = () =>
  createSelector(selectUtmProviderDomain, substate => substate.utmSaved);

export default makeSelectUtmProvider;
export {
  selectUtmProviderDomain,
  makeSelectUtmProviderLoading,
  makeSelectUtmProviderError,
  makeSelectUtmProviderUtmSaved,
};
