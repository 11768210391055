/**
 *
 * Asynchronously loads the component for InvestorEducationKnowledgePage
 *
 */
import loadable from 'utils/loadable';

export default loadable(() => import('./index'), {
  fallback: <div style={{ minHeight: '100vh' }} />,
});
