/**
 *
 * PublicRoute
 *
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';

import User from 'utils/User';
import composeRedirectUrl from 'utils/composeRedirectUrl';
import { LOGIN_ROUTE, NOT_FOUND_PAGE_ROUTE } from 'utils/constants';

export function PublicRoute({
  MainComponent,
  Layout,
  loggedRoute = false,
  history,
  campaignOwnerRoute = false,
  ...rest
}) {
  useEffect(() => {
    if (loggedRoute && !User.isLogged()) {
      const redirectUrl = composeRedirectUrl(LOGIN_ROUTE);
      history.push(redirectUrl);
    }

    if (
      campaignOwnerRoute &&
      (!User.isLogged() || !User.getUserProp('campaignOwner'))
    ) {
      if (!User.isLogged()) {
        const redirectUrl = composeRedirectUrl(LOGIN_ROUTE);
        history.push(redirectUrl);
      } else if (!User.getUserProp('campaignOwner')) {
        history.push(`${NOT_FOUND_PAGE_ROUTE}`);
      }
    }
  }, [loggedRoute, campaignOwnerRoute]);

  return (
    <Route
      {...rest}
      exact={rest.exact}
      render={props => (
        <Layout>
          <MainComponent {...props} {...rest} />
        </Layout>
      )}
    />
  );
}

PublicRoute.propTypes = {
  MainComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  Layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  loggedRoute: PropTypes.bool,
  campaignOwnerRoute: PropTypes.bool,
  history: PropTypes.object,
};

export default withRouter(PublicRoute);
