/*
 *
 * StepDonate constants
 *
 */

export const DONATE = 'app/StepDonate/DONATE';
export const DONATE_SUCCESS = 'app/StepDonate/DONATE_SUCCESS';
export const DONATE_ERROR = 'app/StepDonate/DONATE_ERROR';
export const CLEAN_DONATION = 'app/StepDonate/CLEAN_DONATION';
