import { CAMPAIGNS_ROUTE } from 'utils/constants';

export default function composeRedirectUrl(
  url,
  defaultUrlReplacing = CAMPAIGNS_ROUTE,
) {
  const { pathname, search, origin } = window.location;
  let redirectUrl = `${pathname}${search}`;
  const newUrl = new URL(url, origin);
  newUrl.searchParams.set('redirectUrl', redirectUrl);

  if (!pathname.match(/\/error$/gi)) {
    return `${newUrl.pathname}${newUrl.search}`;
  }

  redirectUrl = `${defaultUrlReplacing}${search}`;
  newUrl.searchParams.set('redirectUrl', redirectUrl);
  return `${newUrl.pathname}${newUrl.search}`;
}
