import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the headerMessage state domain
 */

const selectHeaderMessageDomain = state => state.headerMessage || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by HeaderMessage
 */

const makeSelectHeaderMessage = () =>
  createSelector(selectHeaderMessageDomain, substate => substate);

const makeSelectHeaderMessageArray = () =>
  createSelector(selectHeaderMessageDomain, substate => substate.messageArray);

const makeSelectHeaderMessageLoading = () =>
  createSelector(selectHeaderMessageDomain, substate => substate.loading);

const makeSelectHeaderMessageError = () =>
  createSelector(selectHeaderMessageDomain, substate => substate.error);

export default makeSelectHeaderMessage;
export {
  selectHeaderMessageDomain,
  makeSelectHeaderMessageArray,
  makeSelectHeaderMessageLoading,
  makeSelectHeaderMessageError,
};
