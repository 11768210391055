/* eslint-disable no-underscore-dangle */
import Logger from 'loglevel';

class LinkedInTag {
  constructor() {
    this.initialized = false;
  }

  verifyInit() {
    if (!this.initialized) {
      Logger.warn(
        'LinkedIn Insight Tag not initialized before using call LinkedInTag.init with required params',
      );
    }

    return this.initialized;
  }

  init(partnerId) {
    if (!partnerId || process.env.NODE_ENV === 'test') return;

    if (!window.lintrk) {
      window.lintrk = (a, b) => window.lintrk.q.push([a, b]);
      window.lintrk.q = [];
    }

    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(partnerId);

    const script = document.getElementsByTagName('script')[0];
    const inScript = document.createElement('script');
    inScript.type = 'text/javascript';
    inScript.async = true;
    inScript.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
    try {
      script.parentNode.insertBefore(inScript, script);
      this.initialized = true;
    } catch (e) {
      console.error(`InserBefore Linkedin - ${e.message}`);
    }
  }

  /**
   * Track a conversion action based on conversion ID.
   * Once a conversion is created in the LinkedIn Campaign Manager
   * we can get the Conversion ID. The conversion ID can be extracted from
   * an event-specific pixel. The src url they provide holds a query variable
   * 'conversionId=123456'. This 123456 is your conversion id.
   *
   * @params {int} conversionId - The conversion ID received from LinkedIn
   * @params {int} partnerId = null - By default the partner ID is fetched from the initialization.
   *
   * @return void
   */
  // track(conversionId, partnerId) {
  //   if (!this.verifyInit()) {
  //     return;
  //   }

  //   const pId = partnerId || window._linkedin_data_partner_ids[0];

  //   const url = `https://px.ads.linkedin.com/collect/?pid=${pId}&conversionId=${conversionId}&fmt=gif`;

  //   // It creates an element without actually posting it to the page. The call is already made to the linkedin servers and will be registered
  //   const element = document.createElement('img');
  //   element.alt = '';
  //   element.height = 1;
  //   element.width = 1;
  //   element.src = url;
  // }

  // https://px.ads.linkedin.com/collect?v=2&fmt=js&pid=3627537&time=1656353305794&conversionId=8689377&url=https://shawill.test.trmpln.com/en/project/air-wines
  track(conversionId, partnerId = null) {
    if (!this.verifyInit()) {
      return;
    }

    const prevPartners = window._linkedin_data_partner_ids;
    if (partnerId) window._linkedin_data_partner_ids = [partnerId];
    window.lintrk('track', { conversion_id: conversionId });
    window._linkedin_data_partner_ids = prevPartners;
  }
}

export default new LinkedInTag();
