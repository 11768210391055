/*
 *
 * CampaignsPageFilters actions
 *
 */

import { CampaignType } from 'types/Campaign';
import {
  CHANGE_FILTER,
  CHANGE_QUERY,
  CHANGE_TYPE,
  CLEAN_QUERY,
  CLEAN_SEARCH_FILTERS,
  CLEAR_FILTERS_CHANGED,
} from './constants';

export function changeFilter(filter: string) {
  return {
    type: CHANGE_FILTER,
    filter,
  };
}

export function changeQuery(query: string) {
  return {
    type: CHANGE_QUERY,
    query,
  };
}

export function changeType(campaignTypes: CampaignType[]) {
  return {
    type: CHANGE_TYPE,
    campaignTypes,
  };
}

export function clearFiltersChanged() {
  return {
    type: CLEAR_FILTERS_CHANGED,
  };
}

export function cleanQuery(newQuery: string) {
  return {
    type: CLEAN_QUERY,
    newQuery,
  };
}

export function cleanSearchFilters() {
  return {
    type: CLEAN_SEARCH_FILTERS,
  };
}
