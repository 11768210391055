/**
 *
 * FormattedMessage
 *
 */

import { FormattedMessage as AppFormattedMessage } from 'react-intl';
import { MessageId } from 'types/FormattedMessage';

export interface FormattedMessageProps {
  messages: object;
  messageId: MessageId;
  values?: object;
  count?: number;
  isHtml?: boolean;
}

export function FormattedMessage({
  messages,
  messageId,
  count,
  values,
  ...rest
}: FormattedMessageProps) {
  const isPluralObject = typeof messageId === 'object' && messageId !== null;

  // Determina il messaggio corretto in base al valore di `count`
  let selectedMessageId: string;

  if (isPluralObject) {
    if (!count || count === 0) {
      selectedMessageId = messageId.zero || messageId.other;
    } else if (count === 1) {
      selectedMessageId = messageId.one;
    } else {
      selectedMessageId = messageId.other;
    }
  } else {
    selectedMessageId = messageId;
  }

  if (messages && messages[selectedMessageId]) {
    return (
      <AppFormattedMessage
        {...messages[selectedMessageId]}
        values={{
          b: chunks => <b>{chunks}</b>,
          i: chunks => <i>{chunks}</i>,
          u: chunks => <u>{chunks}</u>,
          ul: chunks => <ul>{chunks}</ul>,
          ol: chunks => <ol>{chunks}</ol>,
          li: chunks => <li>{chunks}</li>,
          sup: chunks => <sup>{chunks}</sup>,
          linebreak: <br />,
          ...values,
        }}
        {...rest}
      />
    );
  }
  if (process.env.NODE_ENV === 'development') {
    return <span>[{selectedMessageId}]</span>;
  }
  return <span>{selectedMessageId}</span>;
}

export default FormattedMessage;
