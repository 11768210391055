/**
 *
 * CardAnimatedLoader
 *
 */

import { Card } from '@mui/material';

import { useStyle } from './CardAnimatedLoader.style';

function CardAnimatedLoader() {
  const classes = useStyle();

  return (
    <Card className={classes.card}>
      <div className={[classes.image, classes.loading].join(' ')} />
      <div className={classes.bars}>
        <div
          className={[classes.bar, classes.bar1, classes.loading].join(' ')}
        />
        <div
          className={[classes.bar, classes.bar2, classes.loading].join(' ')}
        />
        <div className={classes.footer}>
          <div
            className={[classes.bar, classes.footerBar, classes.loading].join(
              ' ',
            )}
          />
          <div
            className={[classes.bar, classes.footerBar, classes.loading].join(
              ' ',
            )}
          />
        </div>
      </div>
    </Card>
  );
}

CardAnimatedLoader.propTypes = {};

export default CardAnimatedLoader;
