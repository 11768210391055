/**
 *
 * FooterSectionDesktop
 *
 */

import { ReactNode } from 'react';
import { connect } from 'react-redux';

import { Typography } from '@mamacrowd/ui-kit';
import { Grid } from '@mui/material';

import FormattedMessage from 'components/FormattedMessage';
import { openCookieSettings } from 'containers/CookiesBanner/actions';

import FooterLink from '../FooterLink';
import { FooterLinkType } from '../FooterLinkList';
import { useStyle } from './FooterSectionDesktop.style';
import messages from '../messages';

export interface FooterSectionDesktopProps {
  links: FooterLinkType[];
  title: ReactNode;
  openCookieSettingsData: () => void;
}

function FooterSectionDesktop({
  links,
  title,
  openCookieSettingsData,
}: FooterSectionDesktopProps) {
  const classes = useStyle();

  const linksJSX = links.map(item =>
    item.translationId === 'handleCookies' ? (
      <Typography
        key={item.translationId}
        variant="body2"
        color="textPrimary"
        className={classes.linkText}
        onClick={openCookieSettingsData}
      >
        <FormattedMessage messages={messages} messageId={item.translationId} />
      </Typography>
    ) : (
      <FooterLink
        message={
          <FormattedMessage
            messages={messages}
            messageId={item.translationId}
          />
        }
        link={item.link || ''}
        key={item.translationId}
      />
    ),
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.footerSectionTitle}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {linksJSX}
      </Grid>
    </Grid>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    openCookieSettingsData: () => {
      dispatch(openCookieSettings());
    },
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default withConnect(FooterSectionDesktop);
