/* eslint-disable no-nested-ternary */
/**
 *
 * CheckBox
 *
 */

import PropTypes from 'prop-types';

import { Typography } from '@mamacrowd/ui-kit';
import { FormControlLabel, Checkbox as MUICheckbox } from '@mui/material';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBox from '@mui/icons-material/CheckBox';

import { useStyle } from './CheckBox.style';

function Checkbox({
  label,
  value,
  error,
  onChange,
  checked,
  color = 'primary',
  disabled = false,
  size = 'medium', // large make test failed due to not exist prop
  labelPlacement = 'end',
  helperText = '',
  ...rest
}) {
  const classes = useStyle();
  const labelJSX = (
    <>
      <Typography
        component="span"
        style={{ display: 'block', textAlign: 'justify' }}
        color={disabled ? 'textSecondary' : 'textPrimary'}
      >
        {label}
      </Typography>
      {(error || helperText) && (
        <Typography
          variant="caption"
          color={disabled ? 'textSecondary' : error ? 'error' : 'textPrimary'}
        >
          {error || helperText}
        </Typography>
      )}
    </>
  );
  return (
    <FormControlLabel
      control={
        <MUICheckbox
          data-testid="test-checkbox"
          icon={<CheckBoxOutlineBlank color={error ? 'error' : 'inherit'} />}
          checkedIcon={<CheckBox />}
          checked={checked}
          color={color}
          value={value}
          size={size}
          disabled={disabled}
          onClick={() => {
            if (typeof onChange === 'function') {
              onChange(!checked);
            }
          }}
          {...rest}
        />
      }
      label={labelJSX}
      disabled={disabled}
      labelPlacement={labelPlacement}
      classes={{ root: classes.root, label: classes.label }}
    />
  );
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
  ]),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  checked: PropTypes.bool.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  fontSize: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelPlacement: PropTypes.string,
  rootAlignItems: PropTypes.string,
};

export default Checkbox;
