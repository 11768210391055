/*
 * Header Messages
 *
 * This contains all the text for the Header component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Header';

export default defineMessages({
  login: {
    id: `${scope}.login.message`,
    defaultMessage: 'Login',
  },
  register: {
    id: `${scope}.register.message`,
    defaultMessage: 'Sign Up',
  },
  profileLink: {
    id: `${scope}.profile_link.message`,
    defaultMessage: 'Account',
  },
  simLink: {
    id: `${scope}.sim_link.message`,
    defaultMessage: 'Sim',
  },
  monitoringLink: {
    id: `${scope}.monitoring_link.message`,
    defaultMessage: 'Monitoring',
  },
  portfolioLink: {
    id: `${scope}.portfolio_link.message`,
    defaultMessage: 'Portfolio',
  },
  myCampaignLink: {
    id: `${scope}.my_campaign_link.message`,
    defaultMessage: 'My Campaigns',
  },
  myCompanyReportsLink: {
    id: `${scope}.my_company_reports_link.message`,
    defaultMessage: 'Reports',
  },
  consoleLink: {
    id: `${scope}.console_link.message`,
    defaultMessage: 'Console',
  },
  watchlistLink: {
    id: `${scope}.watchlist_link.message`,
    defaultMessage: 'Followed',
  },
  invest: {
    id: `${scope}.invest.message`,
    defaultMessage: 'All projects',
  },
  raise: {
    id: `${scope}.raise.message`,
    defaultMessage: 'Candidate a project',
  },
  blog: {
    id: `${scope}.blog.message`,
    defaultMessage: 'Blog',
  },
  raiseEquity: {
    id: `${scope}.raise_equity.message`,
    defaultMessage: 'Equity project',
  },
  raiseRealEstate: {
    id: `${scope}.raise_real_estate.message`,
    defaultMessage: 'Real Estate project',
  },
  guide: {
    id: `${scope}.guide.message`,
    defaultMessage: 'Guide',
  },
  aboutUs: {
    id: `${scope}.about_us.message`,
    defaultMessage: 'About Us',
  },
  news: {
    id: `${scope}.news.message`,
    defaultMessage: 'News',
  },
  powerUser: {
    id: `${scope}.power_user.message`,
    defaultMessage: 'Power User',
  },
});
