import { takeLatest, call, put } from 'redux-saga/effects';
import Logger from 'loglevel';
import { request } from 'utils/request';
import makeApiUrl from 'utils/makeApiUrl';
import { ME_BASIC_PROFILE_URL } from 'utils/constants';
import { manageSagaError } from 'utils/errorManager';

import { GET_BASIC_PROFILE, SET_BASIC_PROFILE } from './constants';

import {
  getBasicProfileSuccess,
  getBasicProfileError,
  setBasicProfileSuccess,
  setBasicProfileError,
} from './actions';

// Individual exports for testing
export function* makeGetBasicProfile() {
  try {
    // Call our request helper (see 'utils/request'
    const requestBasicProfileUrl = makeApiUrl(ME_BASIC_PROFILE_URL);

    const basicProfile = yield call(request, requestBasicProfileUrl);

    Logger.log([
      'SAGAS',
      'REQUEST BASIC PROFILE',
      'BASIC PROFILE SUCCESS',
      basicProfile,
    ]);

    yield put(getBasicProfileSuccess(basicProfile));
  } catch (err) {
    let basicProfileError = null;

    if (err && err.response && err.response.status === 404) {
      basicProfileError = yield err.response.json();

      if (
        basicProfileError &&
        basicProfileError.error &&
        basicProfileError.error.name === 'ErrorNotFound'
      ) {
        yield put(getBasicProfileSuccess(null));
      } else {
        const error = yield manageSagaError('StepBasicProfile', err);
        yield put(getBasicProfileError(error));
      }
    } else {
      const error = yield manageSagaError('StepBasicProfile', err);
      yield put(getBasicProfileError(error));
    }
  }
}

// Individual exports for testing
export function* makeSetBasicProfile(payload) {
  try {
    // Call our request helper (see 'utils/request'
    const requestSetBasicProfileUrl = makeApiUrl(ME_BASIC_PROFILE_URL);

    const options = {
      method: 'POST',
      body: JSON.stringify(payload.basicProfile),
    };

    const { id } = yield call(request, requestSetBasicProfileUrl, options);

    Logger.log(['SAGAS', 'SET BASIC PROFILE', 'SET BASIC PROFILE SUCCESS', id]);

    yield put(
      setBasicProfileSuccess({
        id,
        ...payload.basicProfile,
        isCreated: true,
      }),
    );
  } catch (err) {
    const error = yield manageSagaError('StepBasicProfile', err);
    yield put(setBasicProfileError(error));
  }
}

// Individual exports for testing
export default function* stepBasicProfileSaga() {
  // See example in containers/HomePage/saga.js
  yield takeLatest(GET_BASIC_PROFILE, makeGetBasicProfile);
  yield takeLatest(SET_BASIC_PROFILE, makeSetBasicProfile);
}
