import makeStyles from '@mui/styles/makeStyles';

export const useStyle = makeStyles(theme => ({
  footerSectionTitle: {
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: 10,
  },
  linkText: {
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
